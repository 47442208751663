import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsUtils } from 'core/utils';
import { IGroupShortInfo } from 'modules/group/models/group.model';
import { Observable, map } from 'rxjs';
import { IEvent, IEventPayload } from '../models/event.model';

export enum EventEndpoints {
  GET = '/a/events/:eventId/',
  POST = '/a/events/',
}

@Injectable()
export class EventService {
  private defaultProps: Partial<IEvent> = {
    timeZoneId: 1,
  };

  constructor(private http: HttpClient) {}

  public get(eventId: number, permissions?: string[]): Observable<IEvent> {
    let params = new HttpParams();

    if (permissions) {
      params = params.set('permitted_for', permissions.join(','));
    }

    return this.http
      .get<IEvent>(ElmsUtils.formatUrl(EventEndpoints.GET, { eventId }), { params: params })
      .pipe(map((resource) => Object.assign({}, this.defaultProps, resource)));
  }

  public post(payload: Partial<IEventPayload>): Observable<IEvent> {
    return this.http.post<IEvent>(EventEndpoints.POST, payload);
  }

  public update(eventId: number, payload: Partial<IEventPayload>): Observable<IEvent> {
    return this.http.put<IEvent>(ElmsUtils.formatUrl(EventEndpoints.GET, { eventId }), payload);
  }

  public delete(eventId: number): Observable<IEvent> {
    return this.http.delete<IEvent>(ElmsUtils.formatUrl(EventEndpoints.GET, { eventId }));
  }

  public newEvent(groups: IGroupShortInfo[]): IEvent {
    return {
      timeZoneId: this.defaultProps.timeZoneId,
      groups: groups,
    };
  }
}
