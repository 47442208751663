import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarkdownService } from 'components/markdown';
import { HttpRequestParams } from 'core/services';
import { ElmsUtils } from 'core/utils';
import moment from 'moment';
import { Observable, map } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { IBlogPost } from '../models/blog-post.model';
import { IBlogPostChannel, IBlogPostPayload } from './../models/blog-post.model';

export enum BlogPostEndpoints {
  QUEUE = '/a/blog_posts/',
  GET = '/a/blog_posts/:blogId/',
  DELETE = '/a/blog_posts/:blogId/',
  CHANNELS = '/a/blog_posts/channels/',
  POST = '/a/blog_posts/',
  PUT = '/a/blog_posts/:blogId/',
}

export interface IQueueResponse<T> {
  count: number;
  items: T[];
}

export interface IBlogPostChannelResponse {
  channels: IBlogPostChannel[];
}

export type IQueueBlogPostResponse = IQueueResponse<IBlogPost>;

@Injectable()
export class BlogPostService {
  constructor(
    private http: HttpClient,
    private markdownService: MarkdownService,
    private sanitizer: DomSanitizer,
  ) {}

  public queue(params: HttpRequestParams): Observable<IQueueBlogPostResponse> {
    return this.http.get<IQueueBlogPostResponse>(BlogPostEndpoints.QUEUE, { params });
  }

  public get(blogId: number, permissions?: string[]): Observable<IBlogPost> {
    let params = new HttpParams();

    if (permissions) {
      params = params.set('permitted_for', permissions.join(','));
    }

    return this.http
      .get<IBlogPost>(ElmsUtils.formatUrl(BlogPostEndpoints.GET, { blogId }), { params: params })
      .pipe(map((post) => this.transform(post)));
  }

  public post(payload: Partial<IBlogPostPayload>): Observable<IBlogPost> {
    return this.http.post<IBlogPost>(BlogPostEndpoints.POST, payload).pipe(
      map((post) =>
        this.transform({
          globalId: payload.globalId,
          ...post,
        }),
      ),
    );
  }

  public update(blogId: number, payload: Partial<IBlogPostPayload>): Observable<IBlogPost> {
    return this.http.put<IBlogPost>(ElmsUtils.formatUrl(BlogPostEndpoints.PUT, { blogId }), payload).pipe(
      map((post) =>
        this.transform({
          globalId: payload.globalId,
          ...post,
        }),
      ),
    );
  }

  public delete(blogId: number): Observable<IBlogPost> {
    return this.http.delete<IBlogPost>(ElmsUtils.formatUrl(BlogPostEndpoints.DELETE, { blogId }));
  }

  public getChannels(): Observable<IBlogPostChannelResponse> {
    return this.http.get<IBlogPostChannelResponse>(BlogPostEndpoints.CHANNELS);
  }

  public transform(originalData: IBlogPost, defaultData: Partial<IBlogPost> = {}, sanitize = false): IBlogPost {
    return {
      globalId: originalData.globalId || uuidV4(),
      ...defaultData,
      ...originalData,
      getContent: (): string => {
        const content = originalData.moduleId
          ? originalData.content
          : this.markdownService.toHtml(originalData.content);

        if (sanitize) {
          return this.sanitizer.bypassSecurityTrustHtml(content) as string;
        }

        return content;
      },
      haveBeenUpdated: () => moment(originalData.updatedDate).isAfter(originalData.createdDate),
    };
  }
}
