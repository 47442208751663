import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ElmsUtils } from 'core/utils';
import { Observable } from 'rxjs';
import { IPurchasePayload, IPurchaseResult, IPurchaseResultDetails } from '../models/checkout.models';

export enum PurchaseEndpoints {
  GET = '/a/ecommerce/purchases/:purchaseId/',
  POST = '/a/ecommerce/purchases/',
}

@Injectable()
export class EcommercePurchaseService {
  constructor(private http: HttpClient) {}

  post(payload: IPurchasePayload, throwHttpErrors = false): Observable<IPurchaseResult> {
    return this.http.post<IPurchaseResult>(PurchaseEndpoints.POST, payload, {
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  get(purchaseId: number): Observable<IPurchaseResultDetails> {
    return this.http.get<IPurchaseResultDetails>(ElmsUtils.formatUrl(PurchaseEndpoints.GET, { purchaseId }));
  }
}
