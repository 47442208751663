import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { Player } from 'modules/course/player/services/player.service';

@Component({
  standalone: false,
  selector: 'next-step-prompt',
  templateUrl: './next-step-prompt.component.html',
})
export class NextStepPromptComponent {
  constructor(
    private activeModal: NgbActiveModal,
    public player: Player,
    private backUrlService: BackUrlService,
  ) {}

  goBack(): void {
    this.backUrlService.goBack();
    this.activeModal.dismiss();
  }

  goNextStep(): void {
    this.player.launchNextStep();
    this.activeModal.close('ok');
  }
}
