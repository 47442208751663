<div class="row">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-lg-2 xs-margin-bottom" *ngIf="mode === 'search'">
        <button type="button" class="modern-back-button btn-block" (click)="showCalendar()">
          <span class="fa fa-chevron-left"></span>Back
        </button>
      </div>

      <div
        [ngClass]="{
          'col-lg-6': filtersCount <= 2 && mode !== 'search',
          'col-lg-4': (filtersCount <= 2 && mode === 'search') || (filtersCount >= 3 && mode !== 'search'),
          'col-lg-2': filtersCount >= 3 && mode === 'search',
        }"
        class="xs-margin-bottom">
        <keyword-search-form
          [(value)]="query"
          (submit)="onSubmit()"
          [pending]="processing"
          [disabled]="processing"
          placeholder="Search Calendar">
        </keyword-search-form>
      </div>

      <div class="col-md-12" [ngClass]="{ 'col-lg-4': filtersCount <= 2, 'col-lg-6': filtersCount >= 3 }">
        <div class="row" *ngIf="filters">
          <div
            *ngFor="let f of filters.ui"
            [hidden]="!f.visible || !f.items.length"
            class="col-md-12 xs-margin-bottom"
            [ngClass]="{
              'col-lg-12': filtersCount === 1,
              'col-lg-6': filtersCount === 2,
              'col-lg-4': filtersCount === 3,
            }">
            <search-filter-view-dep [filter]="f"></search-filter-view-dep>
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-md-12 xs-margin-bottom-phone">
        <button
          type="button"
          class="btn btn-default btn-block"
          aria-pressed="false"
          [ngClass]="{ active: calendarMode !== 'default' }"
          (click)="toggleCalendarMode()">
          {{ toggleModeName }}
        </button>
      </div>
    </div>

    <div class="row" *ngIf="filters">
      <div class="col-xs-12">
        <div class="label-list mt-5">
          <selected-search-filters-dep [filter]="f" *ngFor="let f of filters.all"></selected-search-filters-dep>
        </div>
      </div>
    </div>

    <div *ngIf="mode === 'calendar'">
      <learning-calendar
        *ngIf="calendarMode === 'default'"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [registeredOnly]="registeredOnly"
        [groupIds]="groupIds"
        [sessionLabels]="sessionLabels"
        [showRegistrationStatus]="showRegistrationStatus"
        (calendarChange)="onCalendarViewChange($event)"
        [view]="calendarView"></learning-calendar>
      <calendar-list-view
        *ngIf="calendarMode === 'list'"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [registeredOnly]="registeredOnly"
        [groupIds]="groupIds"
        [sessionLabels]="sessionLabels"
        [showRegistrationStatus]="showRegistrationStatus"
        (calendarChange)="onCalendarViewChange($event)"
        [view]="calendarView"></calendar-list-view>

      <div class="standard-margin-top">
        <ng-content select=".calendar-legend" class="standard-margin-top"></ng-content>
      </div>
    </div>

    <div *ngIf="mode === 'search'">
      <calendar-event-search-list
        [items]="items"
        [total]="itemsCount"
        [disabled]="searching"
        (requestMoreChange)="loadMore()"></calendar-event-search-list>
    </div>
  </div>
</div>
