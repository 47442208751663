import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsUtils } from 'core/utils';
import { ISearchQueryParams } from 'modules/search/models/search.models';
import { Observable } from 'rxjs';
import { ICourseProviderOrganization } from '../models/course-provider-organization.model';

export enum CourseProviderOrganizationEndpoints {
  GET = '/a/course/providers/organizations/:organizationId/',
  QUERY = '/a/course/providers/organizations/',
}

@Injectable()
export class CourseProviderOrganizationService {
  constructor(private http: HttpClient) {}

  public get(organizationId: number): Observable<ICourseProviderOrganization> {
    return this.http.get<ICourseProviderOrganization>(
      ElmsUtils.formatUrl(CourseProviderOrganizationEndpoints.GET, { organizationId }),
    );
  }

  public query(params?: ISearchQueryParams): Observable<ICourseProviderOrganization[]> {
    let queryParams = new HttpParams();

    if (params) {
      queryParams = queryParams.set('query', JSON.stringify(params));
    }

    return this.http.get<ICourseProviderOrganization[]>(CourseProviderOrganizationEndpoints.QUERY, {
      params: queryParams,
    });
  }

  public transform(
    originalData: ICourseProviderOrganization,
    defaultData: Partial<ICourseProviderOrganization> = {},
  ): ICourseProviderOrganization {
    return {
      ...defaultData,
      ...originalData,
    };
  }
}
