import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { SendEmailModalComponent } from 'modules/emails/modal/send-email-modal.component';
import { IEmail } from 'modules/emails/models/email.model';
import { EMPTY, Observable, catchError, from } from 'rxjs';
import * as uuid from 'uuid';
import { IEmailPreviewOptions } from '../../admin/emails/components/models/email-options.models';
import { EmailPreviewModalComponent } from '../modal/email-preview-modal.component';

enum EmailServiceEndpoints {
  // GET = '/a/emails/:emailId/',
  POST = '/a/emails/',
}

@Injectable()
export class EmailService {
  constructor(
    private ngbModalService: NgbModal,
    private http: HttpClient,
  ) {}

  public post(payload: FormData, throwHttpErrors = false): Observable<null> {
    return this.http.post<null>(EmailServiceEndpoints.POST, payload, {
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  public composeEmail(email: Partial<IEmail>): IEmail {
    return {
      subject: email.subject || null,
      body: email.body || null,
      globalId: uuid.v4(),
      recipients: email.recipients || [],
      recipientsProvider: email.recipientsProvider || null,
      attachments: [],
    };
  }

  previewEmailDialog(params: Partial<IEmailPreviewOptions>): Observable<void> {
    const modalReference = this.ngbModalService.open(EmailPreviewModalComponent, {
      backdrop: 'static',
      size: 'lg',
      animation: true,
    });

    const component = modalReference.componentInstance as EmailPreviewModalComponent;

    component.patternId = params.patternId;
    component.variables = params.variables;
    component.courseId = Number(params.courseId);
    component.notification = params.notification;

    return from(modalReference.result).pipe(catchError(() => EMPTY));
  }

  composeEmailDialog(email: IEmail, recipientsReadOnly: boolean, allowAddition: boolean): Observable<void> {
    const modalReference = this.ngbModalService.open(SendEmailModalComponent, {
      backdrop: 'static',
      animation: true,
      size: 'lg',
    });

    const component = modalReference.componentInstance as SendEmailModalComponent;

    component.email = email;
    component.recipientsReadOnly = recipientsReadOnly;
    component.allowAddition = allowAddition;

    return from(modalReference.result).pipe(catchError(() => EMPTY));
  }
}
