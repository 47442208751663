import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import { courseRegistrationStatuses } from 'modules/course-registrations/models/course-registration-status.model';
import {
  ICollectionItemBase,
  ICollectionSectionItem,
  isCollectionCourse,
  isCollectionSection,
} from 'modules/course/common/models/learning-object-structure.model';
import { ICollectionWithRegistration } from 'modules/course/player/collection/components/collection-player.component';
import { Player } from 'modules/course/player/services/player.service';
import { IFlattenCourse } from 'modules/course/services/course-components.service.ajs-upgraded-provider';

export type IChunkItem = ICollectionItemBase | ICollectionItemBase[];
@Component({
  standalone: false,
  selector: 'collection-playlist-structure-layer',
  templateUrl: './collection-structure-layer.component.html',
})
export class CollectionStructureLayerComponent implements OnInit {
  static readonly selector = 'collectionPlaylistStructureLayer';

  @Input() items: ICollectionItemBase[];
  @Input() runningCoursePlayer: Player;
  @Input() currentCourse: ICollectionWithRegistration;
  @Input() itemTitle?: string;
  @Input() index?: number;
  @Output() courseStarted = new EventEmitter<number>();

  isCollapsed = false;
  chunks: IChunkItem[];
  settings = this.globalConfig.settings.coursePlayer.collectionPlaylist;

  constructor(private globalConfig: GlobalConfig) {}

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  isCourse(item: ICollectionItemBase | ICollectionItemBase[]): item is IFlattenCourse {
    return !this.isArray(item) && isCollectionCourse(item as ICollectionItemBase);
  }

  isSection(item: ICollectionItemBase | ICollectionItemBase[]): item is ICollectionSectionItem {
    return !this.isArray(item) && isCollectionSection(item as ICollectionItemBase);
  }

  ngOnInit() {
    this.initListChunks();
  }

  isArray(item: IChunkItem): item is IFlattenCourse[] {
    return Array.isArray(item);
  }

  isCompleted(statusId: number) {
    return courseRegistrationStatuses.strictCompletedSet.includes(statusId);
  }

  calculateCourseCount(items: IChunkItem[]) {
    let courseCount = 0;

    items.forEach((i) => {
      courseCount += Array.isArray(i) ? i.filter((c) => c.type === 1).length : Number(i.type === 1);
    });

    return courseCount;
  }

  includesCoursesAndSections(items: IChunkItem[]) {
    return items && items.filter((i) => Array.isArray(i)).length !== items.length;
  }

  startCourse(courseId: number) {
    this.courseStarted.emit(courseId);
  }

  private initListChunks() {
    const chunk = [];

    this.chunks = [];

    for (let i = 0; i < this.items.length; i++) {
      if (isCollectionSection(this.items[i])) {
        if (chunk.length) {
          this.chunks.push(chunk.slice());
          chunk.length = 0;
        }

        this.chunks.push(this.items[i]);
      } else {
        chunk.push(this.items[i]);
      }
    }

    if (chunk.length) {
      this.chunks.push(chunk);
    }
  }
}
