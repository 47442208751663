<ng-container *ngIf="!checkoutSuccess">
  <div class="">
    <h3 class="no-margin-top sm-margin-bottom">Checkout</h3>
  </div>

  <div *ngIf="!productItem">
    <div class="cart-empty">
      <div class="h1 cart-icon">
        <span class="fa fa-shopping-cart"></span>
      </div>

      <span class="h2 no-margin-top">There are no items to checkout</span>
    </div>
  </div>

  <div *ngIf="!!productItem" class="shopping-list">
    <div>
      <div>
        <e-commerce-product [product]="productItem" [purchaseError]="purchaseProductError"></e-commerce-product>
      </div>

      <div class="cart-total h3 sm-margin-bottom">
        Total:
        <span class="cart-total-amount"
          ><course-price [coursePrice]="{ amount: productItem.price, currency: productItem.currency }"></course-price>
        </span>
      </div>
    </div>
  </div>

  <fieldset [disabled]="!!purchaseSubscription" class="text-right sm-margin-bottom" *ngIf="productItem">
    <legend class="sr-only">Actions</legend>

    <div *ngIf="!purchaseSubscription && !purchase?.id">
      <button
        type="button"
        title="Proceed to checkout"
        class="btn btn-primary btn-block-xs"
        *ngIf="!!productItem"
        (click)="checkout()">
        Checkout
      </button>
    </div>

    <div *ngIf="!!purchaseSubscription">
      <button type="button" class="btn btn-primary btn-block-xs" [ladda]="!!purchaseSubscription">
        Confirming... <span class="fa fa-check"></span>
      </button>
    </div>
  </fieldset>

  <div *ngIf="purchaseError" class="alert alert-sm alert-danger" role="alert">
    {{ purchaseError }}
    <span *ngIf="isStateAvailable('main.support')">
      Please visit <a title="Help" uiSref="main.support">Help</a> and contact support.
    </span>
  </div>
</ng-container>
<ng-container *ngIf="!!checkoutSuccess">
  <form ngNoForm #redirectPost name="redirectPost" [action]="processorEndpoint" method="post" target="_self">
    <input type="hidden" id="securetoken" name="securetoken" [value]="securityToken" />
    <input type="hidden" id="securetokenid" name="securetokenid" [value]="securityTokenId" />
    <input [hidden]="true" type="hidden" name="redirectTo" value="Submit" />
  </form>
</ng-container>
