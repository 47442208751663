<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Preview email</h4>
</div>

<div class="modal-body">
  <email-preview
    [patternId]="patternId"
    [variables]="variables"
    [courseId]="courseId"
    [notification]="notification"></email-preview>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="cancel()">Close</button>
</div>
