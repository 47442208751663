import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IFormOptions } from 'components/forms';
import { omit } from 'lodash';
import { EMPTY, catchError } from 'rxjs';
import { IEmail, IEmailForm } from '../models/email.model';
import { EmailService } from '../services/email.service';

@Component({
  standalone: false,
  selector: 'send-email-modal',
  templateUrl: './send-email-modal.component.html',
})
export class SendEmailModalComponent implements OnInit {
  @Input() email: IEmail;
  @Input() recipientsReadOnly: boolean;
  @Input() allowAddition: boolean;

  error: string;
  processing: boolean;
  view: 'compose' | 'error' | 'success' = 'compose';
  formOptions: IFormOptions = { submitted: false };

  constructor(
    private activeModal: NgbActiveModal,
    private emailService: EmailService,
  ) {}

  ngOnInit(): void {
    this.email.attachments = [];
  }

  retry() {
    this.processing = false;
    this.view = 'compose';
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  onSubmit(form: FormGroup<{ emailForm: FormGroup<IEmailForm> }>) {
    const emailForm = form.value.emailForm;
    const formData = new FormData();

    formData.append('email', JSON.stringify(omit(emailForm, 'attachments')));

    if (emailForm.attachments.length) {
      for (const attachment of emailForm.attachments) {
        formData.append('attachments', attachment);
      }
    }

    this.processing = true;
    this.emailService
      .post(formData, true)
      .pipe(catchError((er) => this.catchError(er)))
      .subscribe(() => {
        this.view = 'success';
        this.processing = false;
      });
  }

  private catchError(response: HttpErrorResponse) {
    this.view = 'error';
    this.error = response.error as string;

    return EMPTY;
  }
}
