import { Component, Inject, Input } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import {
  LearningObjectRegistrationWorkflowFactory,
  LearningObjectRegistrationWorkflowService,
  LearningObjectRegistrationWorkflowServiceFactory,
} from 'modules/course-registrations/services/learning-object-registration-workflow.service.ajs-upgraded-provider';
import { LearningObjectRegistrationService } from 'modules/course-registrations/services/learning-object-registration.service.ajs-upgraded-provider';
import { TrainingService } from 'modules/course-registrations/services/training.service.ajs-upgraded-provider';
import { Player } from 'modules/course/player/services/player.service';
import { tap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Component({
  standalone: false,
  selector: 'collection-tile',
  templateUrl: './collection-tile.component.html',
})
export class CollectionTileComponent {
  @Input() currentBlsId: number | string;
  @Input() course: { id: number | string; name: string };
  @Input() player: Player;
  @Input() trackingName: string;
  @Input() actionData: { current_bls_id: number | string };

  workflow: LearningObjectRegistrationWorkflowService;

  constructor(
    private trainingService: TrainingService,
    private learningObjectRegistrationService: LearningObjectRegistrationService,
    @Inject(LearningObjectRegistrationWorkflowFactory)
    private workflowFactoryService: LearningObjectRegistrationWorkflowServiceFactory,
    private window: Window,
    private backUrlService: BackUrlService,
    private stateService: StateService,
  ) {}

  confirm() {
    this.player.leave().subscribe(() => {
      this.execute();
    });
  }

  private execute() {
    if (this.currentBlsId) {
      if (!this.workflow) {
        this.loadWorkflow(() => this.executeAction());
      } else {
        this.executeAction();
      }
    } else {
      this.window.open(this.stateService.href('main.course', { id: this.course.id }), '_blank');
    }
  }

  private loadWorkflow(callback: () => void) {
    fromPromise(this.learningObjectRegistrationService.get(this.course)).pipe(
      tap((registration) => {
        this.workflow = this.workflowFactoryService(registration);
        this.workflow.registration.current_bls_id = parseInt(this.currentBlsId.toString());
      }),
      tap(() => {
        callback();
      }),
    );
  }

  private executeAction() {
    const workflowActions = this.workflow
      .getAvailableLaunchTypes()
      .sort((a, b) => a.playCourseOrder - b.playCourseOrder);

    if (this.trainingService.canShowContent(this.workflow.registration) && workflowActions.length > 0) {
      this.backUrlService.bypassHistory();
      this.workflow.exec(workflowActions[workflowActions.length - 1].actionName, this.actionData);
    } else {
      this.goToCourseDetails();
    }
  }

  private goToCourseDetails() {
    this.backUrlService.passThroughRedirect('main.course', { id: this.course.id });
  }
}
