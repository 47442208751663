import { Injectable } from '@angular/core';
import { ISessionSchedule, IUserCourseSessionSchedule } from '../models/course-session.models';

@Injectable()
export class CourseSessionManagerService {
  processSessionSchedules(data: IUserCourseSessionSchedule[]): ISessionSchedule[] {
    const grouped = data.reduce(
      (acc, schedule) => {
        const date = schedule.scheduleDate;

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(schedule);

        return acc;
      },
      Object.create(null) as Record<string, IUserCourseSessionSchedule[]>,
    );

    return Object.entries(grouped).map(([date, schedules]) => {
      const map = {};
      const locations = [];

      schedules.forEach((schedule) => {
        if (schedule.location || schedule.internetLocation) {
          const location = { ...schedule.location, times: [] };

          if (!map[schedule.locationId]) {
            map[schedule.locationId] = location;
            locations.push(location);
          }

          map[schedule.locationId].times.push({
            startDateTime: schedule.startDateTime,
            endDateTime: schedule.endDateTime,
          });
        }
      });

      return {
        date: date,
        startDateTime: schedules[0].startDateTime,
        endDateTime: schedules[schedules.length - 1].endDateTime,
        locationDescription: schedules[0].locationDescription,
        onlineMeetingIntegration: schedules[0].onlineMeetingIntegration,
        internetLocation: schedules[0].internetLocation,
        locations: locations,
        languages: schedules[0].languages,
      };
    });
  }
}
