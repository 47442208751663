import { IUploadedFile } from 'core/models';
import { ICourseRegistrationQuestion } from 'modules/course-registrations/models/course-registration-question.model';
import { ICourseRegistration } from 'modules/course-registrations/models/course-registration.model';
import { ICourseCredit } from 'modules/course/credits/models/course-credit.models';
import { ICourseProviderOrganization } from 'modules/course/providers/organizations/models/course-provider-organization.model';
import { ICourseSession, ILanguage } from 'modules/course/sessions/models/course-session.models';
import { ITag } from 'modules/tags/models/tags.model';
import { IEntityUser, IUserName } from 'modules/user/models/user.model';
import { IGroupBaseInfo, IGroupShortInfo } from 'modules/group/models/group.model';

export interface ICoursePrice {
  amount: number;
  currency?: string;
}

export interface ICourseTileData {
  createdBy: IUserName;
  learners: number;
  created?: string;
  updated: string;
  startDate: string | null;
  endDate: string | null;
  active: boolean;
  courseFormat: ICourseFormat;
  approvalStatusId: number;
  deactivationDate: string | null;
  organization_name: string;
}

export interface ICourseAbstract {
  id: string | number;
  name: string;
  description: string;
  formatId: number;
  formatTypeId: number;
  formatName?: string | null;
  labelName?: string | null;
  active: boolean;
  length: number;
  createdBy?: IEntityUser;
  updatedBy?: IEntityUser;
}

export interface ICourse extends ICourseAbstract {
  title?: string;
  length: number;
  formatTypeId: number;
  formatName?: string | null;
  courseLocation: Partial<ICourseLocation> | null;
  showOnlyInBls: boolean;
  approvalStatusId: number;
  expirationDate: string;
  published?: boolean;
  publishDate: string;
  startDate: string;
  endDate: string;
  courseFormat: ICourseFormat;
  providesMultipleCredits: boolean;
  attendCap: number;
  attendVirtualCapacity: number;
  tags: ITag[];
  registration?: ICourseRegistration;
  organization_name?: string;
  deactivationDate?: string;
  rating?: number;
  permissions: string[];
  conferenceCourseId: number;
  conferenceCourseName: string;
  mayHaveMultipleQuizzes: boolean;
  ratingCount?: number;
  learners?: number;
  modified?: string;
  paymentApproval?: boolean;
  creditTypes?: Partial<ICourseCredit>[];
  certificates?: string[];
  label: {
    id: number;
    name: string;
  };
  price?: number | ICoursePrice;
  contacts?: ICourseContact[];
  courseUrl?: string;
  format?: ICourseFormat | number;
  registrationsAutoExpire?: boolean;
  ratings?: ICourseReviewStat;
  ratingPrompt?: boolean;
  version?: string;
  customFields?: ICourseRegistrationQuestion[];
  relatedData?: {
    registrationCustomFields?: number[];
    registrations?: number;
  };
  previewImage?: IUploadedFile;
  heroImage?: IUploadedFile;
  editHistory?: ICourseEditHistory[];
  subjectAreas?: ITag[];
  targetAudiences?: ITag[];
}

export interface ICourseForAdmin extends ICourse {
  courseNo: string | null;
  providerCourseNo: string;
  skillLevelId: number;
  skillLevelName: string;
  skillLevelDescription: string;
  systemFormat: boolean;
  prerequisite: string | null;
  aboutAuthor: string | null;
  learningObjectives: string;
  sponsor: string;
  notificationEmail: string;
  accreditation: string | null;
  scorm: boolean;
  masteryScore: boolean;
  certificate: boolean;
  customDescription: string | null;
  forceNewWindow: boolean;
  createdDate: string;
  updatedDate: string;
  markdownEnabled: boolean;
  allowRegisterComponents: boolean;
  allowRegisterOnce: boolean;
  registrationDeadline: string | null;
  registrationOpensDate: string | null;
  listPrice: number | null;
  collectResultsAnonymously: boolean;
  showBlsAfterRegistration: boolean;
  registrationAttemptsLimit: number | null;
  completionTypeId: number;
  continuingEducation: ICourseContinuingEducation;
  hasCompetencyNodes: number;
  media: any[];
  liveEventOnInternet: boolean;
  multiplePrices: any[] | null;
  organizations: ICourseOrganization[];
  languages: ILanguage[];
  deviceResolutions: any[];
  revisedDate: string | null;
  clinical: boolean;
  materialsOrderEmail: string | null;
  prerequisiteTypeId: number;
  prerequisiteComments: string | null;
  listPriceComments: string | null;
  compilationInstructions: string | null;
  audienceBackgroundRoles: any[];
  recurrenceSettings: any | null;
  availabilityAffiliates: string[];
  attendCapacity: number | null;
  breeze_view_ios_app_url: string | null;
  hasDocuments: boolean;
  hasComponents: boolean;
  launchUrl: string | null;
  transcript: string | null;
  internalCourseTranscript: boolean;
  manifestUrl: string | null;
  visible: boolean;
  parentId: number | null;
  materialOrdersUrl: string | null;
  incompleteExpirationDays: number | null;
  approvalDate: string;
  approvedBy: IEntityUser;
  providerApprovalBased: boolean;
  externalIntegration: boolean;
  externalIntegrationWithdraw: boolean;
  showCustomDescriptionTab: boolean;
  customDescriptionTabName: string | null;
  registrationRequired: boolean;
  courseProviderId: number | null;
  addParamsToUrl: string | null;
  allowMaterialsOrderEmail: boolean;
  competenciesNotApplicable: string | null;
  approvalCode: string | null;
  codeBasedApproval: boolean;
  sendDelayedEmail: boolean;
  delayedEmailDayCount: number | null;
  delayedEmailSubject: string | null;
  delayedEmailBody: string | null;
  delayedEmailTypeId: number | null;
  delayedEmailPriority: string;
  autoVerify: boolean;
  prerequisites: any[];
  competencyNodes: any[];
  reviewEmails: any[];
  scormManifestInfo: any[];
  pricing: any[];
  displayPrices: any[];
  attributes: any[];
  approverComments: string | null;
  targetCapabilities: any[];
  groupTags: any[];
  documents: any[];
  collectNPINumber: boolean;
  lockRegistrationWithdrawal: boolean;
  groups: IGroupBaseInfo[];
}

export interface IBMSCourse extends ICourse {
  trainingCategories?: [];
}

export interface ICourseEditHistory {
  courseId: number;
  updatedDate: string;
  updatedByUserId: number;
  updatedByUser: IEntityUser;
}

export interface ICourseLocation {
  id: number;
  courseId: number;
  locationDescription: string;
  virtualLocationUrl: string;
  typeId: number;
  type: ICourseLocationType;
}

export interface ICourseReviewStat {
  visible: boolean;
  rating: number;
  stars: any;
  count: number;
  allow_addition: boolean;
  allow_edit: boolean;
  has_review: boolean;
  available: boolean;
  prompt_after_completed: boolean;
}

export interface ICourseLocationType {
  id: number;
  name: string;
  typeId: number;
  system: boolean;
  registrationRequired: boolean;
}

export interface ILabel {
  id: number;
  name: string;
  active: boolean;
}

export interface ICourseFormat {
  id: number;
  name: string;
  typeId: number;
  system: boolean;
  registrationRequired: boolean;
  labels?: ILabel[];
}

export interface ICourseContact {
  id: string;
  name: string;
  email: string;
  phone: string;
  url: string;
  description: string | null;
}

export type ICourseTile = ICourse & ICourseTileData;

export interface ICourseOrganization {
  id: number;
  organizationId: number;
  courseId: number;
  typeId: number;
  organization: ICourseProviderOrganization;
}

export interface ICourseCustomField {
  id: number;
  description: string;
  required: boolean;
  detailedText: string;
  value: string;
  courseId: number;
  registrationId: number;
}

export enum CourseMediaType {
  introductionVideo = 1,
  videoContent = 2,
  content = 3,
  googleDrive = 4,
  office365 = 5,
  scormPackage = 6,
  contentWithName = 7,
  article = 8,
  scormCloud = 9,
  scormManifest = 10,
}

export interface ICourseMedia {
  type: CourseMediaType;
  data: string;
}

export interface ICourseContinuingEducation {
  id: number;
  courseId: number;
  ceStartDate: string;
  ceEndDate: string;
}

// TODO Refactor to ICourse object.
export interface ICourseDetails extends Omit<ICourseAbstract, 'id'> {
  id: number;
  courseNo: string;
  providerCourseNo: string;
  abstract: string;
  courseUrl: string;
  length: number;
  skillLevelId: number;
  skillLevelName: string;
  skillLevelDescription: string;
  courseFormat: ICourseFormat;
  formatTypeId: number;
  format: number;
  formatId: number;
  systemFormat: boolean;
  prerequisite: string;
  agenda: string;
  aboutAuthor: string;
  learningObjectives: string;
  specialNotes: string;
  sponsor: string;
  notificationEmail: string;
  accreditation: string;
  approvalStatusId: number;
  scorm: boolean;
  masteryScore: boolean;
  certificate: boolean;
  previewImage: string;
  heroImage: string;
  customDescription: string;
  createdDate: string;
  updatedDate: string;
  creditTypes: ICourseCredit[];
  tags: ITag[];
  markdownEnabled: boolean;
  label: ILabel;
  contacts: ICourseContact[];
  allowRegisterComponents: boolean;
  allowRegisterOnce: boolean;
  deactivationDate: string;
  registrationDeadline: string;
  registrationOpensDate: string;
  listPrice: number;
  collectResultsAnonymously: boolean;
  showBlsAfterRegistration: boolean;
  registrationAttemptsLimit: number;
  /* describe what this type means (1, 2): ... */
  completionTypeId: number;
  publishDate: string;
  registrationsAutoExpire: boolean;
  providesMultipleCredits: boolean;
  mayHaveMultipleQuizzes: boolean;
  continuingEducation: ICourseContinuingEducation | null;
  courseLocation: ICourseLocation;
  hasCompetencyNodes: number;
  paymentApproval: boolean;
  media: ICourseMedia[];
  liveEventOnInternet: boolean;
  multiplePrices: null;
  organizations: ICourseOrganization[];
  subjectAreas: ITag[];
  targetAudiences: ITag[];
  languages: ITag[];
  certificates: [];
  startDate: string;
  endDate: string;
  timeZoneId: string;
  allowSessionsOverlap: boolean;
  autoCompleteSessions: boolean;
  permissions: string[];
  availabilityAffiliates?: string[];
  accreditations: [];
  attendCap: number;
  attendCapacity: number;
  attendVirtualCapacity: number;
  ratings: {
    visible: boolean;
    rating: number;
    count: number;
    allow_addition: boolean;
    allow_edit: boolean;
    has_review: boolean;
    available: boolean;
    prompt_after_completed: boolean;
  };
  price: number | ICoursePrice;
  breeze_view_ios_app_url: string;
  hasDocuments: boolean;
  hasComponents: boolean;

  conferenceCourseId: number;
  custom_fields: ICourseCustomField[];
  check_edit_registration_deadline: boolean;
  check_registration_deadline: boolean;
  custom_fields_required: boolean;
  seatsAllocated: number;

  sessions?: ICourseSession[];
  current_bls_id?: number;

  groups?: IGroupShortInfo[];
}

export interface ICourseTrainingOptions {
  virtualAllocated: number;
  inPersonAllocated: number;
}

export interface ILocationAttendanceOptions {
  virtualAvailable: number;
  inPersonAvailable: number;
}

export interface IApprovalStatusItem {
  id: number;
  name: string;
}
