<span
  class="tw-flex tw-items-center tw-gap-1.5"
  *ngIf="courseRatingAvailable && courseRatingCount && courseRatingCount >= minRatingCount"
  [attr.aria-label]="'Course rating: ' + courseRating.toFixed(1)">
  <star-rating [rating]="courseRating" *ngIf="!singleStar"></star-rating>
  <a
    href="#"
    *ngIf="!singleStar && count"
    class="tw-text-gray-11"
    (click)="openCourse()"
    [attr.aria-label]="'Reviews count: ' + courseRatingCount"
    ><span aria-hidden="true">({{ courseRatingCount || 0 }})</span></a
  >
  <single-star-rating [rating]="courseRating" *ngIf="singleStar"></single-star-rating>
</span>
