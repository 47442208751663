import { Component, Input, OnInit } from '@angular/core';
import { IBlogPost } from 'modules/blog/models/blog-post.model';

@Component({
  standalone: false,
  selector: 'blog-post-list-tile',
  templateUrl: './blog-post-list-tile.component.html',
})
export class BlogPostListTileComponent implements OnInit {
  static readonly selector = 'blogPostListTile';
  @Input() post: IBlogPost;
  heroBackground?: string;

  ngOnInit(): void {
    if (this.post.heroImageId) {
      this.heroBackground = `url(/a/blog_posts/${this.post.id}/hero/)`;
    }
  }
}
