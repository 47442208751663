import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { Transition } from '@uirouter/core';
import _ from 'lodash';
import { LearningObjectRegistrationService } from 'modules/course-registrations/services/learning-object-registration.service.ajs-upgraded-provider';
import { CourseService } from 'modules/course/common/services/course.service';
import { lastValueFrom, mergeMap } from 'rxjs';
import { PayLaterComponent } from './components/pay-later.component';

export const ecommercePayLaterRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.pay-later',
    url: '/pay-later/{courseId:int}/{sessionId:int}/',
    component: PayLaterComponent,
    data: {
      label: 'Pay Later',
      availableFor: ['regularUser', 'admin'],
      dependsOn: _.get((<any>window)._globalConfig, 'settings.ecommerceEnabled'),
    },
    resolve: [
      {
        provide: 'registration',
        deps: [Transition, CourseService, LearningObjectRegistrationService],
        useFactory: (
          transition: Transition,
          courseService: CourseService,
          registrationService: LearningObjectRegistrationService,
        ) => {
          return lastValueFrom(
            courseService
              .get(transition.params().courseId)
              .pipe(mergeMap((course) => registrationService.get(course, null))),
          );
        },
      },
      {
        provide: 'sessionId',
        deps: [Transition],
        useFactory: (transition: Transition) => transition.params().sessionId,
      },
      {
        provide: 'courseId',
        deps: [Transition],
        useFactory: (transition: Transition) => transition.params().courseId,
      },
    ],
  },
];
