import { EventInput, EventSourceFuncArg } from '@fullcalendar/core';
import { ICourseCreditAmount, ICourseSearchItem } from 'modules/course/common/models/course-search.model';
import { ICourse, ICourseFormat } from 'modules/course/common/models/course.model';
import { ISearchQueryParams } from 'modules/search/models/search.models';
import { IUser } from 'modules/user/models/user.model';
import { Moment } from 'moment';

export type ICalendarSuccessCallback = (inputs: EventInput[]) => void;
export type ICalendarFailureCallback = (error: Error) => void;
export type IEventSources = (
  info: EventSourceFuncArg,
  successCallback?: ICalendarSuccessCallback,
  failureCallback?: ICalendarFailureCallback,
) => void;

export type ICalendarViewMode = 'dayGridMonth' | 'timeGridWeek' | 'timeGridDay' | 'listWeek';
export type ICalendarDataModes = 'public' | 'admin' | 'adminUserCoursesSearch';

export interface ICalendarQueryParams extends ISearchQueryParams {
  group_id: string;
  user_courses: boolean | null;
  session_label_id: string;
  min_start_date: string;
  max_start_date: string;
}

export interface ICalendarConfig {
  user: IUser;
  mode: ICalendarDataModes | null;
  filters?: string;
}

export interface IEventLabel {
  key: string;
  name: string;
  className: string[];
  badgeClassName?: string[];
}

export interface ICalendarView {
  minDate: string;
  maxDate: string;
  view: string;
}

export interface ICalendarSearchItem extends ICourseSearchItem {
  id: number;

  labelName?: string;
  registered?: boolean;
  waitList?: boolean;
  unavailable?: boolean;
  course_format_type_id?: number;

  start?: string | null;
  end?: string | null;

  approval_status_id: number;
  location?: string | null;
  providesMultipleCredits?: boolean;
}

export interface ICalendarEvent {
  id?: number;
  start: string | null;
  end: string | null;
  location: string | null;
  locationTypeId?: number | null;
  allDay: boolean | null;

  type?: string | null;
  url?: string | null;
  name?: string | null;
  title?: string | null;
  format?: number;
  formatId?: number;

  sessionId?: number;
  scheduleId?: number;
  sessionType?: string;
  conferenceSessionId?: number;
  startOrigin?: string;
  endOrigin?: string;

  className?: string[] | null;
  labels?: IEventLabel[];
  courseFormat?: ICourseFormat | null;
  approvalStatusId?: number | null;
  providesMultipleCredits?: boolean;

  labelName?: string;
  registered?: boolean;
  waitList?: boolean;
  unavailable?: boolean;
  sponsor?: string;

  course_format_type_id?: number;
  creditTypes?: ICourseCreditAmount[];
}

export interface ICalendarListItem extends ICalendarEvent {
  contentOpen: boolean;
  startTime: string | null;
  endTime: string | null;
  displayDate: string | null;
  sponsor?: string;
  course?: Partial<ICourse>;
}

export enum CalendarViewsMomentMap {
  dayGridMonth = 'month',
  timeGridWeek = 'week',
  timeGridDay = 'day',
}

export interface ICalendarState {
  viewType: ICalendarViewMode;
  intervalUnit: 'month' | 'week' | 'day';
  date?: Moment;
  start?: Moment;
  end?: Moment;

  startInterval?: Moment;
  endInterval?: Moment;
}
