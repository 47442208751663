import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ElmsUtils } from 'core/utils';
import { IExternalApplication } from 'modules/external-applications/models/external-application.model';
import { Observable } from 'rxjs';

enum ExternalApplicationsEndpoints {
  GET = '/a/external-applications/:applicationId/',
  QUERY = '/a/external-applications/',
  CREATE = '/a/external-applications/',
  UPDATE = '/a/external-applications/:applicationId/',
  DELETE = '/a/external-applications/:applicationId/',
}

@Injectable()
export class ExternalApplicationService {
  constructor(private http: HttpClient) {}

  public get(applicationId: number): Observable<IExternalApplication> {
    return this.http.get<IExternalApplication>(
      ElmsUtils.formatUrl(ExternalApplicationsEndpoints.GET, { applicationId }),
    );
  }

  public query(throwHttpErrors = false): Observable<IExternalApplication[]> {
    return this.http.get<IExternalApplication[]>(ExternalApplicationsEndpoints.QUERY, {
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  public save(payload: Partial<IExternalApplication>, throwHttpErrors = false): Observable<IExternalApplication> {
    return this.http.post<IExternalApplication>(ExternalApplicationsEndpoints.CREATE, payload, {
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  public update(
    applicationId: number,
    payload: Partial<IExternalApplication>,
    throwHttpErrors = false,
  ): Observable<IExternalApplication> {
    return this.http.post<IExternalApplication>(
      ElmsUtils.formatUrl(ExternalApplicationsEndpoints.UPDATE, { applicationId }),
      payload,
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) },
    );
  }

  public delete(applicationId: number): Observable<void> {
    return this.http.delete<void>(ElmsUtils.formatUrl(ExternalApplicationsEndpoints.DELETE, { applicationId }));
  }
}
