<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">{{ instructor.firstName }} {{ instructor.lastName }}</h4>
</div>

<div class="modal-body modal-scrollable">
  <div [innerHtml]="instructor.profile | markdownToHtml"></div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="cancel()">Close</button>
</div>
