<div
  class="flex collection-player-list-header"
  [ngClass]="{ 'is-open': !isCollapsed, 'is-collapsed': isCollapsed }"
  *ngIf="itemTitle && calculateCourseCount(chunks) && !includesCoursesAndSections(chunks)">
  <button
    type="button"
    class="btn-text flex-row outline-none"
    (click)="toggleCollapsed()"
    title="Expand"
    aria-label="Expand/Collapse">
    <div class="flex-col flex-col-grow">
      <h4 class="collection-title pull-left">{{ itemTitle }}</h4>
    </div>
    <div class="flex-col flex-col-2x text-right align-self-center hidden-print">
      <span class="fa fa-chevron-up" [ngClass]="{ 'fa-chevron-down': isCollapsed }"></span>
    </div>
  </button>
</div>

<div *ngIf="!isCollapsed">
  <div *ngFor="let chunk of chunks">
    <div
      *ngIf="isArray(chunk) && chunks.length === 1"
      class="collection-overlay"
      [ngClass]="{ 'has-title': itemTitle }">
      <ul class="list-unstyled collection-list collection-player-list">
        <li
          class="content-list-item collection-item"
          *ngFor="let course of chunk"
          [id]="course.id"
          [ngClass]="{
            'player-highlighted-collection-item': currentCourse?.id === course.id,
            'collection-completed-component': isCompleted(course.status_id),
          }">
          <div *ngIf="isCourse(course)">
            <div class="flex">
              <button
                type="button"
                class="btn-text flex-row tile-course-row"
                (click)="startCourse(course.id)"
                [id]="'course_player_collection_component_' + course.id + '_start'">
                <div class="flex-col col-collection-play">
                  <div class="collection-play-internal">
                    <!--required nested flex to get proper vertical alignment-->
                    <span
                      class="fa fa-play font-16px text-primary"
                      aria-hidden="true"
                      *ngIf="currentCourse?.id === course.id"></span>
                    <span class="collection-course-number" *ngIf="currentCourse?.id !== course.id && settings?.numbers">
                      {{ course.number }}
                    </span>
                  </div>
                </div>
                <div class="flex-col col-thumbnail collection-thumbnail">
                  <div
                    *ngIf="isCompleted(course.status_id)"
                    class="thummbnail-completed-overlay"
                    aria-hidden="true"></div>
                  <course-thumbnail
                    [course]="course"
                    [disableDefaultIcon]="isCompleted(course.status_id)"></course-thumbnail>
                </div>
                <div class="flex-col flex-col-grow col-course-name">
                  {{ course.name }}
                  <div class="collection-player-hours" *ngIf="course.length">
                    <span class="fa-regular fa-clock inline-block xs-margin-right"></span>{{ course.length }}&#8201;h
                  </div>
                </div>
              </button>

              <div
                class="flex-row next-step-row"
                *ngIf="
                  runningCoursePlayer?.registrationWorkflow.registration.courseId === course.id &&
                  runningCoursePlayer?.currentStep &&
                  runningCoursePlayer?.getNextAvailableStep(true) &&
                  !runningCoursePlayer?.getNextAvailableStep(true).contentStep &&
                  !runningCoursePlayer?.getNextAvailableStep(true).completed
                ">
                <div class="flex-col flex-col-grow">
                  <button
                    type="button"
                    class="btn btn-primary btn-block btn-next-step"
                    *ngIf="
                      (runningCoursePlayer?.registrationWorkflow.registration.course.format !== 101 &&
                        runningCoursePlayer?.registrationWorkflow.registration.course.format !== 103) ||
                      (!runningCoursePlayer?.registrationWorkflow.hasAction('MoveToPostAssessmentAction') &&
                        !runningCoursePlayer?.registrationWorkflow.hasAction('MoveToEvaluationPendingAction'))
                    "
                    [disabled]="!runningCoursePlayer?.getNextAvailableStep(true).available"
                    (click)="runningCoursePlayer?.launchNextStep()"
                    [id]="'course_player_collection_component_' + course.id + '_next_step'">
                    Take
                    {{ runningCoursePlayer?.getNextAvailableStep(true).title }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-block btn-next-step"
                    *ngIf="
                      (runningCoursePlayer?.registrationWorkflow.registration.course.format === 101 ||
                        runningCoursePlayer?.registrationWorkflow.registration.course.format === 103) &&
                      runningCoursePlayer?.registrationWorkflow.hasAction('MoveToPostAssessmentAction')
                    "
                    (click)="runningCoursePlayer?.executeAndLaunchNextStep('MoveToPostAssessmentAction')"
                    [id]="'course_player_collection_component_' + course.id + '_take_assessment'">
                    Take {{ runningCoursePlayer?.getNextAvailableStep(true).title }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-block btn-next-step"
                    *ngIf="
                      (runningCoursePlayer?.registrationWorkflow.registration.course.format === 101 ||
                        runningCoursePlayer?.registrationWorkflow.registration.course.format === 103) &&
                      runningCoursePlayer?.registrationWorkflow.hasAction('MoveToEvaluationPendingAction')
                    "
                    (click)="runningCoursePlayer?.executeAndLaunchNextStep('MoveToEvaluationPendingAction')"
                    [id]="'course_player_collection_component_' + course.id + '_take_evaluation'">
                    Take {{ runningCoursePlayer?.getNextAvailableStep(true).title }}
                  </button>
                </div>
              </div>
              <div class="flex-row next-step-row" *ngIf="runningCoursePlayer?.hasCompleteAction(course)">
                <div class="flex-col flex-col-grow">
                  <button
                    type="button"
                    class="btn btn-primary btn-block btn-next-step"
                    [id]="'course_player_collection_component_' + course.id + '_complete'"
                    (click)="runningCoursePlayer?.registrationWorkflow.exec('CompleteAction')">
                    Complete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div *ngIf="isArray(chunk) && chunks.length > 1">
      <collection-playlist-structure-layer
        (courseStarted)="startCourse($event)"
        [currentCourse]="currentCourse"
        [items]="chunk"
        [itemTitle]="itemTitle"
        [runningCoursePlayer]="runningCoursePlayer">
      </collection-playlist-structure-layer>
    </div>

    <div *ngIf="!isArray(chunk)">
      <collection-playlist-structure-layer
        (courseStarted)="startCourse($event)"
        [currentCourse]="currentCourse"
        *ngIf="isSection(chunk)"
        [items]="chunk.items"
        [itemTitle]="itemTitle ? itemTitle + '&nbsp;&bull;&nbsp;' + chunk.name : chunk.name"
        [runningCoursePlayer]="runningCoursePlayer">
      </collection-playlist-structure-layer>
    </div>
  </div>
</div>
