<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="cancel()" [id]="trackingName + '_cancel_icon'">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Leave?</h4>
</div>

<div class="modal-body">This content is not yet complete.</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="cancel()" [id]="trackingName + '_cancel_button'">Stay</button>
  <button type="button" class="btn btn-primary" (click)="submit()" [id]="trackingName + '_ok_button'" autoFocus>
    Leave
  </button>
</div>
