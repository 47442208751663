import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ElmsUtils } from 'core/utils';
import { Observable, map } from 'rxjs';
import { IEmailTemplate } from '../models/email.model';

export enum EmailTemplateEndpoints {
  PUT_EMAIL_PREVIEW = '/a/emails/templates/:mailPatternId/sample-notification/',
  PUT_EMAIL_TEMPLATE_PREVIEW = '/a/emails/templates/preview/:mailPatternId',
}

@Injectable()
export class EmailTemplateService {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  getEmailTemplatePreview(
    mailPatternId: string,
    courseId: number,
    variables: Record<string, string>,
  ): Observable<IEmailTemplate> {
    return this.http
      .put<IEmailTemplate>(ElmsUtils.formatUrl(EmailTemplateEndpoints.PUT_EMAIL_TEMPLATE_PREVIEW, { mailPatternId }), {
        courseId,
        variables,
      })
      .pipe(
        map((emailTemplate) => {
          return <IEmailTemplate>{
            subject: this.sanitizer.bypassSecurityTrustHtml(<string>emailTemplate.subject),
            body: this.sanitizer.bypassSecurityTrustHtml(<string>emailTemplate.body),
          };
        }),
      );
  }

  getEmailPreview(mailPatternId: number, variables: Record<string, string>): Observable<IEmailTemplate> {
    return this.http
      .put<IEmailTemplate>(ElmsUtils.formatUrl(EmailTemplateEndpoints.PUT_EMAIL_PREVIEW, { mailPatternId }), {
        variables,
      })
      .pipe(
        map((emailTemplate) => {
          return <IEmailTemplate>{
            subject: this.sanitizer.bypassSecurityTrustHtml(<string>emailTemplate.subject),
            body: this.sanitizer.bypassSecurityTrustHtml(<string>emailTemplate.body),
          };
        }),
      );
  }
}
