import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { RecentlyUpdatedService } from 'modules/course/catalog/services/recently-updated.service';
import { ICourseSearchItem } from 'modules/course/common/models/course-search.model';
import { ISearchQueryParams } from 'modules/search/models/search.models';
import { Unsubscribable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'recently-updated-items',
  templateUrl: './recently-updated-items.component.html',
})
export class RecentlyUpdatedItemsComponent implements OnInit, OnDestroy {
  static readonly selector = 'recentlyUpdatedItems';
  @Input() maxCount = 3;
  @Input() trackingName: string;

  courses: ICourseSearchItem[] = [];

  private _filters: ISearchQueryParams;
  private subscriber?: Unsubscribable;

  constructor(private searchService: RecentlyUpdatedService) {}

  @Input() set filters(filters: ISearchQueryParams) {
    if (!_.isEqual(this._filters, filters)) {
      this._filters = filters;
      this.getRecentlyUpdated();
    }
  }

  get filters() {
    return this._filters;
  }

  ngOnInit(): void {
    this.getRecentlyUpdated();
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }

  private getRecentlyUpdated() {
    const searchQuery = Object.assign({}, this.filters, {
      type: 'course',
      startDoc: 0,
      count: this.maxCount,
      sort: 'last_modified',
    });

    this.subscriber = this.searchService
      .search(searchQuery)
      .pipe(
        finalize(() => {
          this.subscriber.unsubscribe();
          delete this.subscriber;
        }),
      )
      .subscribe((res) => {
        this.courses = res.items;
      });
  }
}
