import { Component, Input, OnInit } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import { ErrorHandlerService } from 'core/services';
import { ISiteSettings } from 'core/site-settings/models/site-settings.model';
import { SiteSettingsService } from 'core/site-settings/services/site-settings.service';
import {
  ISamlRedirect,
  ISamlSsoPromptData,
  ISsoClient,
  ISsoLoginCredentials,
} from 'modules/integrations/models/saml-sso.model';
import { SamlService } from 'modules/integrations/services/saml.service';
import { EMPTY, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'saml-sso-view',
  templateUrl: './sso-view.component.html',
})
export class SamlSsoViewComponent implements OnInit {
  @Input() errorCode: string;

  public status = 'existing_account_incline';
  public stepHistory: string[] = [];
  public formSubmitted = false;
  public credentials: ISsoLoginCredentials = { link: true, login_name: null, password: null };
  public loading = false;
  public processing = false;
  public ssoData: ISamlSsoPromptData;
  public ssoConfig: ISsoClient;
  public error: string;
  public accountExists = undefined;
  public currentSiteSetting: ISiteSettings;

  signInWording = this.globalConfig.signInWording;

  constructor(
    private globalConfig: GlobalConfig,
    private siteSettingsService: SiteSettingsService,
    private samlService: SamlService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  async ngOnInit() {
    await this.loadSiteSetting();

    if (this.errorCode) {
      if (this.errorCode === 'not_found') {
        this.showError(
          'Your company does not appear to have enabled Single Sign-on supported by the Corporate Login, ' +
            'based on the email address you entered. <br>' +
            "Please sign in to the site with your username and password, or through your company's access point.",
        );
      } else {
        this.loadStatus();
      }
    } else {
      this.loadStatus();
    }
  }

  loadStatus(): void {
    this.loading = true;
    this.samlService
      .getPromptStatus(true)
      .pipe(
        catchError((reason) => {
          console.log(reason);
          this.showError('Sign-in data are missing');

          return of(null);
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((ssoData: ISamlSsoPromptData) => {
        if (!ssoData) {
          this.showError(
            'Your company does not appear to have enabled Single Sign-on supported by the Corporate Login, ' +
              'based on the email address you entered. <br>' +
              "Please sign in to the site with your username and password, or through your company's access point.",
          );
        } else {
          this.ssoData = ssoData;

          if (ssoData.errorMessage) {
            this.showError(ssoData.errorMessage);
          } else {
            this.samlService.getSsoConfig().subscribe((configs) => {
              this.ssoConfig = configs.find((config) => config.key === parseInt(ssoData.group_id, 10));

              if (!this.ssoConfig) {
                this.showError('SSO is not properly configured for your group');
              }
            });
          }
        }
      });
  }

  reset(): void {
    this.status = this.stepHistory.pop() || 'existing_account_incline';
  }

  linkAccount(data?: ISsoLoginCredentials): void {
    this.processing = true;
    this.samlService
      .linkAccount(data, true)
      .pipe(
        catchError((reason) => {
          this.showError(reason.error);

          return EMPTY;
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe((result: ISamlRedirect) => {
        if (result && result.targetUrl) {
          window.location.href = result.targetUrl;
        }
      });
  }

  goToSsoStep(): void {
    this.stepHistory.push(this.status);

    if (this.accountExists) {
      this.status = 'existing_account_sso';
    } else {
      this.status = 'new_account_sso';
    }
  }

  showError(error: string): void {
    this.stepHistory.push(this.status);
    this.error = error;
    this.status = 'error';
  }

  private async loadSiteSetting() {
    this.currentSiteSetting = await this.siteSettingsService.getCurrent();
  }
}
