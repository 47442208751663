<div class="ecommerce-confirmation" *ngIf="purchase.statusId === 2">
  <h3 class="xs-margin-bottom no-margin-top">Your order is complete</h3>
  <p>Thank you for your purchase!</p>
  <div class="confirmation-message">
    Order number is: <strong>{{ purchase.id }}</strong>
    <div *ngIf="purchase.user.email && purchase.user.receiveEmails">
      We will send a confirmation email with order details to
      <a title="Your email" [href]="'mailto:' + purchase.user.email">{{ purchase.user.email }}</a>
    </div>
  </div>

  <h3 id="customerHeader">Customer</h3>

  <div class="confirmation-message">
    <div class="d-flex gap-5 align-items-center">
      <div class="hidden-print">
        <user-avatar [user]="purchase.user" [size]="56"></user-avatar>
      </div>
      <div class="">
        <div class="username">{{ purchase.user.firstName }} {{ purchase.user.lastName }}</div>
        <address>
          <span *ngIf="purchase.user.address">{{ purchase.user.address }}</span>
          <span *ngIf="purchase.user.address2">{{ purchase.user.address2 }}</span>
          <span *ngIf="purchase.user.city">{{ purchase.user.city }},</span>
          <span *ngIf="purchase.user.stateName">{{ purchase.user.stateName }}&nbsp;</span>
          <span *ngIf="purchase.user.zip" [innerHTML]="purchase.user.zip"></span>
        </address>
      </div>
    </div>
  </div>

  <div *ngIf="purchase.cart?.length">
    <h3>Order Summary</h3>

    <div *ngFor="let item of purchase.cart">
      <e-commerce-product [product]="item"></e-commerce-product>
    </div>
    <div class="h3 sm-margin-bottom text-right font-normal mb-25">
      Total:
      <span class="text-bolder"
        ><course-price [coursePrice]="{ amount: purchase.amount, currency: purchase.currency }"></course-price
      ></span>
    </div>
  </div>

  <div class="hidden-print">
    <a
      class="btn btn-primary pull-right-md btn-block-xs"
      *ngIf="isStateAvailable('main.learning')"
      uiSref="main.learning">
      View your content
    </a>
    <button type="button" class="btn btn-link pull-right-md btn-block-xs xs-margin-top-phone" (click)="printReceipt()">
      <span class="fa fa-print xs-margin-right" aria-hidden="true"></span>Print order
    </button>
  </div>
</div>

<div *ngIf="purchase.statusId !== 2">
  <div *ngIf="purchase.cart?.length">
    <h3>Purchase Failed</h3>
    <div class="alert alert-danger alert-sm">
      <div *ngIf="purchase.payment">
        {{ purchase.payment.response || 'Payment server is unavailable' }}
      </div>
    </div>
    <div *ngFor="let item of purchase.cart">
      <e-commerce-product [product]="item"></e-commerce-product>
    </div>
    <div class="cart-total h3 sm-margin-bottom">
      Total:
      <span class="cart-total-amount"
        ><course-price [coursePrice]="{ amount: purchase.amount, currency: purchase.currency }"></course-price
      ></span>
    </div>
  </div>
</div>
