<article
  class="tw-flex tw-w-full tw-gap-4 tw-rounded-lg tw-border tw-border-gray-3 tw-bg-gray-2 tw-p-4 tw-text-gray-12"
  *ngIf="!smallView && courseTile">
  <a
    title="{{ courseTile.name }}"
    class="tw-w-80 tw-shrink-0"
    tabindex="-1"
    [trackLinkClick]="trackingName"
    aria-hidden="true"
    uiSref="main.course"
    [uiParams]="{ id: courseTile.id }"
    [uiOptions]="{ reload: true, notify: true }"
    [id]="trackingName + '_course_link'">
    <span class="tw-sr-only">{{ courseTile.name }} thumbnail</span>
    <course-thumbnail [course]="courseTile"></course-thumbnail>
  </a>

  <div class="tw-flex tw-flex-1 tw-flex-col tw-gap-2">
    <h3 class="tw-m-0 tw-line-clamp-3 tw-p-0 tw-text-lg tw-font-medium">
      <a
        href="#"
        title="{{ courseTile.name || courseTile.title }}"
        uiSref="main.course"
        class="tw-focus:outline-hidden tw-rounded tw-text-brand-9 hover:tw-text-brand-10 focus-visible:tw-text-brand-11 focus-visible:tw-outline-brand-7"
        [uiParams]="{ id: courseTile.id, currentBlsId: currentBlsId }"
        [trackLinkClick]="trackingName"
        name="{{ courseTile.id }}"
        [uiOptions]="{ reload: true, notify: true }"
        [id]="trackingName + '_course_link2'"
        [innerHtml]="courseTile.name || courseTile.title"></a>
    </h3>

    <p class="tw-m-0 tw-line-clamp-3 tw-p-0">
      {{ courseTile.description | markdownToHtml | htmlToPlainText }}
    </p>

    <p *ngIf="showOrganizationName || courseTile.createdBy" class="tw-m-0 tw-p-0 tw-text-base tw-text-gray-11">
      by
      {{
        showOrganizationName
          ? courseTile.organization_name
          : courseTile.createdBy?.firstName + ' ' + courseTile.createdBy?.lastName
      }}
    </p>

    <course-expiration-date
      *ngIf="courseTile.expirationDate || courseTile.deactivationDate"
      [course]="courseTile"
      [short]="true"
      [variant]="'secondary'"></course-expiration-date>

    <div class="tw-flex tw-items-center tw-gap-3">
      <!--1st horizontal line-->
      <course-star-rating [course]="courseTile" [singleStar]="true" class=""></course-star-rating>

      <course-info-learners-count [course]="courseTile" *ngIf="courseTile.learners > 0"> </course-info-learners-count>

      <course-length *ngIf="courseTile.length" [course]="courseTile"></course-length>
      <!-- TODO: move to component -->
      <div *ngIf="courseTile.certificates?.length > 0" class="tw-flex tw-items-center tw-gap-1.5">
        <i class="fa-solid fa-award tw-text-gray-11" aria-hidden="true"></i>
        <span>Certificate</span>
      </div>
    </div>

    <div class="tw-flex tw-items-center tw-gap-3">
      <!--2nd horizontal line-->
      <course-info-indicators-board [course]="courseTile" [userCourse]="userCourse"></course-info-indicators-board>

      <course-bookmark
        [trackingName]="trackingName"
        (registrationChanged)="onRegistrationChanged($event)"
        [course]="courseTile"
        [userCourse]="userCourse"
        *ngIf="!hideBookmark"></course-bookmark>
    </div>

    <ng-content select="[format-item-labels]"></ng-content>

    <ng-container [ngTemplateOutlet]="footer"></ng-container>
  </div>

  <!--launch-->
  <div class="tw-flex tw-flex-col tw-gap-3 tw-text-right">
    <div class="tw-text-lg tw-font-bold" *ngIf="coursePrice">
      <course-price [coursePrice]="coursePrice"></course-price>
    </div>
    <div *ngIf="!hideRegManagement" class="hidden-print tw-min-w-24">
      <!--status/checkmark-->
      <course-registration-status
        [course]="courseTile"
        [regData]="courseTile.registration"
        mode="modern"
        *ngIf="completed"
        [showHightStatus]="true"
        [showIcon]="true"></course-registration-status>
      <!--launch/buttons-->
      <fieldset [disabled]="frozen" class="tw-m-0">
        <play-course
          [course]="courseTile"
          [mode]="mode"
          [userCourse]="userCourse"
          *ngIf="!completed"
          [trackingName]="trackingName"
          [actionData]="actionData">
        </play-course>
      </fieldset>
    </div>
  </div>
</article>

<simple-course-tile
  *ngIf="smallView"
  [course]="inputCourse"
  [currentBlsId]="currentBlsId"
  [showMandatory]="showMandatory"
  [actionData]="actionData"
  [trackingName]="trackingName"
  [userCourse]="userCourse"
  [mode]="mode"
  [showRegManagement]="!hideRegManagement"
  [hideBookmark]="hideBookmark">
  <div class="tw-text-gray-11">
    <ng-container [ngTemplateOutlet]="footer"></ng-container>
  </div>
</simple-course-tile>

<ng-template #footer>
  <ng-content></ng-content>
</ng-template>
