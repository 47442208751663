export enum CourseRegistrationStatus {
  pending = 1,
  approved = 2,
  declined = 3,
  inProgress = 4,
  completed = 5,
  withdrawn = 6,
  waitList = 7,
  preAssessmentPending = 8,
  assessmentPending = 9,
  notStarted = 10,
  registrationPending = 17,
  postAssessmentSubmitted = 19,
  evaluationPending = 20,
  passed = 40,
  notPassed = 41,
  expired = 42,
}

export const courseRegistrationStatuses = {
  notStartedSet: [
    CourseRegistrationStatus.notStarted,
    CourseRegistrationStatus.pending,
    CourseRegistrationStatus.approved,
    CourseRegistrationStatus.waitList,
    CourseRegistrationStatus.declined,
    CourseRegistrationStatus.withdrawn,
    CourseRegistrationStatus.registrationPending,
  ],
  inProgressSet: [
    CourseRegistrationStatus.inProgress,
    CourseRegistrationStatus.preAssessmentPending,
    CourseRegistrationStatus.assessmentPending,
    CourseRegistrationStatus.postAssessmentSubmitted,
    CourseRegistrationStatus.evaluationPending,
  ],
  activeSet: [
    CourseRegistrationStatus.notStarted,
    CourseRegistrationStatus.pending,
    CourseRegistrationStatus.approved,
    CourseRegistrationStatus.inProgress,
    CourseRegistrationStatus.registrationPending,
    CourseRegistrationStatus.waitList,
    CourseRegistrationStatus.preAssessmentPending,
    CourseRegistrationStatus.assessmentPending,
    CourseRegistrationStatus.postAssessmentSubmitted,
    CourseRegistrationStatus.evaluationPending,
  ],

  completedSet: [
    CourseRegistrationStatus.completed,
    CourseRegistrationStatus.passed,
    CourseRegistrationStatus.notPassed,
  ],

  strictCompletedSet: [CourseRegistrationStatus.completed, CourseRegistrationStatus.passed],

  incompleteSet: [
    CourseRegistrationStatus.pending,
    CourseRegistrationStatus.registrationPending,
    CourseRegistrationStatus.approved,
    CourseRegistrationStatus.inProgress,
    CourseRegistrationStatus.waitList,
    CourseRegistrationStatus.preAssessmentPending,
    CourseRegistrationStatus.assessmentPending,
    CourseRegistrationStatus.notStarted,
    CourseRegistrationStatus.postAssessmentSubmitted,
    CourseRegistrationStatus.evaluationPending,
    CourseRegistrationStatus.notPassed,
  ],

  declinedSet: [CourseRegistrationStatus.declined, CourseRegistrationStatus.withdrawn],

  withdrawnSet: [
    CourseRegistrationStatus.declined,
    CourseRegistrationStatus.withdrawn,
    CourseRegistrationStatus.expired,
  ],

  terminalSet: [
    CourseRegistrationStatus.completed,
    CourseRegistrationStatus.passed,
    CourseRegistrationStatus.notPassed,
    CourseRegistrationStatus.expired,
    CourseRegistrationStatus.withdrawn,
  ],
};

export interface ICourseRegistrationStatus {
  id: number;
  order: number;
}
