import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { CACHE_NAME_TOKEN, CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { ElmsUtils } from 'core/utils';
import { IGroup } from 'modules/group/models/group.model';
import {
  ISamlAvailable,
  ISamlRedirect,
  ISamlSsoPromptData,
  ISsoClient,
  ISsoConfig,
  ISsoLoginCredentials,
} from 'modules/integrations/models/saml-sso.model';
import { Observable, map } from 'rxjs';

enum SamlSsoPaths {
  ClientsRequest = '/a/integration/saml_sso/config/',
  GroupsRequest = '/a/integration/saml_sso/groups/',
  PromptRequest = '/a/integration/saml_sso/prompt_status/',
  LinkAccountRequest = '/a/integration/saml_sso/link_account/',
  GroupAvailabilityRequest = '/a/integration/saml_sso/:groupId/availability/',
}

@Injectable()
export class SamlService {
  constructor(private http: HttpClient) {}

  checkGroupAvailability(groupId: string): Observable<ISamlAvailable> {
    return this.http.get<ISamlAvailable>(
      ElmsUtils.formatUrl(SamlSsoPaths.GroupAvailabilityRequest, { groupId: groupId }),
      {
        context: new HttpContext().set(CACHE_NAME_TOKEN, 'samlSsoCache').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
      },
    );
  }

  getIntegrationGroups(): Observable<IGroup[]> {
    return this.http.get<IGroup[]>(SamlSsoPaths.GroupsRequest, {
      context: new HttpContext().set(CACHE_NAME_TOKEN, 'samlSsoCache').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }

  public getSsoConfig(): Observable<ISsoClient[]> {
    return this.http
      .get<ISsoConfig>(SamlSsoPaths.ClientsRequest, {
        context: new HttpContext().set(CACHE_NAME_TOKEN, 'samlSsoCache').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
      })
      .pipe(map((response: ISsoConfig) => response.group_map));
  }

  getPromptStatus(throwHttpErrors = false): Observable<ISamlSsoPromptData> {
    return this.http.get<ISamlSsoPromptData>(SamlSsoPaths.PromptRequest, {
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  linkAccount(data: ISsoLoginCredentials, throwHttpErrors = false): Observable<ISamlRedirect> {
    return this.http.post<ISamlRedirect>(SamlSsoPaths.LinkAccountRequest, data, {
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }
}
