import { FactoryProvider } from '@angular/core';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { ICollectionItemBase } from 'modules/course/common/models/learning-object-structure.model';

export abstract class TrainingService {
  abstract viewTranscript(courseId: number, newWindow?: boolean): void;

  abstract buildExternalLaunchPath(courseId: number, registrationId: number, type: string): string;

  abstract editRegistration(courseId: number): void;

  abstract manageCustomFields(workflow: any): void;

  abstract showApprovalStatus(registration: any): void;

  abstract launchContent(registration: any, params: any): void;

  abstract canShowContent(registration: ILearningObjectRegistration): boolean;

  abstract getStatusId(registration: any): number;

  abstract mergeSessionsData(courseSessions: any, registration: any): void;

  abstract findNextCollectionComponent(
    structureItems: ICollectionItemBase[],
    includeOptional: boolean,
    excludeNonRunnable?: boolean,
  ): ICollectionItemBase;

  abstract findFirstCollectionComponent(structureItems: any): any;

  abstract hasCompletedCourses(items: any): boolean;

  abstract findNextSession(sessions: any): any;

  abstract pollCoursePlayerWindow(wnd: any): any;

  abstract isInternalContentPlayer(course: any): boolean;
}

export function trainingServiceFactory(injector: angular.auto.IInjectorService): TrainingService {
  return injector.get<TrainingService>('trainingService');
}

export const trainingServiceProvider: FactoryProvider = {
  provide: TrainingService,
  useFactory: trainingServiceFactory,
  deps: ['$injector'],
};
