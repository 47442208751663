import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import { CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import _ from 'lodash';
import {
  CourseRegistrationStatus,
  ICourseRegistrationStatus,
  courseRegistrationStatuses,
} from 'modules/course-registrations/models/course-registration-status.model';
import { Observable, map, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CourseRegistrationStatusService {
  allItems: ICourseRegistrationStatus[] = [];
  statusNamesOverride = _.get(this.globalConfig, 'courseRegistration.statusNamesOverride') || {};
  titles: { [k: number]: string } = {};
  names: { [k: number]: string } = {};

  constructor(
    private http: HttpClient,
    private globalConfig: GlobalConfig,
  ) {
    this.populateStatuses().subscribe();
  }

  populateStatuses(): Observable<null> {
    if (this.allItems.length) {
      return of(null);
    }

    return this.http
      .get('/a/course_registrations/dictionaries/', {
        context: new HttpContext().set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
      })
      .pipe(
        tap((response: any) => {
          response.statuses.forEach((status: any) => {
            if (
              !(
                this.globalConfig.courseRegistration &&
                this.globalConfig.courseRegistration.excludedStatuses &&
                _.includes(this.globalConfig.courseRegistration.excludedStatuses, status.id)
              )
            ) {
              this.allItems.push(status);
            }
          });

          this.fillStatusNames();
        }),
      );
  }

  fillStatusNames() {
    const statusesDict = _.zipObject(_.map(this.allItems, 'id'), _.map(this.allItems, 'name'));

    Object.assign(this.names, statusesDict);
  }

  getTitle(statusId: CourseRegistrationStatus): Observable<string> {
    return this.populateStatuses().pipe(
      map(() => {
        return _.get(this.titles, statusId, this.names[statusId]);
      }),
    );
  }

  getStatusName(statusId: CourseRegistrationStatus, defaultId?: CourseRegistrationStatus): Observable<string> {
    return this.populateStatuses().pipe(
      map(() => {
        return _.get(this.names, statusId) || (defaultId && _.get(this.names, defaultId));
      }),
    );
  }

  getStatusIdByUserCourse(node: any, highestResult: boolean, recordDataSource: string): CourseRegistrationStatus {
    const dataSource = this.getDataSource(node, highestResult, recordDataSource);

    return dataSource && dataSource.statusId ? dataSource.statusId : CourseRegistrationStatus.notStarted;
  }

  getDataSource(node: any, highestResult: boolean, recordDataSource: string) {
    const item = recordDataSource && _.get(node, recordDataSource);
    const last = _.get(node, 'lastRegistration');

    if (last && last.statusId === CourseRegistrationStatus.expired) {
      return last;
    } else if (item) {
      return item;
    } else if (highestResult) {
      return node;
    } else if (last) {
      return last;
    }

    return null;
  }

  orderByStatusSet(status: ICourseRegistrationStatus) {
    if (CourseRegistrationStatus.expired === status.id) {
      return 900 + status.order;
    } else {
      if (_.includes(courseRegistrationStatuses.withdrawnSet, status.id)) {
        return status.order;
      } else {
        if (_.includes(courseRegistrationStatuses.notStartedSet, status.id)) {
          return 200 + status.order;
        } else {
          if (_.includes(courseRegistrationStatuses.inProgressSet, status.id)) {
            return 400 + status.order;
          } else {
            return 800 + status.order;
          }
        }
      }
    }
  }
}
