<fieldset class="standard-margin-bottom">
  <legend class="no-margin-bottom">
    <div class="row">
      <div class="col-xs-6 col-sm-9">
        <span [ngSwitch]="type">
          <span *ngSwitchCase="'pre'">
            <span *ngIf="quizzes.preAssessment.title && quizOptionsEnum?.showTitle">
              {{ quizzes.preAssessment.title }}
            </span>
            <span *ngIf="!quizzes.preAssessment.title || !quizOptionsEnum?.showTitle">
              {{ courseQuizTypes?.preAssessment.name }}
            </span>
          </span>
          <span *ngSwitchCase="'post'">
            <span *ngIf="quizzes.postAssessment.title && quizOptionsEnum?.showTitle">
              {{ quizzes.postAssessment.title }}
            </span>
            <span *ngIf="!quizzes.postAssessment.title || !quizOptionsEnum?.showTitle">
              {{ courseQuizTypes?.assessment.name }}
            </span>
          </span>
          <span *ngSwitchCase="'preEvaluation'">
            <span *ngIf="quizzes.preEvaluation.title && quizOptionsEnum?.showTitle">
              {{ quizzes.preEvaluation.title }}
            </span>
            <span *ngIf="!quizzes.preEvaluation.title || !quizOptionsEnum?.showTitle">
              {{ courseQuizTypes?.preEvaluation.categoryName }}
            </span>
          </span>
          <span *ngSwitchCase="'eval'">
            <span *ngIf="quizzes.evaluation.title && quizOptionsEnum?.showTitle">
              {{ quizzes.evaluation.title }}
            </span>
            <span *ngIf="!quizzes.evaluation.title || !quizOptionsEnum?.showTitle">
              {{ courseQuizTypes?.evaluation.categoryName }}
            </span>
          </span>
          <span *ngSwitchCase="'followUpEvaluation'">
            <span *ngIf="quizzes.followUpEvaluation.title && quizOptionsEnum?.showTitle">
              {{ quizzes.followUpEvaluation.title }}
            </span>
            <span *ngIf="!quizzes.followUpEvaluation.title || !quizOptionsEnum?.showTitle">
              {{ courseQuizTypes?.followupEvaluation.categoryName }}
            </span>
          </span>
        </span>
      </div>
      <div class="col-xs-6 col-sm-3 text-right xs-margin-bottom">
        <ng-content select="actionsSlot"></ng-content>
      </div>
    </div>
  </legend>
</fieldset>
<div class="quiz-player" [ngSwitch]="type">
  <div *ngSwitchCase="'pre'">
    <quiz-player-view
      introText="Click the 'Start' button when you are ready to take
                      the {{ courseQuizTypes?.preAssessment.name }}."
      lostConnectionText="The internet connection was lost.
      Please close the browser tab, check your internet connection, and relaunch the {{
        courseQuizTypes?.preAssessment.name | lowercase
      }}. Your progress has been saved up to this point."
      [resultId]="workflow.registration.preAssessment.resultId"
      [quizFinish]="quizFinish"
      [quizRetake]="quizRetake"
      [quizSkip]="quizSkipSubject"
      [playerClose]="playerClose"
      (finishHandler)="quizFinished($event)">
      <div finishText>Thank you for taking the {{ courseQuizTypes?.preAssessment.name | lowercase }}.</div>
      <div timeEndsText>
        Your time is out. The {{ courseQuizTypes?.preAssessment.name | lowercase }} result will be calculated based on
        the answers you have entered until this point.
      </div>
      <span finishButtons class="text-center-xs display-block-xs">
        <button
          class="btn btn-default btn-lg xs-margin-bottom-phone xs-margin-bottom-phone"
          *ngIf="workflow.hasAction('FinishPreAssessment') && workflow.registration.status_id === 8"
          (click)="handleQuizResult()"
          type="button"
          title="Proceed To Course">
          Proceed To Course
        </button>
      </span>
      <span retakeButtons class="text-center-xs display-block-xs">
        <button
          class="btn btn-default btn-lg xs-margin-bottom-phone xs-margin-bottom-phone"
          *ngIf="workflow.hasAction('LaunchPreAssessmentAction')"
          (click)="handleRetake(type)"
          type="button"
          title="Re-take Assessment">
          Re-take Assessment
        </button>
      </span>
    </quiz-player-view>
  </div>
  <div *ngSwitchCase="'post'">
    <quiz-player-view
      introText="Click the 'Start' button when you are ready to take the {{
        courseQuizTypes?.assessment.name | lowercase
      }}."
      lostConnectionText="The internet connection was lost.
      Please close the browser tab, check your internet connection, and relaunch the {{
        courseQuizTypes?.assessment.name | lowercase
      }}. Your progress has been saved up to this point."
      [resultId]="workflow.registration.postAssessment.resultId"
      [quizFinish]="quizFinish"
      [quizRetake]="quizRetake"
      [quizSkip]="quizSkipSubject"
      [playerClose]="playerClose"
      (finishHandler)="quizFinished()">
      <div finishText>Thank you for taking the {{ courseQuizTypes?.assessment.name | lowercase }}.</div>
      <div timeEndsText>
        Your time is out. The {{ courseQuizTypes?.assessment.name | lowercase }} result will be calculated based on the
        answers you have entered until this point.
      </div>
      <span retakeButtons class="text-center-xs display-block-xs">
        <button
          class="btn btn-default btn-lg xs-margin-bottom-phone xs-margin-bottom-phone"
          *ngIf="workflow.hasAction('LaunchPostAssessmentAction')"
          (click)="handleRetake(type)"
          type="button"
          title="Review">
          Re-take Assessment
        </button>
      </span>
      <span launchButtons class="standard-margin-left text-center-xs display-block-xs">
        <button
          class="btn btn-default xs-margin-bottom-phone xs-margin-bottom-phone"
          *ngIf="workflow.hasAction('Launch') && workflow.registration.postAssessment.openBook"
          (click)="workflow.exec('Launch', { newWindow: true, openBook: true })"
          type="button"
          title="Open Book">
          Open Book
        </button>
        <button
          class="btn btn-default xs-margin-bottom-phone xs-margin-bottom-phone"
          *ngIf="workflow.hasAction('LaunchBrowseContentAction') && workflow.registration.postAssessment.openBook"
          (click)="workflow.exec('LaunchBrowseContentAction', { newWindow: true, openBook: true })"
          type="button"
          title="Open Book">
          Open Book
        </button>
        <button
          class="btn btn-default xs-margin-bottom-phone xs-margin-bottom-phone"
          *ngIf="workflow.hasAction('DownloadAction') && workflow.registration.postAssessment.openBook"
          (click)="workflow.exec('DownloadAction', { newWindow: true, openBook: true })"
          type="button"
          title="Download">
          Download
        </button>
      </span>
    </quiz-player-view>
  </div>
  <div *ngSwitchCase="'preEvaluation'">
    <quiz-player-view
      introText="Click the 'Start' button when you are ready to take the {{
        courseQuizTypes?.preEvaluation.categoryName
      }}."
      lostConnectionText="The internet connection was lost.
      Please close the browser tab, check your internet connection, and relaunch the {{
        courseQuizTypes?.preEvaluation.categoryName | lowercase
      }}. Your progress has been saved up to this point."
      [resultId]="workflow.registration.preEvaluation.resultId"
      [quizFinish]="quizFinish"
      [quizRetake]="quizRetake"
      [quizSkip]="quizSkipSubject"
      [playerClose]="playerClose"
      (finishHandler)="quizFinished()">
      <div finishText>Thank you for completing the Evaluation!</div>
      <span finishButtons class="text-center-xs display-block-xs">
        <button
          class="btn btn-default btn-lg xs-margin-bottom-phone xs-margin-bottom-phone"
          *ngIf="workflow.hasAction('FinishPreEvaluation') && !workflow.registration.preEvaluationCompleted"
          (click)="handleQuizResult()"
          type="button"
          title="Proceed To Course">
          Proceed To Course
        </button>
      </span>
    </quiz-player-view>
  </div>
  <div *ngSwitchCase="'eval'">
    <quiz-player-view
      introText="To help us ensure the quality of this content,
                      please take a few minutes to provide your feedback."
      lostConnectionText="The internet connection was lost.
      Please close the browser tab, check your internet connection, and relaunch the {{
        courseQuizTypes?.evaluation.categoryName | lowercase
      }}. Your progress has been saved up to this point."
      [resultId]="workflow.registration.evaluation.resultId"
      [quizFinish]="quizFinish"
      [quizSkip]="quizSkipSubject"
      [quizRetake]="quizRetake"
      [playerClose]="playerClose"
      (finishHandler)="quizFinished()">
      <div finishText>Thank you for completing the Evaluation!</div>
      <div skipText>You have skipped the {{ courseQuizTypes?.evaluation.categoryName | lowercase }}.</div>
      <span startButtons class="text-center display-block sm-margin-top">
        <a
          class="xs-margin-bottom-phone xs-margin-bottom-phone use-hand-cursor"
          *ngIf="workflow.hasAction('SkipEvaluation') && !workflow.registration.evaluation.mandatory"
          clickConfirmationTitle="Confirm"
          (clickConfirmation)="
            execute('SkipEvaluation', { sendCloseEvent: true, quiSkipEvent: true, quizFinishStep: true })
          "
          clickConfirmationMessage="Are you sure you want to skip the evaluation and mark registration as completed?"
          clickConfirmationButtonOk="Ok">
          Skip and mark completed
        </a>
      </span>
    </quiz-player-view>
  </div>
  <div *ngSwitchCase="'followUpEvaluation'">
    <quiz-player-view
      introText="Click the 'Start' button when you are ready to take the {{
        courseQuizTypes?.followupEvaluation.categoryName
      }}."
      lostConnectionText="The internet connection was lost.
       Please close the browser tab, check your internet connection, and relaunch the {{
        courseQuizTypes?.followupEvaluation.categoryName | lowercase
      }}. Your progress has been saved up to this point."
      [resultId]="workflow.registration.followUpEvaluation.resultId"
      [quizFinish]="quizFinish"
      [quizRetake]="quizRetake"
      [quizSkip]="quizSkipSubject"
      [playerClose]="playerClose"
      (finishHandler)="quizFinished()">
      <div finishText>Thank you for completing the Evaluation!</div>
    </quiz-player-view>
  </div>
</div>
