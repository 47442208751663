import { FactoryProvider, InjectionToken } from '@angular/core';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { Subject } from 'rxjs';

export interface IAvailableLaunchType {
  actionName: string;
  type: string;
  playCourseOrder: number;
  courseContent: string;
}

export interface IWorkflowEvent {
  event: string;
  payload?: any;
}

export abstract class LearningObjectRegistrationWorkflowService {
  registration: ILearningObjectRegistration;
  processingAction: string;
  workflowEvents: Subject<IWorkflowEvent>;

  abstract cancelAction(): void;

  abstract nextState(): void;

  abstract finishAction(): void;

  abstract openContentWindow(action): void;

  abstract exec(actionName, data?, options?): Promise<void>;

  abstract hasAction(action): boolean;

  abstract editRegistration();

  abstract getAvailableCourseLaunchTypes(): IAvailableLaunchType[];

  abstract getAvailableLaunchTypes();

  abstract getDefaultLaunchActions();

  abstract getPostAssessmentLaunchActionName(actions);

  abstract getPlayCourseActions();

  abstract hasPlayCourseActionsForRegistration(): boolean;

  abstract getCheckStateNotification(): any;

  abstract showCheckStateNotification(): void;

  abstract launchContent(type: IAvailableLaunchType | string, newWindow?: boolean): Promise<void> | void;
}
export const LearningObjectRegistrationWorkflowFactory =
  new InjectionToken<LearningObjectRegistrationWorkflowServiceFactory>('RegistrationWorkflowFactory');

export type LearningObjectRegistrationWorkflowServiceFactory = (
  r: any,
  v?: any,
) => LearningObjectRegistrationWorkflowService;

function factory(injector: angular.auto.IInjectorService): LearningObjectRegistrationWorkflowServiceFactory {
  const Service = injector.get<(r: any, v?: any) => void>('LearningObjectRegistrationWorkflow');

  return (registration, attrs) => {
    return new Service(registration, attrs) as LearningObjectRegistrationWorkflowService;
  };
}

export const learningObjectRegistrationWorkflowServiceProvider: FactoryProvider = {
  provide: LearningObjectRegistrationWorkflowFactory,
  useFactory: factory,
  deps: ['$injector'],
};
