import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AccreditationAgencyInfoComponent } from 'modules/credits/accreditation-agency-info.component';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [AccreditationAgencyInfoComponent],
  providers: [],
})
export class CreditsModule {}
