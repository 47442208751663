import { FactoryProvider } from '@angular/core';
import { ICollectionItemBase } from 'modules/course/common/models/learning-object-structure.model';
import { ICourseAbstract } from '../common/models/course.model';

export interface LearningObjectStructure {
  has_non_registered_components: boolean;
  structure: ICollectionItemBase[];
}

export interface IFlattenCourse extends ICollectionItemBase, Omit<ICourseAbstract, 'id'> {
  number: number;
  status_id?: number;
}

export abstract class CourseComponentsService {
  abstract initSessions(courseId: number): Promise<any>;

  abstract getCompilationStructure(
    courseId: number | string,
    userId: number,
    params?: any,
  ): Promise<LearningObjectStructure>;

  abstract isCourseNotCompleted(course: any): boolean;

  abstract getFlattenCourseList(structure: any, index?: number): IFlattenCourse[];

  abstract isCourseNotInTerminalPrerequisiteStatus(course: any): boolean;

  abstract getCollectionComponentsRegistrations(collectionCourseId: number, userId: string): Promise<any>;

  abstract getComponentsCredits(courseId: number): Promise<any>;

  abstract getComponentsCertificates(courseId: number): Promise<any>;

  abstract getComponentsTrainingOptions(courseId: number): Promise<any>;
}

export function courseComponentsServiceFactory(injector: angular.auto.IInjectorService): CourseComponentsService {
  return injector.get<CourseComponentsService>('courseComponentsService');
}

export const courseComponentsServiceProvider: FactoryProvider = {
  provide: CourseComponentsService,
  useFactory: courseComponentsServiceFactory,
  deps: ['$injector'],
};
