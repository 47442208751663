import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { GlobalConfig } from 'core/environment';
import { CACHE_NAME_TOKEN, CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { MetaService } from 'core/services/meta.service.ajs-upgraded-provider';
import { ElmsUtils } from 'core/utils';
import _ from 'lodash';
import { ICourseSectionApi } from 'modules/admin/course/edit/services/course-edit-wizard.ajs-upgraded-provider';
import {
  ICourse,
  ICourseAbstract,
  ICourseDetails,
  ICourseTileData,
  ICourseTrainingOptions,
} from 'modules/course/common/models/course.model';
import { ICourseOptions } from 'modules/course/common/models/play-course.model';
import moment from 'moment';
import { Observable } from 'rxjs';

enum CourseServiceEndpoints {
  GET = '/a/course/:courseId/',
  OPTIONS = '/a/course/options/',
  TRAINING_OPTIONS = '/a/course/:courseId/training_options/',
  COURSE_TILE_DATA = '/a/course/:courseId/course_tile_data/',
  AVAILABILITY = '/a/course/availability/',
  PREREQUISITE_FOR = '/a/course/:courseId/prerequisite_for/',
  TRAINING_CATEGORIES = '/a/course/:courseId/training-categories/',
  UPDATE_SECTION = '/a/course/:courseId/update/:api/',
}

export type CourseQueryParams = {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
};

@Injectable()
export class CourseService {
  constructor(
    private http: HttpClient,
    private window: Window,
    private globalConfig: GlobalConfig,
    private metaService: MetaService,
    private stateService: StateService,
  ) {}

  public get(courseId: number | string, query?: CourseQueryParams): Observable<ICourseDetails> {
    const params = new HttpParams({ fromObject: query });

    return this.http.get<ICourseDetails>(ElmsUtils.formatUrl(CourseServiceEndpoints.GET, { courseId }), { params });
  }

  public getCourse(courseId: number | string, query?: CourseQueryParams): Observable<ICourse> {
    const params = new HttpParams({ fromObject: query });

    return this.http.get<ICourse>(ElmsUtils.formatUrl(CourseServiceEndpoints.GET, { courseId }), { params });
  }

  getCourseOptions(): Observable<ICourseOptions> {
    return this.http.get<ICourseOptions>(CourseServiceEndpoints.OPTIONS, {
      context: new HttpContext().set(CACHE_NAME_TOKEN, 'courseOptionsCache').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }

  getCourseTrainingOptions(courseId: number | string, disableCache?: boolean): Observable<ICourseTrainingOptions> {
    const context = new HttpContext();

    if (!disableCache) {
      context.set(CACHE_NAME_TOKEN, 'courseCache');
      context.set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE);
    }

    // Exists duplicate in roster training service - courseRegistrationManagerService
    return this.http.get<ICourseTrainingOptions>(
      ElmsUtils.formatUrl(CourseServiceEndpoints.TRAINING_OPTIONS, { courseId }),
      { context },
    );
  }

  getCourseTileData(courseId): Observable<ICourseTileData> {
    // Exists duplicate in roster training service - courseRegistrationManagerService
    return this.http.get<ICourseTileData>(ElmsUtils.formatUrl(CourseServiceEndpoints.COURSE_TILE_DATA, { courseId }), {
      context: new HttpContext().set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }

  getCourseAvailability() {
    return this.http.get(CourseServiceEndpoints.AVAILABILITY, {
      context: new HttpContext().set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }

  getPrerequisiteForCourses(course) {
    return this.http.get(ElmsUtils.formatUrl(CourseServiceEndpoints.PREREQUISITE_FOR, { courseId: course.id }));
  }

  copyCourseLink(/*courseId, title*/) {
    throw new Error('Not implemented');

    // $uibModal.open({
    //   component: 'copyLinkDialog',
    //   keyboard: false,
    //   resolve: {
    //     title: function () {
    //       return title || 'Copy course link';
    //     },
    //     link: function () {
    //       return [$window.location.origin, '/gateway/course/', courseId, '/view'].join('');
    //     }
    //   }
    // });
  }

  exportCourseEditHistory(courseId, options) {
    const fileName =
      (options && options.fileName) ||
      ['course_edit_history', courseId, moment(new Date()).format('YYYY-MM-DD')].join('_');
    const format = (options && options.format) || 'csv';

    let params = new HttpParams();

    params = params.append('format', format);
    params = params.append('file_name', fileName);

    if (options) {
      params = params.append('query', JSON.stringify(options.data));
    }

    this.window.location.assign(['/a/course/', courseId, '/history/?', params.toString()].join(''));
  }

  setMetaInfo(course) {
    let ogpImage = '',
      title = course.name,
      description = course.description;

    if (
      this.globalConfig.settings.courseDetails &&
      this.globalConfig.settings.courseDetails.skillLevelCourseFormatMeta
    ) {
      title = [course.name, course.skillLevelName].join(' ');
      description = [
        course.name,
        ' is a ',
        course.skillLevelName,
        ' ',
        course.formatName,
        ' formatted class focusing on ',
        _.map(course.subjectAreas, 'name').join(', '),
        course.length ? ['. Approximately ', course.length, ' hrs will be needed to complete'].join('') : '',
      ].join('');
    }

    if (course.previewImage && course.previewImage.id && course.previewImage.rnd) {
      ogpImage = ['/a/media/file/', course.previewImage.id, '/?rnd=', course.previewImage.rnd].join('');
    }

    this.metaService.setMeta({
      title: title,
      description: description,
      url: this.stateService.href('main.course', { id: course.id }),
      image: ogpImage,
      type: 'website',
    });
  }

  getCourseTrainingCategories(courseId) {
    return this.http.get(ElmsUtils.formatUrl(CourseServiceEndpoints.TRAINING_CATEGORIES, { courseId }), {
      context: new HttpContext().set(CACHE_NAME_TOKEN, 'courseCache').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }

  updateSection(course: ICourseAbstract, courseSectionApi: ICourseSectionApi) {
    if (courseSectionApi.fields?.includes('jobFiles')) {
      course['jobFiles'] = course['files'];
    }

    const dataToUpdate = courseSectionApi.fields ? _.pick(course, ['id'].concat(courseSectionApi.fields)) : course;

    return this.http.put<ICourseDetails>(
      ElmsUtils.formatUrl(CourseServiceEndpoints.UPDATE_SECTION, { courseId: course.id, api: courseSectionApi.api }),
      dataToUpdate,
    );
  }

  checkPermissions(course: ICourse, permission: string) {
    return !!course.permissions.find((p) => p.toLowerCase() === permission.toLowerCase());
  }
}
