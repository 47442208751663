<div class="login-container">
  <div class="center-block">
    <div class="panel panel-default standard-margin-top">
      <div class="panel-body">
        <div class="brand-logo">
          <div class="brand-logo-image" aria-hidden="false"></div>
        </div>
        <div>
          <form (ngSubmit)="linkAccount()" #loginForm="ngForm" novalidate>
            <div
              class="form-group"
              [ngClass]="{ 'has-error': (username?.dirty || formSubmitted) && username?.invalid }">
              <label for="loginField" class="control-label">
                {{ currentSiteSetting?.siteShortName || 'LMS' }} {{ signInWording.username }}</label
              >
              <input
                type="text"
                id="loginField"
                placeholder="{{ (currentSiteSetting?.siteShortName || 'LMS') + ' account ' + signInWording.username }}"
                class="form-control"
                [(ngModel)]="credentials.login_name"
                name="username"
                #username="ngModel"
                required
                autocomplete="off" />
              <div class="margin-top text-danger" *ngIf="(username.dirty || formSubmitted) && username.invalid">
                <span>Please enter your {{ signInWording.username }}</span>
              </div>
            </div>

            <div
              class="form-group"
              [ngClass]="{ 'has-error': (password?.dirty || formSubmitted) && username.valid && password?.invalid }">
              <label for="passwordField" class="control-label"
                >{{ currentSiteSetting?.siteShortName || 'LMS' }} password</label
              >
              <input
                type="password"
                id="passwordField"
                placeholder="{{ (currentSiteSetting?.siteShortName || 'LMS') + ' account password' }}"
                class="form-control"
                [(ngModel)]="credentials.password"
                name="password"
                #password="ngModel"
                required
                autocomplete="off" />
              <div
                class="margin-top text-danger"
                *ngIf="(password.dirty || formSubmitted) && username.valid && password.invalid">
                <span>Please enter your password</span>
              </div>
            </div>

            <div class="form-group">
              <button
                type="submit"
                class="btn btn-lg btn-primary btn-block"
                [disabled]="loading || loginForm.invalid || !linkAccountData"
                id="loginBtn"
                title="Link Account">
                Link Account
              </button>
            </div>

            <div *ngIf="linkAccountData?.allowCreateUser" class="form-group">
              <button
                type="button"
                class="btn btn-lg btn-default btn-block"
                (click)="createAccount()"
                id="createAccountBtn"
                title="Create an Account"
                [disabled]="loading || !linkAccountData">
                Create an Account
              </button>
            </div>
            <div *ngIf="error" class="alert alert-sm alert-danger">
              {{ error }}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
