import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CourseRegistrationQuestionService } from 'modules/course-registrations/services/course-registration-question.service';
import { CourseRegistrationService } from 'modules/course-registrations/services/course-registration.service';
import { trainingServiceProvider } from 'modules/course-registrations/services/training.service.ajs-upgraded-provider';
import { UserCourseService } from 'modules/course-registrations/services/user-course.service';
import { CourseRegistrationStatusComponent } from './components/course-registration-status.component';
import { PlayCourseDirective } from './components/play-course.component.upgraded';
import { RegistrationCreditTypeSelectionDirective } from './components/registration-credit-type-selection.component.upgraded';
import { RegistrationCreditDirective } from './components/registration-credit.component.upgraded';
import { RegistrationMenuDirective } from './components/registration-menu.component.upgraded';
import { VerificationIndicatorDirective } from './components/verification-indicator.component.upgraded';
import { CourseRegistrationActionService } from './services/cource-registration-action.service';
import { CourseRegistrationStatusService } from './services/course-registration-status.service';
import { learningObjectRegistrationWorkflowServiceProvider } from './services/learning-object-registration-workflow.service.ajs-upgraded-provider';
import { learningObjectRegistrationServiceProvider } from './services/learning-object-registration.service.ajs-upgraded-provider';

@NgModule({
  imports: [CommonModule, LmsComponentsModule],
  providers: [
    learningObjectRegistrationServiceProvider,
    learningObjectRegistrationWorkflowServiceProvider,
    CourseRegistrationStatusService,
    CourseRegistrationActionService,
    CourseRegistrationQuestionService,
    trainingServiceProvider,
    CourseRegistrationService,
    UserCourseService,
  ],
  declarations: [
    PlayCourseDirective,
    CourseRegistrationStatusComponent,
    RegistrationMenuDirective,
    VerificationIndicatorDirective,
    RegistrationCreditDirective,
    RegistrationCreditTypeSelectionDirective,
  ],
  exports: [
    PlayCourseDirective,
    CourseRegistrationStatusComponent,
    RegistrationMenuDirective,
    VerificationIndicatorDirective,
    RegistrationCreditDirective,
    RegistrationCreditTypeSelectionDirective,
  ],
})
export class CourseRegistrationCommonModule {}
