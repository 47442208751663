<!--<div class="modal-header">-->
<!--  <button type="button" class="close" aria-label="Close" (click)="cancel()">-->
<!--    <span aria-hidden="true">&times;</span>-->
<!--  </button>-->
<!--  <h4 class="modal-title">Question Version</h4>-->
<!--</div>-->

<div class="modal-body">
  <p>The {{ player.getNextAvailableStep(true).title }} is now available.</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="goBack()" autoFocus>Close Player</button>
  <button type="button" class="btn btn-primary" (click)="goNextStep()">
    Take {{ player.getNextAvailableStep(true).title }}
  </button>
</div>
