<div class="f-row" *ngIf="!anonymous && courses.length">
  <div class="f-col-xs-12" *ngIf="!noHeader">
    <h1 class="text-center text-uppercase hidden-xs recommended-course-header">{{ header || 'Recommendations' }}</h1>
    <h3 class="text-center text-uppercase visible-xs recommended-course-header-xs">
      {{ header || 'Recommendations' }}
    </h3>
  </div>
</div>

<div
  class="f-row tiles-container"
  [ngClass]="{ 'scrollable-horizontal': !(tilesView === 'horizontal') }"
  *ngIf="!anonymous && courses.length && tilesView !== 'horizontal'">
  <div class="f-col-xs-12 f-col-sm-4" *ngFor="let course of courses">
    <simple-course-tile
      class="simple-course-tile-item"
      [course]="course"
      [showMandatory]="true"
      [trackingName]="trackingName"></simple-course-tile>
  </div>
</div>

<div
  class="tw-grid tw-auto-cols-fr tw-grid-flow-col tw-gap-4"
  [ngClass]="{ 'scrollable-horizontal': !(tilesView === 'horizontal') }"
  *ngIf="!anonymous && courses.length && tilesView === 'horizontal'">
  <horizontal-course-tile-modern
    *ngFor="let course of courses"
    [hideRegManagement]="true"
    [smallView]="smallView"
    [ngClass]="{ preview: courses.length === 1 }"
    [course]="course"
    [showMandatory]="true"
    [trackingName]="trackingName">
  </horizontal-course-tile-modern>
</div>

<div *ngIf="!courses.length" class="alert alert-info">There are no recommended courses for you.</div>
