<div class="row">
  <div class="col-sm-12">
    <article *ngFor="let post of service.recentBlogPosts | async; last as isLastItem">
      <div class="d-flex gap-10 mb-20">
        <div class="">
          <div class="" *ngIf="post.showAuthorInfo && post.createdBy">
            <user-avatar [user]="post.createdBy" [size]="56"></user-avatar>
          </div>
        </div>
        <div class="">
          <h4 class="mt-0 mb-5 line-clamp-2" *ngIf="post.showTitle">
            <a href="" uiSref="main.blog" [uiParams]="{ id: post.id }">{{ post.title }}</a>
          </h4>
          <div class="small" *ngIf="post.showAuthorInfo">
            <p>{{ post.createdBy.firstName }} {{ post.createdBy.lastName }}</p>
            <time>{{ post.updatedDate | date: 'mediumDate' }}</time>
          </div>
        </div>
      </div>
      <section class="xs-margin-top">
        <p [innerHTML]="post.getContent()"></p>
      </section>
      <div *ngIf="!isLastItem" class="dotted-divider" role="separator" aria-hidden="true"></div>
    </article>
    <div [hidden]="service.isNewsAvailable" class="alert alert-info" role="alert">
      There is no news available. Stay tuned!
    </div>
    <div
      class="alert alert-info text-center"
      role="alert"
      [hidden]="!((service.recentBlogPosts | async).length && service.isLoading)">
      Loading...
    </div>
  </div>
</div>
<div [hidden]="!service.isLoading">
  <div class="load-spinner" role="status" aria-live="polite" aria-label="Loading posts"></div>
</div>
