<iframe
  [src]="playerPath"
  marginheight="0"
  frameborder="0"
  *ngIf="embed"
  allowfullscreen
  webkitallowfullscreen
  mozallowfullscreen></iframe>

<div class="content row" *ngIf="!embed">
  <fieldset title="Course title">
    <legend>
      {{ registration.course.name }}
    </legend>
  </fieldset>
  <div
    *ngIf="registration.course.format === 101"
    class="external-player-dialog text-center"
    [ngClass]="{ 'has-up-next-button': wrapped }">
    <!--page 2-->
    <!--      <p>-->
    <!--        This {{(registration.course.label.name||registration.course.formatName)}}
            requires a file download. Please continue or visit the <a href="#"
            title="Open Course Details" ui-sref="main.course({id: registration.courseId})">
            course details page</a> to access it.-->
    <!--      </p>-->
    <button
      type="button"
      class="btn btn-primary btn-lg"
      (click)="popupExternalPlayer('Download')"
      title="Open"
      *ngIf="hasAction('Download')"
      id="course_player_popup_external_player_download">
      Open
    </button>
  </div>

  <div
    *ngIf="registration.course.format === 103 || (registration.course.scorm && forceNewWindow)"
    class="external-player-dialog text-center"
    [ngClass]="{ 'has-up-next-button': wrapped }">
    <!--page 12-->
    <!--      <p>-->
    <!--        This {{(registration.course.label.name||registration.course.formatName)}}
            requires opening an external source. Please continue or visit the <a href="#"
            title="Open Course Details" ui-sref="main.course({id: registration.courseId})">
            course details page</a> to access it.-->
    <!--      </p>-->
    <button
      type="button"
      class="btn btn-primary btn-lg"
      (click)="popupExternalPlayer('Launch')"
      id="course_player_popup_external_player_launch"
      *ngIf="hasAction('Launch')"
      title="Open">
      Open
    </button>
  </div>
</div>
