import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { GlobalConfig } from 'core/environment';
import _ from 'lodash';

export interface ICourseRating {
  id: number | string;
  rating?: number;
  ratingCount?: number;
  ratings?: {
    rating: number;
    count: number;
  };
}
@Component({
  standalone: false,
  selector: 'course-star-rating',
  templateUrl: './course-star-rating.component.html',
})
export class CourseStarRatingComponent implements OnInit {
  static readonly selector = 'courseStarRating';

  @Input() course: ICourseRating;
  @Input() modern: boolean;
  @Input() singleStar = false;
  @Input() count = true;

  courseRating: number;
  courseRatingCount: number;
  minRatingCount: number;

  courseRatingAvailable: boolean;
  constructor(
    private globalConfig: GlobalConfig,
    private stateService: StateService,
    private window: Window,
    private activeState: UIRouterGlobals,
  ) {}

  @HostBinding('class.hidden') get hidden() {
    return !this.courseRatingAvailable || !this.courseRatingCount || this.courseRatingCount < this.minRatingCount;
  }

  ngOnInit() {
    this.courseRatingAvailable =
      _.get(this.window, 'elmsEnvironment.userSettings.course_rating_available', false) &&
      _.get(this.globalConfig.settings, 'courseDetails.reviewsVisible');
    this.courseRating = this.getValue(this.course.rating || _.get(this.course, 'ratings.rating'));
    this.courseRatingCount = this.getValue(this.course.ratingCount || _.get(this.course, 'ratings.count'));
    this.minRatingCount = _.get(this.globalConfig, 'settings.courseDetails.minNumberOfReviewsToShowRating', 1);
  }

  openCourse() {
    if (this.stateService.includes('main.course')) {
      if (this.modern) {
        // rootScopeService.broadcast('event:scrollTo', 'reviews', 'center');
        console.error('Should scroll to reviews', ['event:scrollTo', 'reviews', 'center']);
      } else {
        this.stateService.go(
          this.activeState.current.name,
          Object.assign(this.activeState.params, { activeTab: 'reviews' }),
        );
      }
    } else {
      if (this.modern || !this.stateService.get('main.courseReviews')) {
        this.stateService.go('main.course', { id: this.course.id, activeTab: 'reviews' });
      } else {
        this.stateService.go('main.courseReviews', { id: this.course.id });
      }
    }
  }

  private getValue(val: number | number[]): number {
    return _.isArray(val) ? val[0] : val;
  }
}
