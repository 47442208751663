import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CACHE_NAME_TOKEN, CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { IGroupCategory } from 'modules/group/models/group.model';
import { Observable } from 'rxjs';

enum GroupCategoryRequestPaths {
  QUERY = '/a/groups/categories/',
}

export enum GroupCategory {
  NATIONAL = 10,
  STATE = 12,
}

@Injectable()
export class GroupCategoryService {
  constructor(private http: HttpClient) {}

  public query(): Observable<IGroupCategory[]> {
    return this.http.get<IGroupCategory[]>(GroupCategoryRequestPaths.QUERY, {
      context: new HttpContext().set(CACHE_NAME_TOKEN, 'groupCache').set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }
}
