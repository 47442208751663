import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequestParams } from 'core/services';
import { Observable, map } from 'rxjs';
import { IBlogPost } from '../models/blog-post.model';
import { BlogPostService } from './blog-post.service';

export type IBlogPostRotatorParams = HttpRequestParams & {
  direction: 'next' | 'previous';
  postId?: number;
  sequenceId?: string;
  directlyAssigned?: boolean;
};

export type IBlogPostRotatorState = IBlogPostRotatorParams & {
  post?: IBlogPost;
  count?: number;
  position?: number;
};

@Injectable()
export class BlogPostRotatorService {
  public state: IBlogPostRotatorState = {
    direction: 'next',
  };

  constructor(
    private blogPostService: BlogPostService,
    private http: HttpClient,
  ) {}

  nextPost(postId?: number): Observable<IBlogPost | null> {
    return this.getPost({ postId, direction: 'next' });
  }

  previousPost(postId?: number): Observable<IBlogPost | null> {
    return this.getPost({ postId, direction: 'previous' });
  }

  private getPost(params: IBlogPostRotatorParams): Observable<IBlogPost | null> {
    if ('directlyAssigned' in this.state) {
      params.directlyAssigned = this.state.directlyAssigned;
    }

    if ('sequenceId' in this.state) {
      params.sequenceId = this.state.sequenceId;
    }

    if (!params.postId) {
      delete params.postId;
    }

    return this.http.get<IBlogPostRotatorState>('/a/blog_posts/rotator/', { params }).pipe(
      map((state) => {
        this.state = state || this.state;

        return this.state.post ? this.blogPostService.transform(state.post) : null;
      }),
    );
  }
}
