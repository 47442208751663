import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { LaddaModule } from 'components/ladda/ladda.module';
import { EmailAttachmentsComponent } from './components/email-attachments.component';
import { EmailPreviewComponent } from './components/email-preview.component';
import { EmailSenderComponent } from './components/email-sender.component';
import { EmailPreviewModalComponent } from './modal/email-preview-modal.component';
import { SendEmailModalComponent } from './modal/send-email-modal.component';
import { EmailTemplateService } from './services/email-template.service';
import { EmailService } from './services/email.service';

@NgModule({
  imports: [CommonModule, FormsModule, HttpClientModule, LaddaModule, NgbTooltipModule, LmsComponentsModule],
  declarations: [
    EmailPreviewComponent,
    EmailPreviewModalComponent,
    SendEmailModalComponent,
    EmailSenderComponent,
    EmailAttachmentsComponent,
  ],
  exports: [EmailAttachmentsComponent],
  providers: [EmailService, EmailTemplateService],
})
export class EmailsModule {}
