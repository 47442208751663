import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { ISearchFilterTypes, ISearchFilterValueTerm } from 'app/deprecated/search/models/search-filters.models';
import { GlobalConfig } from 'core/environment';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import { BaseFilterService } from '../../../../deprecated/search/services/filters-old/search-filtering.service.ajs-upgraded';

@Component({
  standalone: false,
  selector: 'catalog-search-filters',
  templateUrl: './catalog-search-filters.component.html',
})
export class CatalogSearchFiltersComponent implements OnInit, OnDestroy {
  static readonly selector = 'catalogSearchFilters';

  @Input() loading: boolean;

  type = 'course';
  defaultSort = 'relevance';
  isAnonymous: boolean = this.currentUser.get().anonymous;
  sortTerms: ISearchFilterValueTerm[] = this.searchFilters.sortTerms;
  sortTermValues: string[] = _.map(this.sortTerms, 'value');
  isOpen = false;
  mobileView = false;
  sort: ISearchFilterValueTerm;
  filters: ISearchFilterTypes;
  allFilters: any[] = [];
  showFilters = true;

  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;
  private urlPath: string;

  constructor(
    private window: Window,
    private stateService: StateService,
    private currentUser: CurrentUserService,
    private urlService: UrlService,
    private searchFilters: BaseFilterService,
    private activeState: UIRouterGlobals,
    private globalConfig: GlobalConfig,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setWindowWidth();
  }

  get visibleFilters() {
    return this.filters?.ui?.filter((filter) => filter.visible) || [];
  }

  ngOnInit(): void {
    this.initAllFilters();
    this.initUiFilters();
    this.ngOnUrlChange();
    this.setWindowWidth();
    this.urlHandlerDestroy = <UrlParamHandler>this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
  }

  ngOnUrlChange() {
    this.urlPath = this.urlPath || this.urlService.path();

    if (this.urlPath === this.urlService.path()) {
      const params = _.pickBy(this.urlService.search(), _.identity);

      if (!_.isEqual(params, this.urlParams)) {
        this.urlParams = params;
        this.ngOnUrlParamsChange(params);
      }
    }
  }

  ngOnUrlParamsChange(params: IUrlParams<string>) {
    this.sort = this.getSortTerm(
      params.sort && _.includes(this.sortTermValues, params.sort) ? params.sort : this.defaultSort,
    );

    if (params.type !== this.type) {
      params.type = this.type;
      this.stateService.go(this.activeState.current, params);
    }
  }

  applySort(sort: ISearchFilterValueTerm) {
    this.sort = sort;
    this.applySearchFilters();
    this.isOpen = false;
  }

  applySearchFilters() {
    const searchQuery = Object.assign({}, this.urlParams, {
      type: this.type,
      sort: this.sort.value !== this.defaultSort ? this.sort.value : '',
    });

    this.stateService.go(this.activeState.current, searchQuery);
  }

  hasSelectedItems() {
    return !!_.find(this.filters.ui, function (filter) {
      return filter?.selectedItems?.length;
    });
  }

  clearAll() {
    _.forEach(this.filters.ui, function (filter) {
      filter.clearSelection();
      filter.selectedItems = [];
    });
  }

  toggleShowFilter() {
    this.showFilters = !this.showFilters;
  }

  private setWindowWidth() {
    this.mobileView = this.window.innerWidth < 768;
  }

  private initUiFilters() {
    this.filters = {
      ui: this.allFilters,
      all: [],
    };
  }

  private initAllFilters() {
    const filters = this.globalConfig.settings.searchFilters.catalog.filter((f) => f.available).map((f) => f.name);

    this.allFilters = _.map(filters, (name) => {
      const filter = new this.searchFilters[name]();

      filter.appearance = 'button';
      filter.load();
      filter.extractConditions();

      return filter;
    });
  }

  private getSortTerm(sort: string) {
    return _.find(this.sortTerms, function (term) {
      return term.value === sort;
    });
  }
}
