import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { GroupCategoryService } from 'modules/group/services/group-category.service';
import { GroupService } from 'modules/group/services/group.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, LmsComponentsModule],
  declarations: [],
  providers: [GroupService, GroupCategoryService],
})
export class GroupModule {}
