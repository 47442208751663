<h1 class="no-margin-bottom">{{ event.title }}</h1>
<br />

<div>
  <div class="row">
    <div class="col-xs-12 standard-margin-bottom">
      <div class="pull-left">
        <a
          href=""
          class="btn btn-link no-padding-left modern-back-button"
          title="Go back"
          (click)="$event.stopPropagation(); $event.preventDefault(); goBack()"
          ><span class="fa fa-chevron-left"></span>Back</a
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <p class="label-list">
        <span class="label label-event">Calendar Event</span>
        <span class="twc-badge twc-badge-outline-secondary">ID {{ event.id }}</span>
      </p>

      <div [innerHTML]="event.description | markdownToHtml"></div>

      <h3>Event Schedule</h3>
      <div>
        <strong *ngIf="event.startDate">
          <date-time [date]="event.startDate"></date-time>
        </strong>
        <span *ngIf="event.endDate"> - <date-time [date]="event.endDate"></date-time></span>
      </div>

      <div *ngIf="event.place">
        <h3>Location</h3>
        <div [innerHTML]="event.place"></div>
      </div>
    </div>
  </div>
</div>
