import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CourseCatalogModule } from 'modules/course/catalog/course-catalog.module';
import { CourseCommonModule } from 'modules/course/common/course-common.module';
import { CourseCustomListModule } from 'modules/course/custom-lists/course-custom-list.module';
import { CoursePlayerModule } from 'modules/course/player/course-player.module';
import { CourseQuizService } from 'modules/course/quiz/services/course-quiz.service';
import { CourseReviewsModule } from 'modules/course/reviews/course-reviews.module';
import { CourseSessionsService } from 'modules/course/sessions/services/course-sessions.service';
import { CourseViewsModule } from 'modules/course/views/course-views.module';
import { NetworkModule } from 'modules/network/network.module';
import { QuizModule } from 'modules/quiz/quiz.module';
import { CourseService } from './common/services/course.service';
import { ApprovalStatusService } from './services/approval-status.service';
import { collectionsServiceProvider } from './services/collections.service.ajs-upgraded-provider';
import { courseComponentsServiceProvider } from './services/course-components.service.ajs-upgraded-provider';
import { CourseSessionLocationService } from './sessions/services/course-session-location.service';
import { CourseSessionManagerService } from './sessions/services/course-session-manager.service';

@NgModule({
  imports: [
    NgbDropdownModule,
    CourseCommonModule,
    CourseCatalogModule,
    NetworkModule,
    CourseViewsModule,
    CourseCustomListModule,
    CoursePlayerModule,
    UIRouterModule,
    LmsComponentsModule,
    CommonModule,
    QuizModule,
    CourseReviewsModule,
  ],
  exports: [],
  declarations: [],
  providers: [
    courseComponentsServiceProvider,
    collectionsServiceProvider,
    ApprovalStatusService,
    CourseService,
    // approvalStatusServiceProvider,
    CourseQuizService,
    CourseSessionsService,
    CourseSessionManagerService,
    CourseSessionLocationService,
  ],
})
export class CourseModule {}
