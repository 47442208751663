<div class="horizontal-tile tw-rounded-lg tw-border tw-border-gray-3 tw-bg-gray-2 tw-p-5 tw-text-gray-11">
  <div class="tw-flex tw-flex-col tw-gap-5 sm:tw-flex-row">
    <div>
      <div class="course-thumbnail-image" [ngStyle]="{ 'background-image': heroBackground }"></div>
    </div>

    <div class="tw-flex-grow">
      <div class="tw-flex tw-flex-col tw-gap-3">
        <div class="tw-mb-0 tw-mt-0 tw-line-clamp-3 tw-text-xl tw-font-medium tw-text-gray-12">
          {{ post.title }}
        </div>

        <div [innerHTML]="post.getContent()"></div>
      </div>
    </div>
  </div>
</div>
