import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { GLOBAL_CONFIG } from 'core/environment/global-config.service.ajs-upgraded-provider';
import _ from 'lodash';
import { CatalogViewComponent } from 'modules/course/catalog/view/catalog-view.component';
import { searchTerms } from 'modules/search/common';

export const courseCatalogRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.fullCatalog',
    url: '^/full-catalog?' + searchTerms.join('&') + '&catalogType&catalog_query',
    params: _.reduce(
      searchTerms.concat('catalogType', 'catalog_query'),
      function (obj, key) {
        obj[key] = {
          value: null,
          dynamic: true,
        };

        return obj;
      },
      {},
    ),
    component: CatalogViewComponent,
    data: {
      label: 'Catalog',
      get availableFor(): string[] {
        return GLOBAL_CONFIG.settings.courseCatalog?.availableFor;
      },
    },
  },
];
