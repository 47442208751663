<section class="text-sla tw-overflow-clip tw-rounded-xl tw-border tw-border-gray-3 tw-bg-gray-2" *ngIf="post">
  <img
    alt="{{ post.title }}"
    *ngIf="heroBackground"
    class="tw-aspect-auto tw-h-48 tw-w-full tw-bg-gray-8 tw-object-cover sm:tw-h-64 md:tw-h-96"
    [src]="heroBackground" />

  <div class="tw-flex tw-flex-col tw-gap-4 tw-p-4 md:tw-p-8">
    <h2
      class="tw-m-0 tw-text-xl tw-text-gray-12 md:tw-text-2xl lg:tw-text-3xl"
      *ngIf="post.showTitle"
      [innerHTML]="post.title"></h2>

    <div class="tw-line-clamp-3 tw-text-gray-11" [innerHTML]="post.getContent()"></div>

    <div class="tw-flex tw-items-center tw-gap-3">
      <a
        href="#"
        class="twc-btn twc-btn-primary"
        uiSref="main.blog"
        [uiParams]="{ id: post.id }"
        [trackLinkClick]="'announcement detail'">
        Read more
      </a>
      <a class="tw-text-base tw-font-normal" uiSref="main.news" [trackLinkClick]="'announcements'">View all</a>
    </div>
  </div>
</section>
