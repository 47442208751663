import { Injectable } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import { ApprovalStatus } from 'core/types';
import { IApprovalStatusItem } from '../common/models/course.model';

@Injectable({
  providedIn: 'root',
})
export class ApprovalStatusService {
  private statusNames: Record<number, string> = {
    1: 'Ready for approval',
    2: 'Published',
    3: 'Declined',
    4: 'More information requested',
    5: 'Incomplete',
  };

  constructor(globalConfig: GlobalConfig) {
    if (globalConfig.courseApprovalCustomStatuses) {
      Object.assign(this.statusNames, globalConfig.courseApprovalCustomStatuses);
    }
  }

  public getStatusName(statusId: number): string {
    return this.statusNames[statusId];
  }

  public toList(): IApprovalStatusItem[] {
    return Object.keys(ApprovalStatus).map((i) => ({
      id: ApprovalStatus[i],
      name: this.getStatusName(ApprovalStatus[i]),
    }));
  }
}
