import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { Transition } from '@uirouter/core';
import { OpenIdConnectErrorComponent } from 'modules/integrations/components/open-id-connect-error.component';
import { OpenIdConnectLinkAccountComponent } from 'modules/integrations/components/open-id-connect-link-account.component';
import { SamlSsoClientListComponent } from 'modules/integrations/components/saml-sso-client-list.component';
import { SamlSsoViewComponent } from 'modules/integrations/components/sso-view.component';

export const integrationsRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'prompt.samlSso',
    url: '/saml-sso?error_code',
    params: {
      error_code: null,
    },
    component: SamlSsoViewComponent,
    data: {
      label: 'SAML SSO',
      availableFor: ['anonymous'],
    },
    resolve: [
      {
        provide: 'errorCode',
        deps: [Transition],
        useFactory: (transition: Transition) => transition.params().error_code,
      },
    ],
  },
  {
    name: 'prompt.samlSsoClients',
    url: '/saml-sso-clients',
    params: {
      error_code: null,
    },
    component: SamlSsoClientListComponent,
    data: {
      label: 'SAML SSO Clients',
      availableFor: ['anonymous', 'regularUser', 'admin'],
    },
  },
  {
    name: 'prompt.openIdConnectLinkAccount',
    url: '/open-id-connect/link-account',
    params: {
      error_code: null,
    },
    component: OpenIdConnectLinkAccountComponent,
    data: {
      label: 'Open-id connect',
      availableFor: ['anonymous', 'regularUser', 'admin'],
    },
  },
  {
    name: 'prompt.openIdConnectError',
    url: '/open-id-connect/error',
    params: {
      error_code: null,
    },
    component: OpenIdConnectErrorComponent,
    data: {
      label: 'Open-id connect',
      availableFor: ['anonymous', 'regularUser', 'admin'],
    },
  },
];
