<span class="tw-flex tw-items-center tw-gap-1.5" *ngIf="course.learners > 0">
  <i class="fa-solid fa-graduation-cap tw-opacity-70" aria-hidden="true"></i>
  <span>
    {{ course.learners }}
    <span [ngPlural]="course.learners">
      <ng-template ngPluralCase="=1">learner</ng-template>
      <ng-template ngPluralCase="other">learners</ng-template>
    </span>
  </span>
</span>
