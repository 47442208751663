import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { GlobalConfig } from 'core/environment';
import { courseRegistrationStatuses } from 'modules/course-registrations/models/course-registration-status.model';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import {
  ICollectionCourseItem,
  ICollectionItemBase,
  isCollectionCourse,
} from 'modules/course/common/models/learning-object-structure.model';
import { Player } from 'modules/course/player/services/player.service';
import { CourseComponentsService } from 'modules/course/services/course-components.service.ajs-upgraded-provider';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Component({
  standalone: false,
  selector: 'player-collection-components',
  templateUrl: './player-collection-component.component.html',
})
export class PlayerCollectionComponentsComponent implements OnInit {
  @Input() blsId: number | string;
  @Input() registration: ILearningObjectRegistration;
  @Input() player: Player;
  @Output() recommendationsBound = new EventEmitter<number>();

  userId: number = this.currentUser.get().id;
  compilationStructure: ICollectionItemBase[];
  courses: ICollectionCourseItem[];
  constructor(
    public globalConfig: GlobalConfig,
    private courseComponentsService: CourseComponentsService,
    private currentUser: CurrentUserService,
  ) {}

  ngOnInit() {
    fromPromise(this.courseComponentsService.getCompilationStructure(this.blsId, this.userId)).subscribe((response) => {
      this.compilationStructure = response.structure;
      this.courses = this.extractCourses(this.compilationStructure);
      this.recommendationsBound.emit(this.courses.length);
    });
  }

  extractCourses(structure: ICollectionItemBase[]): ICollectionCourseItem[] {
    const courses: ICollectionCourseItem[] = [];

    structure.forEach((item) => {
      if (item.id !== this.blsId && item.id !== this.registration.course.id) {
        if (isCollectionCourse(item)) {
          if (courseRegistrationStatuses.strictCompletedSet.includes(item.status_id)) {
            courses.push(item);
          }
        } else {
          Array.prototype.push.apply(courses, this.extractCourses(item.items));
        }
      }
    });

    return courses;
  }
}
