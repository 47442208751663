import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CourseProviderOrganizationMemberService } from './services/course-provider-organization-member.service';
import { CourseProviderOrganizationService } from './services/course-provider-organization.service';
import { OrganizationSettingsViewComponent } from './view/components/organization-settings-view.component';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [OrganizationSettingsViewComponent],
  providers: [CourseProviderOrganizationService, CourseProviderOrganizationMemberService],
  exports: [OrganizationSettingsViewComponent],
})
export class CourseProvidersOrganizationsModule {}
