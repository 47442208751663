import { Component, Input, OnInit } from '@angular/core';
import { ICourse } from 'modules/course/common/models/course.model';
import { CheckoutProductType, ICheckoutProduct } from '../models/checkout.models';

@Component({
  standalone: false,
  selector: 'e-commerce-product',
  templateUrl: './e-commerce-product.component.html',
})
export class ECommerceProductComponent implements OnInit {
  @Input() product: ICheckoutProduct;
  @Input() purchaseError: string | null;

  course: ICourse;

  readonly productType = CheckoutProductType;

  ngOnInit(): void {
    this.course = <ICourse>(
      (this.product.data?.courseId ? { id: this.product.data.courseId, name: this.product.name } : this.product)
    );
  }
}
