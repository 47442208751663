<div
  class="course-player"
  [ngClass]="{
    'course-content-menu-is-open': contentMenuOpen,
    'course-content-menu-is-closed': !contentMenuOpen && showSteps(),
    'course-content-menu-is-inactive': !showSteps(),
  }">
  <!-- Content -->
  <div class="course-wrapper resizable">
    <div [ngClass]="{ 'scorm-recommendations': leftMenuEnabled }">
      <!--Left Menu-->
      <div
        class="hidden-xs player-recommendations-sidebar"
        ng-show="leftMenuEnabled"
        [ngClass]="{ isOpen: leftMenuExpanded }"
        *ngIf="!current_bls_id || blsCourse">
        <course-player-menu
          [registration]="registration"
          [collection]="blsCourse"
          [player]="player"
          [menuEnabled]="leftMenuEnabled"></course-player-menu>
      </div>

      <!--Slider/Divider-->
      <button
        type="button"
        class="hidden-xs player-splitter"
        [ngClass]="{ isOpen: leftMenuExpanded }"
        (click)="leftMenuExpanded = !leftMenuExpanded"
        *ngIf="leftMenuEnabled">
        &nbsp;
        <div class="fa fa-caret-left" [ngClass]="{ 'fa-caret-right': !leftMenuExpanded }"></div>
      </button>

      <!--Content panel-->
      <div [ngClass]="{ isOpen: leftMenuExpanded, 'player-content-recommendations': leftMenuEnabled }">
        <course-player
          [course]="course"
          [registration]="registration"
          [(player)]="player"
          (playerEvent)="handlePlayerEvent($event)"
          [hideRetakePostAssessment]="hideRetakePostAssessment"
          [contentType]="contentType"
          [closeSubject]="closeSubject"></course-player>
      </div>
    </div>
  </div>

  <!-- Content header bar -->
  <div class="navbar navbar-fixed-top course-content-navbar" [ngClass]="{ 'navbar-open': contentMenuOpen }">
    <div class="navbar-inner">
      <div class="flex flex-vertical-center">
        <div class="flex-row">
          <div class="flex-col-grow flex-col">
            <button
              type="button"
              class="btn btn-default btn-sm navbar-btn pull-left visible-xs"
              (click)="toggleContentMenu()"
              *ngIf="showSteps()">
              <span class="fa-solid fa-bars"></span>
              <span class="sr-only">Toggle player menu</span>
            </button>
            <p class="navbar-text text-truncate">
              &nbsp;<strong
                >{{ course.name }}<span *ngIf="blsCourse?.name">&nbsp;-&nbsp;{{ blsCourse?.name }}</span></strong
              >
            </p>
          </div>

          <div class="flex-col">
            <!--actions for xs screens -->
            <div ngbDropdown #dropDown="ngbDropdown" class="visible-xs player-actions-dropdown">
              <button
                [disabled]="!browserIsOnline"
                class="btn btn-primary btn-sm dropdown-toggle"
                type="button"
                ngbDropdownToggle>
                Actions
                <span class="fa fa-caret-down"></span>
              </button>

              <ul ngbDropdownMenu class="dropdown-menu pull-right">
                <!--dropdown for Actions-->
                <li *ngIf="registration?.actions?.includes('PrintAction') && registration.hasTranscript">
                  <a
                    ngbDropdownItem
                    href="#"
                    (click)="trainingService.viewTranscript(registration.course.id)"
                    title="Transcript">
                    <span class="fa fa-file"></span>
                    {{ isQuizStep() ? 'Transcript' : 'Transcript' }}
                  </a>
                </li>
                <li *ngIf="registration?.actions?.includes('PrintAction') && !registration.hasTranscript">
                  <a
                    ngbDropdownItem
                    href="/d/course/{{ registration.course.id }}/transcript/"
                    target="_blank"
                    title="Print Course">
                    <span class="fa fa-print"></span> Print Course
                  </a>
                </li>

                <li
                  class="divider"
                  ngbDropdownItem
                  *ngIf="
                    showClose() &&
                    ((registration?.actions?.includes('PrintAction') && !registration.hasTranscript) ||
                      (registration?.actions?.includes('PrintAction') && registration.hasTranscript))
                  "></li>

                <li>
                  <a ngbDropdownItem *ngIf="!hiding && showClose()" href="#" (click)="hide()" title="Exit">
                    <span class="fa fa-times"></span> Exit
                  </a>
                </li>
              </ul>
            </div>
            <!-- end xs actions-->

            <!-- buttons for desktop screens, hide all but "Translate" -->
            <button
              type="button"
              data-[disabled]="!player.getNextAvailableStep(true).available"
              (click)="player.launchNextStep()"
              class="btn btn-sm btn-primary navbar-btn hidden-xs"
              *ngIf="nonContentStepIsAvailable() && !hideNextButton() && !!player.getNextAvailableStep(true).available">
              Take {{ player.getNextAvailableStep(true).title }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger navbar-btn navbar-right hidden-xs"
              (click)="hide()"
              [disabled]="!browserIsOnline"
              *ngIf="!hiding && showClose()">
              <span class="fa fa-times"></span> Close
            </button>

            <!--hide other buttons-->
            <button
              type="button"
              class="btn btn-sm btn-default navbar-btn navbar-right hidden-xs"
              (click)="trainingService.viewTranscript(registration.course.id)"
              *ngIf="registration?.actions?.includes('PrintAction') && registration.hasTranscript"
              title="Transcript">
              <span class="fa fa-file"></span> Transcript
            </button>

            <a
              href="/d/course/{{ registration?.course?.id }}/transcript/"
              class="btn btn-sm btn-default navbar-btn navbar-right btn-opaque hidden-xs"
              target="_blank"
              *ngIf="registration?.actions?.includes('PrintAction') && !registration.hasTranscript"
              title="Print Course">
              <span class="fa fa-print"></span> Print Course
            </a>
          </div>
          <!--end col with buttons-->
        </div>
      </div>
      <!--end flex-->
    </div>
  </div>

  <!--Steps Menu -->
  <div class="course-content-menu" *ngIf="showSteps()">
    <div class="btn-group">
      <div *ngFor="let step of getVisibleSteps()">
        <button
          type="button"
          class="btn btn-default"
          [ngClass]="{ 'btn-primary': step === player.currentStep, 'btn-success': step.completed }"
          [disabled]="!player.stepAvailable(step) || player.currentStep === step"
          *ngIf="step.title"
          (click)="player.showStep(step)">
          <span
            class="fa"
            [ngClass]="{
              'fa-check-circle': step.completed,
              'fa-arrow-circle-o-right': step === player.currentStep,
            }"></span>
          {{ step.title }}
        </button>
      </div>
    </div>
  </div>
</div>
