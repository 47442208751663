import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { calendarRoutingModule } from 'modules/calendar/calendar-routing.module';
import { CalendarEventsComponent } from 'modules/calendar/components/calendar-events.component';
import { CalendarComponent } from 'modules/calendar/components/calendar.component';
import { CalendarEventsService } from 'modules/calendar/services/calendar-events.service';
import { CourseRegistrationCommonModule } from 'modules/course-registrations/course-registration-common.module';
import { SearchDeprecatedModule } from '../../deprecated/search/search.deprecated.module';
import { SearchCommonModule } from '../search/common/search-common.module';
import { CalendarListViewComponent } from './components/calendar-list-view.component';
import { CalendarEventSearchListComponent } from './components/event-search-list.component';
import { LearningCalendarComponent } from './components/learning-calendar.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    NgbTooltipModule,
    FullCalendarModule,
    LmsComponentsModule,
    // SearchCommonModule, // Please don't touch!.
    SearchDeprecatedModule,
    CourseRegistrationCommonModule,
    UIRouterUpgradeModule.forChild({ states: calendarRoutingModule }),
    SearchCommonModule,
  ],
  declarations: [
    CalendarComponent,
    CalendarEventsComponent,
    LearningCalendarComponent,
    CalendarListViewComponent,
    CalendarEventSearchListComponent,
  ],
  providers: [CalendarEventsService],
  exports: [CalendarEventsComponent],
})
export class CalendarModule {}
