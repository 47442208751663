import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { Transition } from '@uirouter/core';
import { DiscussionsService } from 'modules/discussions/services/discussions.service';
import { lastValueFrom } from 'rxjs';
import { DiscussionComponent } from './view/discussion.component';

export const discussionsRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.discussion',
    url: '^/discussions/:id?from',
    component: DiscussionComponent,
    params: {
      id: null,
      from: {
        value: null,
        dynamic: true,
      },
    },
    data: {
      label: 'Discussion',
      availableFor: ['regularUser', 'admin'],
    },
    resolve: [
      {
        provide: 'discussionTopic',
        deps: [Transition, DiscussionsService],
        useFactory: (transition: Transition, discussionsService: DiscussionsService) => {
          return lastValueFrom(discussionsService.get(Number(transition.params().id)));
        },
      },
    ],
  },
];
