import { FactoryProvider } from '@angular/core';
import { ICourseAbstract } from 'modules/course/common/models/course.model';

export interface IStatsInfo {
  completed: number;
  total: number;
  completedCount: number;
}

export abstract class CollectionsService {
  abstract aggregateRegistrationsInfo(compilationStructure: any, includeOptional?: boolean): IStatsInfo;

  abstract collectionRegistered(registration: any): boolean;

  abstract updateChangedComponents(compilationStructure: any, newCompilationStructure: any): any;

  abstract getCourseCollections(courseId: number): Promise<ICourseAbstract[]>;

  abstract getComponentsEarnedCertificates(courseId: number): any;

  abstract isCollectionPlayable(collection: any): any;

  abstract getCollectionStructureMeta(collectionId: number): any;

  abstract checkLocationTypeForComponents(collectionId: number): any;
}

export function collectionsServiceFactory(injector: angular.auto.IInjectorService): CollectionsService {
  return injector.get<CollectionsService>('collectionsService');
}

export const collectionsServiceProvider: FactoryProvider = {
  provide: CollectionsService,
  useFactory: collectionsServiceFactory,
  deps: ['$injector'],
};
