import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import {
  Calendar,
  CalendarOptions,
  DatesSetArg,
  EventClickArg,
  EventInput,
  EventSourceFuncArg,
} from '@fullcalendar/core';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { ElmsUtils } from 'core/utils';
import {
  ICalendarEvent,
  ICalendarQueryParams,
  ICalendarSuccessCallback,
  ICalendarView,
  ICalendarViewMode,
  IEventSources,
} from 'modules/calendar/models/events.model';
import { CalendarEventsService } from 'modules/calendar/services/calendar-events.service';
import { IUser } from 'modules/user/models/user.model';
import moment from 'moment';
import { Observable, Unsubscribable, concat, finalize, map, of, reduce, switchMap, tap } from 'rxjs';
import { calendarOptions } from './calendar-options.provider';

@Component({
  standalone: false,
  selector: 'learning-calendar',
  templateUrl: './learning-calendar.component.html',
})
export class LearningCalendarComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('calendar') calendarComponent?: FullCalendarComponent;
  @ViewChild('ngDate') datePicker?: NgbInputDatepicker;

  @Input() editable = false;
  @Input() minDate: string = null;
  @Input() maxDate: string = null;
  @Input() groupIds?: string[];
  @Input() sessionLabels?: string;
  @Input() registeredOnly: boolean;
  @Input() showRegistrationStatus: boolean;
  @Input() view: ICalendarViewMode = 'dayGridMonth';
  @Output() calendarChange = new EventEmitter<ICalendarView>();

  selectedDate: string;
  calendarOptions: CalendarOptions = calendarOptions;

  private calendarApi?: Calendar;
  private requestSubscriber?: Unsubscribable;
  private calendarView: ICalendarView;
  private lockEvents: boolean;
  private user: IUser;

  constructor(
    private currentUserService: CurrentUserService,
    private eventsService: CalendarEventsService,
    private notificationService: NotificationService,
    private stateService: StateService,
  ) {}

  eventSource: IEventSources = (i, success) => this.fetchEvents(i, success);

  ngOnInit(): void {
    this.user = this.currentUserService.get();
    this.calendarOptions.initialView = this.view;
    this.calendarOptions.editable = this.editable;
    this.calendarOptions.initialDate = this.getDefaultDate(this.minDate, this.maxDate);
    this.calendarView = { minDate: this.minDate, maxDate: this.maxDate, view: this.view };
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
    this.calendarApi.on('datesSet', (info: DatesSetArg) => this.onCalendarViewChanged(info));
    this.calendarOptions.customButtons.myCustomButton.click = () => this.datePicker.toggle();
    this.calendarOptions.eventClick = (info: EventClickArg) => this.onCalendarEventClick(info);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.minDate || changes.maxDate || changes.view) &&
      (!changes.minDate?.firstChange || !changes.maxDate?.firstChange || !changes.view?.firstChange)
    ) {
      const { view, maxDate, minDate } = this.calendarView;

      if (this.view !== view || this.minDate !== minDate || this.maxDate !== maxDate) {
        this.lockEvents = true;
        this.calendarApi?.changeView(this.view || 'dayGridMonth', this.getDefaultDate(this.minDate, this.maxDate));
        this.lockEvents = false;
      }
    }

    if (
      (changes.registeredOnly || changes.groupIds || changes.sessionLabels) &&
      (!changes.registeredOnly?.firstChange || !changes.groupIds?.firstChange || !changes.sessionLabels?.firstChange)
    ) {
      this.calendarApi?.removeAllEvents();
      this.calendarApi?.refetchEvents();
    }
  }

  ngOnDestroy() {
    if (this.requestSubscriber) {
      this.requestSubscriber?.unsubscribe();
      delete this.requestSubscriber;
    }
  }

  fetchEvents(info: EventSourceFuncArg, success: ICalendarSuccessCallback) {
    const query: ICalendarQueryParams = {
      group_id: this.groupIds?.join(','),
      user_courses: this.registeredOnly || null,
      session_label_id: this.sessionLabels,
      min_start_date: ElmsUtils.formatDate(info.start, 'YYYY-MM-DDZ'),
      max_start_date: ElmsUtils.formatDate(info.end, 'YYYY-MM-DDZ'),
    };

    this.datePicker?.close();
    this.notificationService.info('Loading');
    this.requestSubscriber?.unsubscribe();
    this.requestSubscriber = this.eventsService
      .events(query)
      .pipe(
        map((events) => events.map((e) => this.transform(e))),
        tap((items) => {
          success(items);
        }),
        switchMap((items) => this.loadRegistrations(items.map((i) => i.extendedProps as ICalendarEvent))),
        finalize(() => {
          this.notificationService.visibleInfo(false);
          this.requestSubscriber?.unsubscribe();
          delete this.requestSubscriber;
        }),
      )
      .subscribe();
  }

  onDatePickerSelected() {
    this.calendarApi.changeView('timeGridDay', this.selectedDate);
  }

  private transform(event: ICalendarEvent): EventInput {
    return {
      id: event.id.toString() + event.sessionId?.toString() + event.scheduleId?.toString(),
      title: event.name,
      start: event.start,
      end: event.end,
      editable: this.editable,
      classNames: event.className,
      url: event.url,
      allDay: event.allDay,
      extendedProps: event,
    };
  }

  private loadRegistrations(items: ICalendarEvent[]): Observable<null> {
    if (this.showRegistrationStatus && !this.registeredOnly) {
      const courses = items.filter((i) => i.type === 'course');
      const queue: Observable<ICalendarEvent[]>[] = [];
      const sessionIds = courses.filter((i) => !i.conferenceSessionId).map((i) => i.sessionId);
      const conferenceSessionIds = courses.filter((i) => i.conferenceSessionId).map((i) => i.sessionId);

      if (sessionIds.length) {
        queue.push(this.eventsService.registrations(items, this.user.id, sessionIds));
      }

      if (conferenceSessionIds.length) {
        queue.push(this.eventsService.conferenceRegistrations(items, this.user.id, conferenceSessionIds));
      }

      if (queue.length) {
        return concat(...queue).pipe(
          map((events) => events.map((e) => this.transform(e))),
          tap((allEvents) => {
            if (allEvents.length) {
              this.calendarApi.batchRendering(() => {
                allEvents.forEach((e) => {
                  const event = this.calendarApi.getEventById(e.id);

                  event.setExtendedProp('labels', (e.extendedProps as ICalendarEvent).labels);
                  event.setExtendedProp('title', (e.extendedProps as ICalendarEvent).title);
                  event.setProp('classNames', e.classNames);
                  event.setProp('title', e.title);
                });
              });
            }
          }),
          reduce(() => null),
        );
      }
    }

    return of(null);
  }

  private getDefaultDate(start: string, end: string): string | null {
    if (start) {
      if (end && end !== start) {
        const startDate = moment(start);
        const diffDays = moment(end).diff(startDate, 'days');

        if (diffDays > 0) {
          startDate.add(Math.floor(diffDays / 2), 'day');
        }

        start = startDate.format('YYYY-MM-DD');
      }

      return start;
    }

    return ElmsUtils.formatDate(new Date(), 'YYYY-MM-DD');
  }

  private onCalendarViewChanged(info: DatesSetArg) {
    this.calendarView = {
      minDate: ElmsUtils.formatDate(info.start, 'YYYY-MM-DD'),
      maxDate: ElmsUtils.formatDate(info.end, 'YYYY-MM-DD'),
      view: info.view.type,
    };

    if (!this.lockEvents) {
      this.calendarChange.emit(this.calendarView);
    }

    // TODO fixes issues with FullCalendar itself:
    // 1. https://yt.kmionline.com/issue/TRAIN3-24778#focus=Comments-75-1765490.0-0
    // 2. https://github.com/fullcalendar/fullcalendar/issues/7089
    setTimeout(() => this.calendarApi.updateSize(), 15);
  }

  /* TODO Angular itself redirectes to the initial state (reseting the url params) if a link[a] is static.
   * example: <a href="/main/course/<courseId>"></a> */
  private onCalendarEventClick(info: EventClickArg) {
    if (!info.jsEvent.shiftKey && !info.jsEvent.ctrlKey && !info.jsEvent.metaKey) {
      const event = info.event.extendedProps as ICalendarEvent;

      info.jsEvent.preventDefault();
      this.stateService.go('main.' + event.type, { id: event.id });
    }
  }
}
