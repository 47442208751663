import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { ElmsUtils } from 'core/utils';
import _ from 'lodash';
import {
  ICertificate,
  ICertificateFieldDetail,
  ICertificateOptions,
  IDataTypeFormat,
} from 'modules/certificates/models/certificate.model';
import { Observable, map } from 'rxjs';
import * as uuid from 'uuid';

export enum CertificatesEndpoints {
  GET = '/a/certificates/:certificateId/',
  PUT = '/a/certificates/:certificateId/',
  POST = '/a/certificates/',
  DELETE = '/a/certificates/:certificateId/',
  OPTIONS = '/a/certificates/options/',
}

interface IUniqResponse {
  unique: boolean;
}

@Injectable()
export class CertificateService {
  private defaultProps: Partial<ICertificate> = {
    globalId: uuid.v4(),
    active: false,
    private: true,
    groups: [],
  };

  constructor(private http: HttpClient) {}

  public get(
    certificateId: number,
    permissions?: ReadonlyArray<string>,
    throwHttpErrors = false,
  ): Observable<ICertificate> {
    let params = new HttpParams();

    if (permissions) {
      params = params.appendAll({ permitted_for: permissions });
    }

    return this.http
      .get<ICertificate>(ElmsUtils.formatUrl(CertificatesEndpoints.GET, { certificateId }), {
        params: params,
        context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
      })
      .pipe(map((certificate) => Object.assign({}, this.defaultProps, certificate)));
  }

  public update(certificateId: number, payload: Partial<ICertificate>): Observable<ICertificate> {
    return this.http.put<ICertificate>(ElmsUtils.formatUrl(CertificatesEndpoints.PUT, { certificateId }), payload);
  }

  public save(payload: Partial<ICertificate>): Observable<ICertificate> {
    return this.http.post<ICertificate>(CertificatesEndpoints.POST, payload);
  }

  public delete(certificateId: number): Observable<null> {
    return this.http.delete<null>(ElmsUtils.formatUrl(CertificatesEndpoints.DELETE, { certificateId }));
  }

  public newCertificate(customAttrs?: Partial<ICertificate>): Partial<ICertificate> {
    return {
      ...this.defaultProps,
      ...(customAttrs || {}),
    };
  }

  isCertificateNameUniq(certificate: ICertificate, name: string): Observable<boolean> {
    const url = ['/a/certificates/', certificate.id ? certificate.id + '/' : '', 'unique/'].join('');

    return this.http.get<IUniqResponse>(url, { params: { name: name.trim() } }).pipe(
      map((response) => {
        return response.unique;
      }),
    );
  }

  adjustFontStyle(certificateField) {
    /* eslint-disable no-bitwise */
    if (certificateField.fontStyleOptions) {
      certificateField.fontStyle = 0;

      if (certificateField.fontStyleOptions.bold) {
        certificateField.fontStyle |= 1;
      }

      if (certificateField.fontStyleOptions.italic) {
        certificateField.fontStyle |= 2;
      }

      if (certificateField.fontStyleOptions.strikethrough) {
        certificateField.fontStyle |= 8;
      }

      if (certificateField.fontStyleOptions.underline) {
        certificateField.fontStyle |= 4;
      }
    } else {
      Object.assign(certificateField, {
        fontStyleOptions: {
          bold: (certificateField.fontStyle & 1) !== 0,
          italic: (certificateField.fontStyle & 2) !== 0,
          strikethrough: (certificateField.fontStyle & 8) !== 0,
          underline: (certificateField.fontStyle & 4) !== 0,
        },
      });
    }

    return certificateField;
  }

  getFields(): Observable<ICertificateFieldDetail[]> {
    return this.getOptions().pipe(
      map((options) => {
        return options.fields;
      }),
    );
  }

  getOptions(): Observable<ICertificateOptions> {
    return this.http.get<ICertificateOptions>(CertificatesEndpoints.OPTIONS, {
      context: new HttpContext().set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE),
    });
  }

  getDataTypeFormats(): Observable<{ [index: string]: IDataTypeFormat[] }> {
    return this.getOptions().pipe(
      map((options) => {
        return _.groupBy(options.dataTypeFormats, 'dataTypeId');
      }),
    );
  }
}
