<div class="flex">
  <div class="flex-row md-margin-bottom" *ngIf="mobileView">
    <div class="flex-col flex-col-grow">
      <search-mobile-action-panel-dep
        class="catalog-mobile-filters"
        [filters]="filters"
        [hideExport]="true"
        [viewMode]="'button'"
        [customFilters]="true">
      </search-mobile-action-panel-dep>
    </div>
  </div>

  <div class="flex-row hidden-xs md-margin-bottom" *ngIf="!mobileView">
    <div class="flex-col md-margin-right">
      <button type="button" class="btn btn-outline" (click)="toggleShowFilter()" style="min-width: 81px">
        <span class="fa fa-filter" aria-hidden="true"></span>
        <span>Filters</span>
      </button>
    </div>
    <div class="flex-col flex-col-12x catalog-filters">
      <div class="btn-group btn-block" ngbDropdown>
        <button id="searchFilters" type="button" class="btn btn-default btn-block" ngbDropdownToggle>
          {{ sort.name }}
          <span class="fa fa-caret-down"></span>
        </button>

        <div ngbDropdownMenu>
          <span *ngFor="let sortTerm of sortTerms">
            <a (click)="applySort(sortTerm)" href="#" ngbDropdownItem>{{ sortTerm.name }}</a>
          </span>
        </div>
      </div>
    </div>

    <div class="flex-col flex-col-grow"></div>
  </div>
</div>

<div class="catalog-filters hidden-xs md-margin-top" *ngIf="showFilters && !mobileView">
  <div *ngFor="let filter of visibleFilters">
    <search-filter-view-dep [filter]="filter"></search-filter-view-dep>
  </div>
</div>

<div class="md-margin-top md-margin-bottom" *ngIf="!mobileView">
  <selected-search-filters-dep
    [filter]="f"
    *ngFor="let f of filters?.ui"
    class="label-list"></selected-search-filters-dep>
  <div>
    <a href="#" title="Clear All" (click)="clearAll()" class="inline-block" *ngIf="hasSelectedItems()"> Clear All </a>
  </div>
</div>
