import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsUtils } from 'core/utils';
import { Observable } from 'rxjs';
import { IUserCourseSession } from '../models/course-session.models';

enum CourseSessionEndpoints {
  GET = '/a/course/:courseId/sessions/:sessionId/',
  QUERY = '/a/course/:courseId/sessions/',
}

type ParamOptions = { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> };

export interface ICourseSessionQueryParams extends ParamOptions {
  courseId: number | string;
  permitted_for: string;
  filters: string;
  page: number;
  rows: number;
}

export interface ICourseSessionQueryResponse {
  items: IUserCourseSession[];
  count: number;
}

@Injectable()
export class CourseSessionService {
  constructor(private http: HttpClient) {}

  get(courseId: number, sessionId: number, permittedFor?: string[]): Observable<IUserCourseSession> {
    let params = new HttpParams();

    if (permittedFor) {
      params = params.set('permitted_for', permittedFor.join(','));
    }

    return this.http.get<IUserCourseSession>(ElmsUtils.formatUrl(CourseSessionEndpoints.GET, { courseId, sessionId }), {
      params,
    });
  }

  query(params: ICourseSessionQueryParams): Observable<ICourseSessionQueryResponse> {
    return this.http.get<ICourseSessionQueryResponse>(
      ElmsUtils.formatUrl(CourseSessionEndpoints.QUERY, { courseId: params.courseId }),
      { params: new HttpParams({ fromObject: params }) },
    );
  }
}
