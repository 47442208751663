import { Component, Input } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { GlobalConfig } from 'core/environment';
import _ from 'lodash';
import { UserCourseService } from 'modules/course-registrations/services/user-course.service';
import { CourseService } from 'modules/course/common/services/course.service';
import { BaseCourseTileComponent } from 'modules/course/views/tiles/components/base-course-tile.component';

@Component({
  standalone: false,
  selector: 'horizontal-course-tile-modern',
  templateUrl: './horizontal-course-tile.component.html',
})
export class HorizontalCourseTileComponent extends BaseCourseTileComponent {
  static readonly selector = 'horizontalCourseTileModern';

  @Input() smallView = false;
  @Input() disabled = false;
  @Input() hideRegManagement = false;
  @Input() hideBookmark = false;

  constructor(
    globalConfig: GlobalConfig,
    window: Window,
    courseService: CourseService,
    userCourseService: UserCourseService,
    currentUser: CurrentUserService,
  ) {
    super(globalConfig, window, courseService, userCourseService, currentUser);
  }

  shouldLoadUserCourse(): boolean {
    return _.isEmpty(this.userCourse);
  }
}
