import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LearningObjectRegistrationWorkflowService } from 'modules/course-registrations/services/learning-object-registration-workflow.service.ajs-upgraded-provider';
import { TrainingService } from 'modules/course-registrations/services/training.service.ajs-upgraded-provider';
import { Subject } from 'rxjs';

@Component({
  standalone: false,
  selector: 'course-content-view',
  templateUrl: './course-content-view.component.html',
})
export class CourseContentViewComponent implements OnInit {
  @Input() workflow: LearningObjectRegistrationWorkflowService;
  @Input() type: string;
  @Output() finished = new EventEmitter<void>();
  @Input() wrapped: string;
  @Input() playerActions: any;
  @Output() closed = new EventEmitter<void>();
  @Output() launch = new EventEmitter<string>();
  @Input() closeSubject: Subject<void>;

  @Input() quizRetake: Subject<{ resultId: number; skipStartStep?: boolean }>;
  @Output() retake: EventEmitter<string> = new EventEmitter<string>();

  usePlayer: 'internal' | 'external' = 'internal';

  constructor(private trainingService: TrainingService) {}

  ngOnInit() {
    this.usePlayer = this.trainingService.isInternalContentPlayer(this.workflow.registration.course)
      ? 'internal'
      : 'external';
  }

  handleRetake(quizType: string) {
    this.retake.emit(quizType);
  }

  finishHandler() {
    this.finished.emit();
  }

  onPLayerClose() {
    this.closed.emit();
  }

  launchHandler(action: string) {
    this.launch.emit(action);
  }
}
