import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CourseRegistrationCommonModule } from 'modules/course-registrations/course-registration-common.module';
import { CourseCommonModule } from 'modules/course/common/course-common.module';
import { HorizontalCourseTileComponent } from 'modules/course/views/tiles/components/horizontal-course-tile.component';
import { SimpleCourseTileComponent } from 'modules/course/views/tiles/components/simple-course-tile.component';

@NgModule({
  imports: [UIRouterModule, LmsComponentsModule, CommonModule, CourseCommonModule, CourseRegistrationCommonModule],
  declarations: [HorizontalCourseTileComponent, SimpleCourseTileComponent],
  exports: [HorizontalCourseTileComponent, SimpleCourseTileComponent],
})
export class CourseTilesModule {}
