import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsUtils } from 'core/utils';
import {
  ICourseRegistration,
  ICourseRegistrationPayload,
} from 'modules/course-registrations/models/course-registration.model';
import { Observable } from 'rxjs';

enum CourseRegistrationActionEndpoints {
  EXECUTE = '/a/course_details/:courseId/registration/',
}

@Injectable()
export class CourseRegistrationActionService {
  constructor(private http: HttpClient) {}
  execute(courseId: number, payload: ICourseRegistrationPayload): Observable<ICourseRegistration> {
    return this.http.post<ICourseRegistration>(
      ElmsUtils.formatUrl(CourseRegistrationActionEndpoints.EXECUTE, { courseId }),
      payload,
    );
  }
}
