import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { GlobalConfig } from 'core/environment';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { TrainingService } from 'modules/course-registrations/services/training.service.ajs-upgraded-provider';
import { ICourseDetails } from 'modules/course/common/models/course.model';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { CourseService } from 'modules/course/common/services/course.service';
import { NextStepPromptComponent } from 'modules/course/player/components/modal/next-step-prompt.component';
import { IPlayerEvent, IPlayerStep, Player } from 'modules/course/player/services/player.service';
import { quizEnums } from 'modules/quiz';
import { Subject, Unsubscribable, fromEvent, map, merge } from 'rxjs';

@Component({
  standalone: false,
  selector: 'course-content-player',
  templateUrl: './course-content-player.component.html',
})
export class CourseContentPlayerComponent implements OnInit, OnDestroy {
  @Input() course: ICourseDetails;
  @Input() registration: ILearningObjectRegistration;
  @Input() hideRetakePostAssessment?: boolean;
  @Input() current_bls_id?: string;
  @Input() contentType: string;

  closeSubject = new Subject<void>();

  player?: Player;
  contentMenuOpen = false;
  leftMenuExpanded = false;
  _leftMenuEnabled = false;
  browserIsOnline = true;
  userIsAdmin = false;

  blsCourse: ICourseDetails | null = null;

  hiding = false;

  private sessionExpired = false;
  private subscribers: Unsubscribable[] = [];
  private currentUser = this.userService.get();

  constructor(
    private globalConfig: GlobalConfig,
    private userService: CurrentUserService,
    private window: Window,
    private backUrlService: BackUrlService,
    public trainingService: TrainingService,
    private courseService: CourseService,
    private uiRouterGlobals: UIRouterGlobals,
    private ngbModal: NgbModal,
    private stateService: StateService,
    private injector: Injector,
  ) {}

  set leftMenuEnabled(value: boolean) {
    if (!this._leftMenuEnabled && value) {
      this.leftMenuExpanded = true;
    }

    this._leftMenuEnabled = value;
  }

  get leftMenuEnabled() {
    return this._leftMenuEnabled;
  }

  ngOnInit() {
    this.userIsAdmin = this.currentUser.checkPermission('courseRegistration.edit');

    this.window.onmessage = (event) => {
      if (event.data === 'closePlayer') {
        this.hide();
      }

      if (event.data === 'sessionExpired') {
        this.sessionExpired = true;
      }
    };

    this.loadBlsCourse();

    this.subscribers.push(
      merge(
        fromEvent(this.window, 'online').pipe(map(() => true)),
        fromEvent(this.window, 'offline').pipe(map(() => false)),
      ).subscribe((status) => {
        this.browserIsOnline = status;
      }),
    );
  }

  ngOnDestroy() {
    this.subscribers.forEach((subscriber) => subscriber.unsubscribe());
    this.subscribers.length = 0;
  }

  showClose(): boolean {
    return this.uiRouterGlobals.params.showClose === undefined || this.uiRouterGlobals.params.showClose;
  }

  hide() {
    if (!this.hiding) {
      this.closeSubject.next();

      if (this.hideNextButton() && this.player.getNextAvailableStep(false)) {
        const injector = Injector.create({
          parent: this.injector,
          providers: [{ provide: Player, useValue: this.player }],
        });

        this.ngbModal.open(NextStepPromptComponent, {
          injector,
        });
      } else {
        this.closePlayer();
      }
    }
  }

  hideNextButton(): boolean {
    return (
      !this.globalConfig.settings.coursePlayer?.hideNextStepBtnFor?.length ||
      this.globalConfig.settings.coursePlayer?.hideNextStepBtnFor?.includes(this.course.format)
    );
  }

  showSteps(): boolean {
    return (
      this.globalConfig.settings.coursePlayer?.showSteps &&
      this.registration &&
      this.player?.steps?.length > 1 &&
      this.trainingService.canShowContent(this.registration)
    );
  }

  toggleContentMenu() {
    this.contentMenuOpen = !this.contentMenuOpen;
  }

  getVisibleSteps(): IPlayerStep[] {
    return this.player?.steps?.filter((step) => step.visible || this.player.currentStep?.name === step.name) || [];
  }

  handlePlayerEvent(event: IPlayerEvent) {
    if (event.event === 'courseInterrupted') {
      this.hide();
    }
  }

  isQuizStep(): boolean {
    return this.player?.currentStep && !this.player.currentStep.contentStep;
  }

  nonContentStepIsAvailable(): boolean {
    const nextStep = this.player?.getNextAvailableStep(true);

    if (!nextStep) {
      return false;
    }

    const nextStepVisible = !nextStep.contentStep && !nextStep.completed && nextStep.visible;
    const nextStepIntended =
      !nextStep.intendedFor ||
      (nextStep.intendedFor === quizEnums.quizIntendedFor.learners &&
        this.registration.userId === this.currentUser.id) ||
      (nextStep.intendedFor === quizEnums.quizIntendedFor.administrators && this.userIsAdmin);

    return (
      nextStepVisible &&
      nextStepIntended &&
      !this.showSteps() &&
      this.course.completionTypeId !== 1 &&
      this.course.completionTypeId !== 2 &&
      !!this.player.currentStep
    );
  }

  private loadBlsCourse() {
    if (this.current_bls_id) {
      this.subscribers.push(
        this.courseService.get(this.current_bls_id, { allowSystemFormat: true }).subscribe((blsCourse) => {
          this.blsCourse = blsCourse;
        }),
      );
    }
  }

  private closePlayer() {
    this.hiding = true;

    if (this.sessionExpired) {
      this.stateService.go('logout');
    } else {
      this.backUrlService.goBack();
    }
  }
}
