<button
  type="button"
  (click)="onBookmarkClick(); $event.stopPropagation(); $event.preventDefault()"
  class="tw-group tw-inline-flex tw-items-center tw-gap-2 tw-rounded tw-text-brand-9 focus:tw-outline-none"
  [ngClass]="
    playCourseService.saveForLaterAvailable(course, userCourse)
      ? 'hover:tw-text-brand-12 focus-visible:tw-text-brand-12 focus-visible:tw-outline-brand-7'
      : 'hover:tw-text-danger-11 focus-visible:tw-text-danger-11 focus-visible:tw-outline-danger-7'
  "
  *ngIf="!isEmptyObject(userCourse)"
  [title]="playCourseService.saveForLaterAvailable(course, userCourse) ? 'Bookmark course' : 'Remove bookmark'"
  [trackLinkClick]="trackingName">
  <i
    [class]="
      playCourseService.saveForLaterAvailable(course, userCourse) ? 'fa-regular fa-bookmark' : 'fa-solid fa-bookmark'
    "></i>
  <ng-container *ngIf="playCourseService.saveForLaterAvailable(course, userCourse); else removeBookmark">
    Bookmark
  </ng-container>
  <ng-template #removeBookmark>
    <span class="tw-hidden group-hover:tw-inline group-focus-visible:tw-inline">Remove bookmark</span>
    <span class="tw-inline group-hover:tw-hidden group-focus-visible:tw-hidden">Bookmarked</span>
  </ng-template>
</button>
