<div class="login-container">
  <div class="center-block">
    <div class="panel panel-default standard-margin-top">
      <div class="panel-body">
        <span>
          Access denied. Please contact {{ siteSetting?.siteShortName }} technical support by email at
          <a title="Mail to Technical Support" href="mailto:{{ siteSetting?.supportEmail }}">{{
            siteSetting?.supportEmail
          }}</a>
        </span>
      </div>
    </div>
  </div>
</div>
