<div *ngIf="!emailTemplate">
  <div class="load-spinner"></div>
</div>

<div *ngIf="!!emailTemplate">
  <h4><span [innerHtml]="emailTemplate.subject"></span></h4>

  <div class="row">
    <div class="col-xs-12">
      <div [innerHtml]="emailTemplate.body"></div>
    </div>
  </div>
</div>
