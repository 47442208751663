import { ICourseAbstract } from 'modules/course/common/models/course.model';
import { ICourseCredit } from 'modules/course/credits/models/course-credit.models';

export enum CollectionItemType {
  COURSE = 1,
  SECTION = 2,
  BLS_ROOT = 3,
}

export interface ICollectionItemBase {
  type: number;
  id: number;
  order: number;
  name: string;
  items: ICollectionItemBase[];
}

export interface ICollectionCourseItem extends ICollectionItemBase, Omit<ICourseAbstract, 'id'> {
  name: string;
  description: string;
  required: boolean;
  randomOrder: boolean;
  url: string;
  status_id: number;
  verified: boolean;
  spent: number;
  length: number;
  completion_date: string;
  provider_course_no: string;
  courseGradePoints: number;
  courseGradePercentage: number;
  current_bls_id: number;
  price: number;
  expirationDate: string;
  publishDate: string;
  rating: number;
  ratingCount: number;
  registration: any;
  formatTypeId: number;
  showOnlyInBls: boolean;
  creditTypes: ICourseCredit[];
}

export function isCollectionCourse(item: ICollectionItemBase): item is ICollectionCourseItem {
  return item.type === CollectionItemType.COURSE;
}

export interface ICollectionSectionItem extends ICollectionItemBase {
  name: string;
  description: string;
  required?: boolean | null;
  completionRequirement?: boolean | null;
  randomOrder: boolean;
}

export function isCollectionSection(item: ICollectionItemBase): item is ICollectionSectionItem {
  return item.type === CollectionItemType.SECTION;
}

export type IBlsRoot = ICollectionItemBase;

export function isBlsRoot(item: ICollectionItemBase): item is ICollectionCourseItem {
  return item.type === CollectionItemType.BLS_ROOT;
}
