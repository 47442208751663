<div *ngIf="loading">
  <div class="load-spinner" role="status" aria-live="polite" aria-label="Loading"></div>
</div>

<div *ngIf="!loading">
  <!-- Existing Account Incline -->
  <div *ngIf="status === 'existing_account_incline' && currentSiteSetting && ssoData">
    <h3 class="mt-0 mb-15">Do you have a {{ currentSiteSetting.siteShortName || 'LMS' }} account?</h3>
    <p class="text-muted">You are logging in with account credentials from {{ ssoConfig?.value?.name }}</p>

    <div class="d-flex gap-10 flex-direction-column">
      <label class="font-normal">
        <input type="radio" [(ngModel)]="accountExists" name="accountExists" [value]="true" />
        Yes
      </label>
      <label class="font-normal">
        <input type="radio" [(ngModel)]="accountExists" name="accountExists" [value]="false" />
        No
      </label>
    </div>

    <div class="row mt-10">
      <div class="col-sm-12 text-right">
        <button
          type="button"
          class="btn btn-primary"
          (click)="goToSsoStep()"
          title="Continue"
          [disabled]="accountExists === undefined">
          Continue
        </button>
      </div>
    </div>
  </div>

  <!-- Existing Account SSO -->
  <div *ngIf="status === 'existing_account_sso' && ssoData">
    <h3 class="mt-0 mb-15">Link your {{ currentSiteSetting.siteShortName || 'LMS' }} account</h3>
    <p class="text-muted">
      {{ signInWording.SignIn }} to link your {{ currentSiteSetting.siteShortName || 'LMS' }} account to your SSO
      provider. You only need to do it once.
    </p>
    <form (ngSubmit)="linkAccount(credentials)" #loginForm="ngForm" novalidate>
      <div
        class="form-group"
        [ngClass]="{
          'has-error': (loginForm.controls.username?.dirty || formSubmitted) && loginForm.controls.username?.invalid,
        }">
        <label for="loginField" class="control-label">
          {{ currentSiteSetting.siteShortName || 'LMS' }} {{ signInWording.username }}</label
        >
        <input
          type="text"
          id="loginField"
          placeholder="{{ (currentSiteSetting.siteShortName || 'LMS') + ' account ' + signInWording.username }}"
          class="form-control"
          [(ngModel)]="credentials.login_name"
          name="username"
          #username="ngModel"
          required
          autocomplete="off" />
        <div class="margin-top text-danger" *ngIf="(username.dirty || formSubmitted) && username.invalid">
          <span>Please enter your {{ signInWording.username }}</span>
        </div>
      </div>

      <div
        class="form-group"
        [ngClass]="{
          'has-error':
            (loginForm.controls.password?.dirty || formSubmitted) &&
            username.valid &&
            loginForm.controls.password?.invalid,
        }">
        <label for="passwordField" class="control-label"
          >{{ currentSiteSetting.siteShortName || 'LMS' }} Password</label
        >
        <input
          type="password"
          id="passwordField"
          placeholder="{{ (currentSiteSetting.siteShortName || 'LMS') + ' account password' }}"
          class="form-control"
          [(ngModel)]="credentials.password"
          name="password"
          #password="ngModel"
          required
          autocomplete="off" />
        <div
          class="margin-top text-danger"
          *ngIf="(password.dirty || formSubmitted) && username.valid && password.invalid">
          <span>Please enter your password</span>
        </div>
      </div>

      <div class="row mt-10">
        <div class="col-sm-12 text-right">
          <button type="button" class="btn btn-default btn-outline sm-margin-right" title="Go back" (click)="reset()">
            Back
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            title="{{ signInWording.SignIn }}"
            [disabled]="loginForm.invalid || processing"
            [ladda]="processing">
            {{ signInWording.SignIn }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <!-- New Account SSO -->
  <div *ngIf="status === 'new_account_sso' && ssoData">
    <h3 class="mt-0 mb-15">Create a new {{ currentSiteSetting.siteShortName || 'LMS' }} account</h3>
    <div *ngIf="ssoData.allow_create">
      <p class="text-muted">
        You will need to create a new {{ currentSiteSetting.siteShortName || 'LMS' }}
        account to link to your SSO provider. You only need to do it once.
      </p>
    </div>
    <div *ngIf="!ssoData.allow_create">
      The current configuration doesn't allow the creation of new
      {{ currentSiteSetting.siteShortName || 'LMS' }} accounts
    </div>

    <div class="row mt-10">
      <div class="col-sm-12 text-right">
        <button type="button" class="btn btn-default btn-outline sm-margin-right" title="Go back" (click)="reset()">
          Back
        </button>

        <button
          type="button"
          class="btn btn-primary"
          [disabled]="processing"
          (click)="linkAccount()"
          [ladda]="processing"
          *ngIf="ssoData.allow_create"
          title="Create new account">
          Create new account
        </button>
      </div>
    </div>
  </div>

  <!-- Error State -->
  <div *ngIf="currentSiteSetting && (status === 'error' || (!ssoData && !loading))">
    <h3 class="mt-0 mb-15">Access denied</h3>
    <div [innerHtml]="error"></div>
    <div *ngIf="!ssoData && !loading">Sign-in data are missing</div>

    <div class="row mt-10">
      <div class="col-sm-12 text-right">
        <button
          type="button"
          class="btn btn-default btn-outline sm-margin-right"
          title="Go back"
          (click)="reset()"
          *ngIf="ssoConfig">
          Back
        </button>

        <a
          title="Mail to technical support"
          class="btn btn-primary"
          *ngIf="currentSiteSetting.supportEmail"
          href="mailto:{{ currentSiteSetting.supportEmail }}"
          target="_blank"
          >Contact support</a
        >
      </div>
    </div>
  </div>
</div>
