import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { StaticAlertsService } from 'modules/alerts/services/static-alerts.service';
import { AlertType } from '../models/alert.model';
import { ImpersonationService } from 'modules/admin/user/services/impersonation.service';

@Component({
  standalone: false,
  selector: 'alert-messages',
  templateUrl: './alert-messages.component.html',
})
export class AlertMessagesComponent implements OnInit {
  @Output() hasAlerts?: EventEmitter<boolean> = new EventEmitter<boolean>();
  readonly alertType = AlertType;

  constructor(
    public staticAlertsService: StaticAlertsService,
    private impersonationService: ImpersonationService,
  ) {}

  ngOnInit() {
    // impersonationService user only for initialization of alert. It works before that way.

    this.staticAlertsService.messages$.subscribe((messages) => {
      this.hasAlerts.next(!!_.filter(messages, 'visibility').length);
    });
  }
}
