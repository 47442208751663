import { Component, OnInit, inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ISearchFilterTypes } from 'app/deprecated/search/models/search-filters.models';
import { CurrentUserService } from 'core/authorization';
import { GlobalConfig } from 'core/environment/global-config.service.ajs-upgraded-provider';
import { Unsubscribable, finalize, tap } from 'rxjs';
import {
  BaseFilterService,
  getCalendarFilters,
} from '../../../deprecated/search/services/filters-old/search-filtering.service.ajs-upgraded';
import {
  TrainSearchFilterUpgradedProvider,
  getTrainSearchFilters,
} from '../../../deprecated/search/services/filters-old/train-search-filtering.service.ajs-upgraded';
import { ICalendarDataModes } from '../models/events.model';

@Component({
  standalone: false,
  selector: 'calendar',
  templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit {
  filters: ISearchFilterTypes;
  template: string;
  showRegistrationStatus: boolean;
  mode: ICalendarDataModes;

  readonly user = this.currentUserService.get();

  private requestSubscriber?: Unsubscribable;

  constructor(
    private globalConfig: GlobalConfig,
    private stateService: StateService,
    private currentUserService: CurrentUserService,
  ) {
    if (this.globalConfig.settings.fullCalendar.filterProvider === 'TrainSearchFilterUpgradedProvider') {
      const provider = inject<TrainSearchFilterUpgradedProvider>(TrainSearchFilterUpgradedProvider);

      this.requestSubscriber = getTrainSearchFilters(provider)
        .pipe(
          tap((items) => (this.filters = items)),
          finalize(() => {
            this.requestSubscriber?.unsubscribe();
            delete this.requestSubscriber;
          }),
        )
        .subscribe();
    }

    if (this.globalConfig.settings.fullCalendar.filterProvider === 'BaseFilterService') {
      const provider = inject<BaseFilterService>(BaseFilterService);

      this.filters = getCalendarFilters(provider, this.user.anonymous, this.stateService);
    }
  }

  ngOnInit() {
    this.mode = this.globalConfig.settings.fullCalendar.mode;
    this.template = this.globalConfig.settings.fullCalendar.template;
    this.showRegistrationStatus = this.globalConfig.settings.fullCalendar.showRegistrationStatus;
  }
}
