import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { StateService, Transition } from '@uirouter/core';
import { CourseRegistrationStatus } from 'modules/course-registrations/models/course-registration-status.model';
import { CourseRegistrationService } from 'modules/course-registrations/services/course-registration.service';
import { LearningObjectRegistrationService } from 'modules/course-registrations/services/learning-object-registration.service.ajs-upgraded-provider';
import { ICourseAbstract } from 'modules/course/common/models/course.model';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { CourseService } from 'modules/course/common/services/course.service';
import { CollectionPlayerComponent } from 'modules/course/player/collection/components/collection-player.component';
import { CourseContentPlayerComponent } from 'modules/course/player/view/course-content-player.component';
import { CourseComponentsService } from 'modules/course/services/course-components.service.ajs-upgraded-provider';
import { lastValueFrom, map, switchMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

export const coursePlayerRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'view.collectionPlayer',
    url: '/collection/{collectionId}?courseId',
    component: CollectionPlayerComponent,
    params: {
      collectionId: null,
      courseId: {
        value: null,
        dynamic: true,
      },
    },
    data: {
      label: 'Collection',
      availableFor: ['regularUser', 'admin'],
      preventOpenByDirectLink: true,
    },
    resolve: [
      {
        provide: 'collection',
        deps: [CourseService, Transition],
        useFactory: (courseService: CourseService, transition: Transition) => {
          return lastValueFrom(courseService.get(transition.params().collectionId, { allowSystemFormat: true }));
        },
      },
      {
        provide: 'collectionRegistration',
        deps: ['collection', Transition, StateService, LearningObjectRegistrationService],
        useFactory: (
          collection: ICourseAbstract,
          transition: Transition,
          stateService: StateService,
          learningObjectRegistration: LearningObjectRegistrationService,
        ) => {
          return learningObjectRegistration.get(collection, null).then((registration: ILearningObjectRegistration) => {
            if (
              [
                CourseRegistrationStatus.preAssessmentPending,
                CourseRegistrationStatus.inProgress,
                CourseRegistrationStatus.assessmentPending,
                CourseRegistrationStatus.evaluationPending,
              ].includes(registration.status_id) ||
              (!registration.status_id && registration.completedRegistration)
            ) {
              return registration;
            } else {
              transition.abort();

              if (transition.params().courseId) {
                stateService.go('view.course_content', {
                  courseId: transition.params().courseId,
                  current_bls_id: transition.params().collectionId,
                });
              }
            }
          });
        },
      },
    ],
  },
  {
    name: 'view.course_content',
    url: '/course/{courseId}/content/{type}?registrationId&current_bls_id',
    component: CourseContentPlayerComponent,
    params: {
      courseId: null,
      type: null,
      registrationId: null,
      current_bls_id: null,
    },
    data: {
      label: 'Course',
      availableFor: ['regularUser', 'admin'],
      preventOpenByDirectLink: true,
    },
    resolve: [
      {
        provide: 'course',
        deps: [CourseService, Transition, CourseComponentsService],
        useFactory: (
          courseService: CourseService,
          transition: Transition,
          courseComponentsService: CourseComponentsService,
        ) => {
          return lastValueFrom(
            courseService.get(transition.params().courseId, { allowSystemFormat: true }).pipe(
              switchMap((course) => {
                return fromPromise(courseComponentsService.initSessions(course.id)).pipe(
                  map((sessions) => {
                    course.sessions = sessions;

                    return course;
                  }),
                );
              }),
            ),
          );
        },
      },
      {
        provide: 'registration',
        deps: ['course', Transition, CourseRegistrationService, LearningObjectRegistrationService],
        useFactory: (
          course: ICourseAbstract,
          transition: Transition,
          courseRegistrationService: CourseRegistrationService,
          learningObjectRegistration: LearningObjectRegistrationService,
        ) => {
          if (transition.params().registrationId) {
            return lastValueFrom(
              courseRegistrationService.get(transition.params().registrationId).pipe(
                map((registration) => {
                  return learningObjectRegistration.getFromReg(registration, course);
                }),
              ),
            );
          } else {
            return learningObjectRegistration.get(course, null);
          }
        },
      },
      {
        provide: 'current_bls_id',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().current_bls_id;
        },
      },
      {
        provide: 'contentType',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().type;
        },
      },
    ],
  },
];
