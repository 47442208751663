<section [hidden]="courses.length === 0" class="tw-flex tw-flex-col tw-gap-3">
  <h2 class="twc-h2">Recently Updated</h2>
  <div class="twc-grid-scrollable">
    <simple-course-tile
      *ngFor="let course of courses"
      [course]="course"
      [showMandatory]="true"
      [trackingName]="trackingName">
    </simple-course-tile>
  </div>
</section>
