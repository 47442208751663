<div class="fc fc-media-screen fc-direction-ltr fc-theme-standard 1">
  <div class="fc-toolbar fc-header-toolbar">
    <div class="fc-toolbar-chunk">
      <div class="fc-button-group">
        <button type="button" class="fc-prev-button fc-button fc-button-primary" (click)="prev()">
          <span class="fc-icon fc-icon-chevron-left"></span>
          <span class="sr-only">Previous</span>
        </button>
        <button type="button" class="fc-next-button fc-button fc-button-primary" (click)="next()">
          <span class="fc-icon fc-icon-chevron-right"></span>
          <span class="sr-only">Next</span>
        </button>
      </div>
      <button type="button" class="fc-today-button fc-button fc-button-primary" (click)="today()">Today</button>
      <input
        hidden
        name="ngDate"
        ngbDatepicker
        positionTarget=".fc-myCustomButton-button"
        container="body"
        aria-label="Open calendar"
        [(ngModel)]="selectedDate"
        (dateSelect)="onDatePickerSelected()"
        #ngDate="ngbDatepicker" />

      <button type="button" (click)="ngDate.toggle()" class="fc-myCustomButton-button fc-button fc-button-primary">
        <span class="fc-icon fc-icon-datePicker"></span>
        <span class="sr-only">Open calendar</span>
      </button>
    </div>
    <div class="fc-toolbar-chunk">
      <h2 class="fc-toolbar-title">
        {{ displayTitle }}
      </h2>
    </div>
    <div class="fc-toolbar-chunk">
      <div class="fc-button-group">
        <button
          type="button"
          class="fc-dayGridMonth-button fc-button fc-button-primary"
          [ngClass]="{ 'fc-button-active': calendarCurrentState.intervalUnit === 'month' }"
          (click)="computeMonthRange()">
          Month
        </button>
        <button
          type="button"
          class="fc-timeGridWeek-button fc-button fc-button-primary fc-button-active"
          [ngClass]="{ 'fc-button-active': calendarCurrentState.intervalUnit === 'week' }"
          (click)="computeWeekRange()">
          Week
        </button>
        <button
          type="button"
          class="fc-timeGridDay-button fc-button fc-button-primary"
          [ngClass]="{ 'fc-button-active': calendarCurrentState.intervalUnit === 'day' }"
          (click)="computeDayRange()">
          Day
        </button>
      </div>
    </div>
    <!--    <div class="fc-clear"></div>-->
  </div>
</div>

<div *ngIf="!events?.length && processing">
  <div class="load-spinner"></div>
</div>

<div *ngIf="events?.length">
  <div class="row">
    <div class="col-xs-12">
      <ul class="content-list content-list-striped">
        <!-- List header -->
        <li class="content-list-header">
          <div class="flex">
            <div class="flex-row">
              <div class="flex-col flex-col-3x" *ngIf="user.id === eventsUser.id"></div>

              <div class="flex-col flex-col-grow">
                <div class="header-sort-link">
                  <strong>Course Title</strong>
                </div>
              </div>

              <div class="flex-col flex-col-22x hidden-xs">
                <div class="header-sort-link">
                  <strong>Start Time</strong>
                </div>
              </div>

              <div class="flex-col flex-col-3x" *ngIf="user.id === eventsUser.id"></div>
            </div>
          </div>
        </li>

        <!-- END List result -->
        <li class="content-list-item" *ngFor="let item of events" [ngClass]="{ 'is-open': item.contentOpen }">
          <div class="flex">
            <div class="flex-row">
              <!-- action icon -->
              <div class="flex-col flex-col-3x" *ngIf="user.id === eventsUser.id">
                <span *ngFor="let l of item.labels" [ngClass]="l.badgeClassName" class="badge">
                  <span [innerHTML]="l.key"></span>
                </span>
              </div>

              <!-- title -->
              <div class="flex-col flex-col-grow">
                <div class="line-clamp">
                  <a
                    [href]="item.url"
                    [innerHTML]="item.name"
                    [title]="item.title"
                    [hidden]="fetching"
                    [ariaLabel]="item.name"></a>
                  <span
                    class="btn-link disabled-link underline"
                    aria-disabled="true"
                    [ariaLabel]="item.name"
                    [hidden]="!fetching"
                    [innerHTML]="item.name"></span>
                </div>
              </div>

              <!-- time -->
              <div class="flex-col flex-col-22x hidden-xs" [ngClass]="{ 'disabled-text': fetching }">
                {{ item.displayDate }}
                <div>
                  <time-indicator [date]="item.start"></time-indicator>
                  -
                  <time-indicator [date]="item.end"></time-indicator>
                </div>
              </div>

              <div class="flex-col flex-col-3x" *ngIf="user.id === eventsUser.id">
                <registration-menu
                  [course]="item.course"
                  class="dropdown-list-left"
                  [showDetails]="true"
                  [showHistory]="true"
                  [showRemoveAction]="false"
                  *ngIf="!eventsUser.anonymous">
                </registration-menu>
              </div>
            </div>
          </div>

          <div class="flex" *ngIf="item.location || item.sponsor" [ngClass]="{ 'disabled-text': fetching }">
            <div class="flex-row">
              <div class="flex-col flex-col-3x"></div>
              <div class="flex-col flex-col-3x"></div>
              <div class="flex-col flex-col-grow">
                <div class="content-list-header" *ngIf="item.sponsor">
                  <div><span class="header-sort-link">Sponsor/Offerer</span></div>
                  <div>
                    <span>{{ item.sponsor }}</span>
                  </div>
                </div>
              </div>
              <div class="flex-col flex-col-22x hidden-xs">
                <div class="content-list-header" *ngIf="item.location">
                  <div><span class="header-sort-link">Location</span></div>
                  <div>
                    <span>{{ item.location }}</span>
                  </div>
                </div>
              </div>
              <div class="flex-col"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
