import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AddTagFn } from '@ng-select/ng-select/lib/ng-select.component';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { IFormOptions } from 'components/forms';
import { ElmsUtils } from 'core/utils';
import { IEmail } from 'modules/emails/models/email.model';
import { IEntityUser } from 'modules/user/models/user.model';

@Component({
  standalone: false,
  selector: 'email-sender',
  templateUrl: './email-sender.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class EmailSenderComponent implements OnInit {
  @Input() email: IEmail;
  @Input() recipientsReadOnly: boolean;
  @Input() allowAddition: boolean;
  @Input() formOptions?: IFormOptions;

  receiveEmails: boolean;
  taggedEmailValid = false;
  allRecipients: Partial<IEntityUser>[] = [];

  addTagPredicate?: AddTagFn;
  searchPredicate?: (term: string, item?: IEntityUser) => boolean;

  constructor(private currentUserService: CurrentUserService) {}

  ngOnInit() {
    this.receiveEmails = this.currentUserService.get().receiveEmails;
    this.allRecipients = [...this.email.recipients];

    if (!this.recipientsReadOnly && this.allowAddition) {
      this.addTagPredicate = (e) => (ElmsUtils.validEmail(e) ? { email: e, tag: true } : null);
    } else if (!this.recipientsReadOnly) {
      this.searchPredicate = (t, i) => `${i.lastName}, ${i.firstName} <${i.email}>`.includes(t);
    }
  }

  removeEmail(email: string) {
    this.allRecipients = this.allRecipients.filter((i) => i.email !== email);
  }

  onSearch(terms: { term: string; items: IEntityUser[] }) {
    if (this.allowAddition) {
      this.taggedEmailValid = ElmsUtils.validEmail(terms.term);
    }
  }
}
