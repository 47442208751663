import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsUtils } from 'core/utils';
import {
  ILearningSeriesUser,
  ILearningSeriesUserSearchResponse,
} from 'modules/admin/learning-series/models/learning-series-users.models';
import { Observable } from 'rxjs';
import { ILearningSeriesStructure } from '../models/learning-series-structure.models';

export enum UserLearningSeriesEndpoints {
  GET = '/a/user/:userId/learning_series/:learningSeriesId/',
  QUERY = '/a/user/:userId/learning_series/',
  STRUCTURE = '/a/user/:userId/learning_series/:learningSeriesId/structure/',
}

@Injectable()
export class UserLearningSeriesService {
  constructor(private http: HttpClient) {}

  public get(learningSeriesId: number, userId: number, permissions?: string[]): Observable<ILearningSeriesUser> {
    let params = new HttpParams();

    if (permissions) {
      params = params.set('permitted_for', permissions.join(','));
    }

    return this.http.get<ILearningSeriesUser>(
      ElmsUtils.formatUrl(UserLearningSeriesEndpoints.GET, { learningSeriesId, userId }),
      { params: params },
    );
  }

  search(userId: number, permissions?: string[]): Observable<ILearningSeriesUserSearchResponse> {
    let params = new HttpParams();

    if (permissions) {
      params = params.set('permitted_for', permissions.join(','));
    }

    return this.http.get<ILearningSeriesUserSearchResponse>(
      ElmsUtils.formatUrl(UserLearningSeriesEndpoints.QUERY, { userId }),
      { params: params },
    );
  }

  public getStructure(learningSeriesId: number, userId: number): Observable<ILearningSeriesStructure> {
    return this.http.get<ILearningSeriesStructure>(
      ElmsUtils.formatUrl(UserLearningSeriesEndpoints.STRUCTURE, { learningSeriesId, userId }),
    );
  }
}
