import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SecurityService } from 'core/services';
import { IBlogPost } from 'modules/blog/models/blog-post.model';
import { BlogPostService } from 'modules/blog/services/blog-post.service';
import { BlogPostRotatorService } from 'modules/blog/services/blog-rotator.service';
import { Unsubscribable, finalize, tap } from 'rxjs';

@Component({
  standalone: false,
  selector: 'recent-blog-post',
  templateUrl: './recent-blog-post.component.html',
})
export class RecentBlogPostComponent implements OnInit, OnDestroy {
  static readonly selector = 'recentBlogPost';

  @Input() trackingName = '';
  @Input() title!: string;

  post: IBlogPost | null = null;

  readonly isStateAvailable = this.securityService.isStateAvailable;

  private requestSubscriber?: Unsubscribable;

  constructor(
    private securityService: SecurityService,
    private blogPostRotatorService: BlogPostRotatorService,
    private blogPostService: BlogPostService,
  ) {}

  ngOnInit(): void {
    const lastPostId = this.blogPostRotatorService.state.post?.id;

    this.requestSubscriber = (
      lastPostId ? this.blogPostService.get(lastPostId) : this.blogPostRotatorService.nextPost(this.post?.id)
    )
      .pipe(
        tap((post) => this.updatePost(post)),
        finalize(() => this.clearRequestSubscriber()),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.clearRequestSubscriber();
  }

  private clearRequestSubscriber(): void {
    if (this.requestSubscriber) {
      this.requestSubscriber.unsubscribe();
      delete this.requestSubscriber;
    }
  }

  private updatePost(post: IBlogPost | null): void {
    this.post = post;
  }
}
