import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsUtils } from 'core/utils';
import {
  IFeaturedCoursesCategory,
  IFeaturedCoursesLimited,
  IFeaturedCoursesParams,
  IFeaturedGroupCourse,
  IFeaturedUserCourse,
} from 'modules/course/custom-lists/models/featured-courses.model';
import { Observable } from 'rxjs';

enum CourseCustomListServiceEndpoints {
  GET = '/a/groups/:groupId/featured_courses/',
  PUT = '/a/groups/:groupId/featured_courses/',
  USER_COURSES = '/a/user/featured_courses/',
  COURSES = '/a/featured_items/courses/limited/',
  CATEGORIES = '/a/featured_items/course_categories/',
  RECOMMENDED = '/a/advertising/recommendations/courses/',
}

@Injectable()
export class CourseCustomListService {
  constructor(private http: HttpClient) {}

  featuredCourseListByGroup(groupId): Observable<IFeaturedGroupCourse[]> {
    return this.http.get<IFeaturedGroupCourse[]>(
      ElmsUtils.formatUrl(CourseCustomListServiceEndpoints.GET, { groupId }),
    );
  }

  getUserFeaturedCourses(params: IFeaturedCoursesParams): Observable<IFeaturedUserCourse[]> {
    return this.http.get<IFeaturedUserCourse[]>(CourseCustomListServiceEndpoints.USER_COURSES, {
      params,
    });
  }

  getFeaturedCourses(): Observable<IFeaturedCoursesLimited[]> {
    return this.http.get<IFeaturedCoursesLimited[]>(CourseCustomListServiceEndpoints.COURSES);
  }

  getFeaturedCourseCategories(): Observable<IFeaturedCoursesCategory[]> {
    return this.http.get<IFeaturedCoursesCategory[]>(CourseCustomListServiceEndpoints.CATEGORIES);
  }

  getRecommendedCourses(params: IFeaturedCoursesParams) {
    return this.http.get<IFeaturedUserCourse[]>(CourseCustomListServiceEndpoints.RECOMMENDED, {
      params,
    });
  }
}
