<ul class="content-list">
  <li class="content-list-item" *ngFor="let attachment of value">
    <div class="flex flex-vertical-center">
      <div class="flex-row">
        <div class="flex-col flex-col-grow text-truncate">{{ attachment.name }}</div>
        <div class="flex-col flex-col-6x">
          <span class="text-muted">{{ formatFileSize(attachment.size) }}</span>
        </div>

        <div class="flex-col flex-col-3x">
          <button
            type="button"
            class="btn btn-danger btn-xs"
            [ngbTooltip]="'Remove Attachment'"
            [placement]="['left']"
            (clickConfirmation)="removeAttachment(attachment)"
            clickConfirmationMessage="Remove Attachment?">
            <span class="fa fa-times" aria-hidden="true"></span>
            <span class="sr-only">Remove</span>
          </button>
        </div>
      </div>
    </div>
  </li>
</ul>

<div class="sm-margin-top">
  <file-input
    id="attachment"
    [options]="uploadOptions"
    [resetOnSelection]="true"
    [disabled]="disabled || invalid"
    (openTriggerChange)="openTriggerChange($event)"
    (fileSelected)="onFileSelected($event)"></file-input>
  <small class="no-margin-top">Total size should not exceed {{ uploadOptions.maxFileSizeMb }} Mb.</small>
</div>
