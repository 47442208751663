<section class="tw-flex tw-flex-col tw-gap-3" *ngIf="mode === 'list'">
  <div *ngIf="channel">
    <h2 class="twc-h2"><span *ngIf="channel" [innerHtml]="channel.name"></span></h2>
  </div>

  <div *ngIf="posts" class="tw-h-72 tw-overflow-hidden tw-rounded-xl tw-border tw-border-gray-7">
    <div class="tw-h-72 tw-overflow-y-auto">
      <div
        *ngFor="let post of posts"
        [innerHtml]="post.getContent() | linksSelfTarget"
        class="tw-border-b tw-border-gray-7 tw-px-6 tw-py-4 tw-text-xl"></div>
    </div>
  </div>
</section>

<section *ngIf="mode !== 'list'" class="tw-flex tw-flex-col tw-gap-2.5">
  <h2 class="twc-h2" *ngIf="channel">
    <span [innerHtml]="channel.name"></span>
  </h2>

  <div *ngIf="posts" class="hot-topics-tiles">
    <div
      *ngFor="let post of posts"
      [innerHtml]="post.getContent() | linksSelfTarget"
      class="hot-topics-tile tw-rounded-xl"></div>
  </div>
</section>
