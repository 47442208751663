import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { ImpersonationService } from 'modules/admin/user/services/impersonation.service';
import { StaticAlertsService } from 'modules/alerts/services/static-alerts.service';
import { AlertMessagesComponent } from 'modules/alerts/view/alert-messages.component';
import { ImpersonateAlertComponent } from 'modules/alerts/view/impersonate-alert.component';
import { CookieService } from 'ngx-cookie-service';
import { AlertTypesService } from './services/alert-types.service';
import { AlertsService } from './services/alert.service';

@NgModule({
  imports: [LmsComponentsModule, CommonModule, HttpClientModule, UIRouterUpgradeModule],
  declarations: [AlertMessagesComponent, ImpersonateAlertComponent],
  providers: [HttpClient, CookieService, AlertsService, AlertTypesService, StaticAlertsService, ImpersonationService],
  exports: [AlertMessagesComponent],
})
export class AlertsModule {}
