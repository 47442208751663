import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { RecentBlogPostService } from '../../services/recent-blog-posts.service';

@Component({
  standalone: false,
  selector: 'recent-blog-posts',
  templateUrl: './recent-blog-posts.component.html',
})
export class RecentBlogPostsComponent implements OnInit {
  static readonly selector = 'recentBlogPosts';

  constructor(
    public service: RecentBlogPostService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  @HostListener('window:scroll')
  onScroll() {
    const { documentElement } = this.document;

    if (!this.service.canLoadMore || this.service.isLoading) {
      return;
    }

    if (documentElement.offsetHeight + documentElement.scrollTop >= documentElement.scrollHeight - 1) {
      this.service.loadMore();
    }
  }

  ngOnInit(): void {
    this.service.loadMore();
  }
}
