import { Component, Input, OnInit } from '@angular/core';

export interface ICourseExpirationDate {
  expirationDate?: string;
  deactivationDate?: string;
  courseFormat?: {
    registrationRequired: boolean;
  };
}

@Component({
  standalone: false,
  selector: 'course-expiration-date',
  templateUrl: './course-expiration-date.component.html',
})
export class CourseExpirationDateComponent implements OnInit {
  static readonly selector = 'courseExpirationDate';

  @Input() course: ICourseExpirationDate;
  @Input() short: boolean;
  @Input() variant: 'secondary' | 'outline-secondary' = 'outline-secondary';

  registrationRequired: boolean;
  expirationDate: string;

  ngOnInit() {
    this.registrationRequired = this.course.courseFormat ? this.course.courseFormat.registrationRequired : true;

    if (this.registrationRequired) {
      this.expirationDate = this.course.deactivationDate || this.course.expirationDate;
    }
  }
}
