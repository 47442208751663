import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GlobalConfig } from 'core/environment';
import { ErrorHandlerService } from 'core/services';
import { ISiteSettings } from 'core/site-settings/models/site-settings.model';
import { SiteSettingsService } from 'core/site-settings/services/site-settings.service';
import { ILinkAccountData, IResponseLinkResult } from 'modules/integrations/models/open-id-connect.models';
import { ISsoLoginCredentials } from 'modules/integrations/models/saml-sso.model';
import { OpenIdConnectService } from 'modules/integrations/services/open-id-connect.service';
import { EMPTY, catchError, finalize, tap } from 'rxjs';

@Component({
  standalone: false,
  selector: 'open-id-connect-link-account',
  templateUrl: './open-id-connect-link-account.component.html',
})
export class OpenIdConnectLinkAccountComponent implements OnInit {
  @ViewChild('loginForm', { static: false }) loginForm: NgForm;
  username = '';
  password = '';
  create = false;
  error = '';
  loading = false;
  linkAccountData: ILinkAccountData;
  currentSiteSetting: ISiteSettings;
  signInWording = this.globalConfig.signInWording;

  public formSubmitted = false;
  public credentials: ISsoLoginCredentials = { link: true, login_name: null, password: null };

  constructor(
    private openIdConnectService: OpenIdConnectService,
    private errorHandlerService: ErrorHandlerService,
    private globalConfig: GlobalConfig,
    private siteSettingsService: SiteSettingsService,
  ) {}

  async ngOnInit() {
    await this.loadSiteSetting();
    this.loading = true;
    this.openIdConnectService
      .getLinkAccountData(true)
      .pipe(
        tap((data: ILinkAccountData) => {
          this.linkAccountData = data;
        }),
        catchError((reason: HttpErrorResponse) => {
          this.error = reason.error;

          return EMPTY;
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe();
  }

  linkAccount(): void {
    if (this.loginForm.valid) {
      this.error = '';
      this.loading = true;

      this.openIdConnectService
        .linkAccount(this.credentials, true)
        .pipe(
          tap((data: IResponseLinkResult) => {
            if (data && data.targetUrl) {
              window.location.href = data.targetUrl;
            }
          }),
          catchError((reason: HttpErrorResponse) => {
            this.error = reason.error;

            return EMPTY;
          }),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe();
    } else {
      this.error = 'Please enter your username and password.';
    }
  }

  createAccount(): void {
    this.loading = true;
    this.openIdConnectService
      .linkAccount(undefined, true)
      .pipe(
        tap((data: IResponseLinkResult) => {
          if (data && data.targetUrl) {
            window.location.href = data.targetUrl;
          }
        }),
        catchError((reason: HttpErrorResponse) => {
          this.error = reason.error;
          this.errorHandlerService.handleForbiddenError(reason);

          return EMPTY;
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe();
  }

  private async loadSiteSetting() {
    this.currentSiteSetting = await this.siteSettingsService.getCurrent();
  }
}
