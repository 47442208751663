import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { GlobalConfig } from 'core/environment';
import _ from 'lodash';
import { courseRegistrationStatuses } from 'modules/course-registrations/models/course-registration-status.model';
import { IUserCourse } from 'modules/course-registrations/models/course-registration.model';
import { UserCourseService } from 'modules/course-registrations/services/user-course.service';
import { ICourseSearchItem } from 'modules/course/common/models/course-search.model';
import { ICourse, ICourseAbstract, ICoursePrice, ICourseTile } from 'modules/course/common/models/course.model';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { CourseService } from 'modules/course/common/services/course.service';
import moment from 'moment';
import { Unsubscribable, finalize } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  standalone: false,
  template: '',
})
export abstract class BaseCourseTileComponent implements OnInit, OnDestroy {
  @Input() trackingName: string;
  @Input() trackingCategory: string;
  @Input() actionData?: any;
  @Input() currentBlsId?: number;
  @Input() showRegManagement?: boolean;
  @Input() mode?: 'component' | 'buyNowOnly' | 'roundedView' | 'linkView';
  @Input() userCourse?: IUserCourse;
  @Input() showMandatory: boolean;

  frozen = false;
  showOrganizationName = false;
  publishedByOrganizationSetting = this.globalConfig.settings.courseDetailsTile.publishedByOrganization;
  courseRatingAvailable =
    _.get(this.window, 'elmsEnvironment.userSettings.course_rating_available', false) &&
    this.globalConfig.settings.courseDetails?.reviewsVisible;

  courseTile: ICourseTile;
  appSettings = this.globalConfig.settings;
  loading = false;
  lengthInDays = 0;
  loadingUserCourse = false;
  inputCourse: ICourse | ICourseSearchItem;
  completed = false;
  coursePrice: ICoursePrice;
  private subscriber?: Unsubscribable;

  constructor(
    protected globalConfig: GlobalConfig,
    protected window: Window,
    protected courseService: CourseService,
    protected userCourseService: UserCourseService,
    protected currentUser: CurrentUserService,
  ) {}

  abstract shouldLoadUserCourse(): boolean;

  @Input()
  set course(course: ICourse | ICourseSearchItem) {
    this.inputCourse = course;
    this.courseTile = course as ICourseTile;

    if (this.courseTile.price) {
      if (typeof this.courseTile.price === 'number') {
        this.coursePrice = {
          amount: this.courseTile.price,
          currency: 'USD',
        } as ICoursePrice;
      } else {
        this.coursePrice = { ...this.courseTile.price } as ICoursePrice;
      }
    }
  }

  isNumber(value: any): boolean {
    return !isNaN(this.toInteger(value));
  }

  ngOnInit() {
    this.loading = true;

    this.subscriber = this.courseService
      .getCourseTileData(this.inputCourse.id)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.subscriber.unsubscribe();
          delete this.subscriber;
        }),
        tap((courseTileData) => {
          Object.assign(this.courseTile, courseTileData);

          if (this.courseTile.startDate && this.courseTile.endDate) {
            // If startDate and endDate exits, event if it several minutes we show one day
            this.lengthInDays = moment(this.courseTile.endDate).diff(moment(this.courseTile.startDate), 'days') + 1;
          }

          this.showOrganizationName = this.publishedByOrganizationSetting && !!this.courseTile.organization_name;
          this.showRegManagement = this.showRegManagement && this.courseTile.courseFormat.registrationRequired;
        }),
        tap(() => {
          if (this.courseTile.courseFormat.registrationRequired) {
            if (this.shouldLoadUserCourse()) {
              this.loadUserCourse();
            }

            this.completed = this.isCompleted();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }

  onRegistrationChanged(event: [ICourseAbstract, ILearningObjectRegistration]) {
    if (event[0].id === this.courseTile.id) {
      this.loadUserCourse();
    }
  }

  private loadUserCourse() {
    if (this.currentUser.get().anonymous) {
      return;
    }

    this.loadingUserCourse = true;

    this.userCourseService
      .get(this.currentUser.get().id, this.courseTile.id, true)
      .pipe(
        finalize(() => {
          this.loadingUserCourse = false;
        }),
      )
      .subscribe((userCourse) => {
        this.userCourse = userCourse;
        this.completed = this.isCompleted();
      });
  }

  private isCompleted(): boolean {
    return _.includes(courseRegistrationStatuses.strictCompletedSet, this.userCourse?.statusId);
  }

  private toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }
}
