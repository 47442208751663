<span *ngIf="statusId">
  <span *ngIf="mode === 'modern'" class="status-modern">
    <div *ngIf="showIcon && isCompleted(statusId)">
      <span class="fa fa-check status-{{ statusId }} font-22px" aria-hidden="true"></span>
      <span class="status-{{ statusId }} status-modern-name hidden-xs d-none d-sm-block font-weight-bolder">{{
        statusName
      }}</span>
    </div>

    <span *ngIf="!showIcon" class="status-{{ statusId }} status-modern-name">{{ statusName }}</span>
  </span>

  <span *ngIf="!mode || mode === 'basic'">
    <span class="label label-wrap label-status-{{ statusId }}" title="{{ statusTitle }}">{{ statusName }}</span>
  </span>

  <span *ngIf="mode === 'focused'" class="course-status-dot">
    <span class="course-status status-{{ statusId }}"
      ><span class="status-dot" aria-hidden="true"></span><span>{{ statusName }}</span></span
    >
  </span>
</span>
