<div *ngIf="loading">
  <div class="load-spinner" role="status" aria-live="polite" aria-label="Loading"></div>
</div>

<div *ngIf="!loading">
  <h4>Please select your SAML Single Sign On Provider</h4>

  <ul class="content-list">
    <li class="content-list-item" *ngFor="let client of ssoClients">
      <a [href]="client.value.url">{{ client.value.name }}</a>
    </li>
  </ul>
</div>
