<div class="tw-flex tw-h-72 tw-overflow-hidden tw-rounded-xl tw-border tw-border-gray-7 tw-text-gray-12">
  <div class="tw-hidden tw-h-full tw-w-64 lg:tw-block">
    <img alt="Blog image" class="tw-h-full tw-w-full tw-object-cover" src="/s/images/announcement_2021.png" />
  </div>
  <div class="tw-flex tw-flex-1 tw-flex-col tw-p-4">
    <div class="tw-relative tw-flex-1 tw-overflow-hidden" *ngIf="post">
      <div
        class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-h-8 tw-bg-gradient-to-t tw-from-white/95 tw-to-transparent">
        <!-- fade rgadient -->
      </div>
      <div class="tw-mb-4 tw-flex tw-gap-2.5">
        <div *ngIf="!hideAuthor && post.showAuthorInfo">
          <div *ngIf="post.createdBy">
            <user-avatar [size]="64" [user]="post.createdBy"></user-avatar>
          </div>
        </div>
        <div>
          <h4 class="tw-m-0 tw-line-clamp-2 tw-p-0 tw-text-lg tw-font-medium" [hidden]="!post.showTitle">
            <a
              href=""
              class="tw-text-brand-9 hover:tw-text-brand-10 focus:tw-outline-none focus-visible:tw-text-brand-11 focus-visible:tw-outline-brand-7"
              uiSref="main.blog"
              [uiParams]="{ id: post.id }"
              [innerText]="post.title"
              id="blog_posts_rotator_blog_link"
              [trackLinkClick]="'announcements'"></a>
          </h4>
          <small class="tw-flex tw-flex-col tw-text-sm">
            <span *ngIf="!hideAuthor && post.showAuthorInfo">
              <span [innerHTML]="post.createdBy.firstName"></span> <span [innerHTML]="post.createdBy.lastName"></span>
            </span>
            <time *ngIf="!hideAuthor && post.showAuthorInfo" [innerHTML]="post.updatedDate | date: 'mediumDate'"></time>
          </small>
        </div>
      </div>
      <article [innerHTML]="post.getContent()"></article>
    </div>
    <div [hidden]="postExists" class="alert alert-info" role="alert">There is no news available. Stay tuned!</div>
    <div
      class="tw-relative tw-flex tw-h-8 tw-items-center tw-text-center"
      *ngIf="rotatorState && rotatorState.count > 1">
      <button
        type="button"
        title="Previous"
        aria-label="Previous"
        class="btn tw-absolute tw-bottom-0 tw-left-0 tw-top-0 hover:tw-border-gray-3 hover:tw-bg-gray-4 hover:tw-transition-none focus:tw-bg-gray-5"
        (click)="previousPost()"
        [ladda]="isLoading && rotatorState.direction === 'previous'"
        data-spinner-color="#000"
        data-style="expand-right"
        [disabled]="isLoading"
        [hidden]="rotatorState.count === 2 && rotatorState.position === 1">
        <span class="fa fa-chevron-left" aria-label="Previous post"></span>
      </button>

      <span *ngIf="showNumeration" class="tw-flex-1 tw-text-gray-11">
        {{ rotatorState.position }} of {{ rotatorState.count }}
      </span>

      <button
        type="button"
        title="Next"
        aria-label="Next"
        class="btn tw-absolute tw-bottom-0 tw-right-0 tw-top-0 hover:tw-border-gray-3 hover:tw-bg-gray-4 hover:tw-transition-none focus:tw-bg-gray-5"
        (click)="nextPost()"
        [ladda]="isLoading && rotatorState.direction === 'next'"
        data-spinner-color="#000"
        data-style="expand-left"
        [disabled]="isLoading"
        [hidden]="rotatorState.count === 2 && rotatorState.position === 2">
        <span class="fa fa-chevron-right" aria-label="Next post"></span>
      </button>
    </div>
  </div>
</div>
