import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { InstructorsService } from 'modules/instructors/services/instructor.service';
import { InstructorProfileModalComponent } from 'modules/instructors/view/modal/instructor-profile-modal.component';

@NgModule({
  imports: [HttpClientModule, LmsComponentsModule],
  declarations: [InstructorProfileModalComponent],
  providers: [HttpClient, InstructorsService],
})
export class InstructorsModule {}
