import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ICourseDetails } from 'modules/course/common/models/course.model';
import { ICourseReview } from 'modules/course/reviews/models/course-review.model';

@Directive({
  standalone: false,
  selector: 'edit-course-review',
})
export class EditCourseReviewDirective extends UpgradeComponent {
  @Input() course: ICourseDetails;
  @Input() review: ICourseReview;
  @Output() reviewChange = new EventEmitter<ICourseReview>();
  @Output() cancelHandler = new EventEmitter<void>();
  @Input() fullStart: boolean;
  @Input() hideCancel: boolean;
  @Input() trackingName: string;
  @Output() addHandler = new EventEmitter<{ review: ICourseReview }>();
  constructor(elementRef: ElementRef, injector: Injector) {
    super('editCourseReview', elementRef, injector);
  }
}
